var priceCalculationType = {
	data() {
		return {
			listPriceCalculationTypes: [],
			loadingPriceCalculationTypes: false,
			paginationPriceCalculationTypes: [],
			objectPriceCalculationType: {
				exists: 0,
				id: 0,
				name: '',
				str_id: '',
				observation: '',
				status_id: null,
				status: null,
				created_at: null,
				updated_at: null,
			},
			savingPriceCalculationType: false,
			searchingPriceCalculationType: false,
			searchPriceCalculationTypes: ''
		}
	},
	methods: {
		addItemListPriceCalculationTypes(pct) {
			this.listPriceCalculationTypes.unshift(pct)
		},
		clearObjectPriceCalculationType() {
			this.objectPriceCalculationType.exists = 0
			this.objectPriceCalculationType.id = 0
			this.objectPriceCalculationType.name = ''
			this.objectPriceCalculationType.str_id = ''
			this.objectPriceCalculationType.observation = ''
			this.objectPriceCalculationType.status_id = null
			this.objectPriceCalculationType.status = null
			this.objectPriceCalculationType.created_at = null
			this.objectPriceCalculationType.updated_at = null
		},
		getPriceCalculationTypes(page = 1) {
			this.listPriceCalculationTypes = []
			this.paginationPriceCalculationTypes = []
			this.loadingPriceCalculationTypes = true
			let param = '?page=' + page + '&search=' + this.searchPriceCalculationTypes
			let url = this.getApiBackendNew + 'priceCalculationTypes' + param
			this.axiosActionIS(url, 'GET', 'getPriceCalculationTypesResult')
		},
		getPriceCalculationTypesResult(response) {
			this.loadingPriceCalculationTypes = false
			if (response.data.success == true) {
				this.listPriceCalculationTypes = response.data.data
				this.paginationPriceCalculationTypes = response.data.paginate
			}
			this.getPriceCalculationTypesResultLocal(response)
		},
		getPriceCalculationTypesResultLocal(response) {
			console.log('getPriceCalculationTypesResultLocal no desarrollado', response)
		},
		savePriceCalculationType(pct) {
			this.savingPriceCalculationType = true
			let method = (pct.id == 0) ? 'POST' : 'PUT'
			let url = this.getApiBackendNew + 'priceCalculationType/'
			url += (method == 'POST') ? 'register' : pct.id + '/update'
			this.axiosActionIS(url, method, 'savePriceCalculationTypeResult', pct)
		},
		savePriceCalculationTypeResult(response) {
			this.savingPriceCalculationType = false
			if (response.data.success == true) {
				this.setObjectPriceCalculationType(response.data.data)
			}
			this.savePriceCalculationTypeResultLocal(response)
		},
		savePriceCalculationTypeResultLocal(response) {
			console.log('savePriceCalculationTypeResultLocal no desarrollado', response)
		},
		setObjectPriceCalculationType(pct) {
			this.clearObjectPriceCalculationType()
			for (const property in pct) {
				this.objectPriceCalculationType[property] = r[property]
			}
		},
		showPriceCalculationType(id) {
			this.searchingPriceCalculationType = true
			let url = this.getApiBackendNew + 'priceCalculationType/' + id
			this.axiosActionIS(url, 'GET', 'showPriceCalculationTypeResult')
		},
		showPriceCalculationTypeResult(response) {
			this.searchingPriceCalculationType = false
			if (response.data.success == true) {
				this.setObjectPriceCalculationType(response.data.data)
			}
			this.showPriceCalculationTypeResultLocal(response)
		},
		showPriceCalculationTypeResultLocal(response) {
			console.log('showPriceCalculationTypeResultLocal no desarrollado', response)
		}
	},
};

export default priceCalculationType;