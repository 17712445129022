var expense = {
	data() {
		return {
			urlsExpense: {
				create: 'register',
				delete: '/delete',
				get: 'expenses',
				show: 'expense/',
			},
			objectExpense: {
				exists: 0,
				id: 0,
				type_expense_id: 0,
				type_expense: '',
				description: '',
				amount: 0,
				status_id: 0,
				status: {},
				expense_trackings_expense: [],
				created_at: null,
				updated_at: null
			},
			listExpenses: []
		}
	},
	computed: {
		sumExpensesTotal() {
			let total = 0
			total = this.listExpenses.reduce(function (a, b) {
				return parseFloat(a) + parseFloat(b.amount)
			}, 0)
			return total.toFixed(0)
		},
	},
	methods: {
		clearObjectTeam() {
			this.objectExpense.exists = 0
			this.objectExpense.id = 0
			this.objectExpense.type_expense_id = 0
			this.objectExpense.type_expense = ''
			this.objectExpense.description = ''
			this.objectExpense.amount = 0
			this.objectExpense.status_id = 0
			this.objectExpense.status = {}
			this.objectExpense.expense_trackings_expense = []
			this.objectExpense.created_at = null
			this.objectExpense.updated_at = null
		},
		deleteExpense(controller, controllerId, id) {
			let url = this.getApiBackendNew + controller + '/' + controllerId + '/expense/' + id + '/delete'
			this.axiosActionIS(url, 'DELETE', 'deleteExpenseResult')
		},
		deleteExpenseResult(response) {
			if (response.data.success == true) {
				this.deleteItemExpense(response.data.data)
			}
			this.deleteExpenseResultLocal(response)
		},
		deleteExpenseResultLocal(response) {
			console.log('deleteExpenseResultLocal no desarrollado', response)
		},
		deleteItemExpense(id) {
			let index = this.listExpenses.map((el) => parseInt(el.id)).indexOf(parseInt(id))
			let expenses = this.listExpenses.splice(index, 1)
		},
		saveExpense(controller, id, item) {
			let method = (item.exists == 0) ? 'POST' : 'PUT'
			this.$emit('showLockScreen', 'Guardando datos . . .')
			if (method == 'POST') {
				let url = this.getApiBackendNew + this.controller + '/' + id + '/' + this.urlsExpense.show + this.urlsExpense.create
				this.axiosActionIS(url, method, 'saveExpenseResult', item)
			} else {
				//this.axiosActionIS(this.getApiBackendNew + this.urlsExpense.show + this.objectExpense.id, method, 'saveExpenseResult', this.objectExpense)
			}
		},
		saveExpenseResult(response) {
			if (response.data.success) {
				this.setObjectExpense(response.data.data)
			}
			this.saveExpenseResultLocal(response)
		},
		saveExpenseResultLocal(response) {
			console.log("saveExpenseResultLocal no desarrollado", response)
		},
		setObjectExpense(item) {
			let newItem = Object.create(item)
			for (const property in newItem) {
				this.objectExpense[property] = newItem[property]
			}
		},
	},
};

export default expense;