<template>
	<div id="budgetWizardComponent">
		<button ref="btnShowBudget" @click="showBudgetLocal" class="btn btn-primary btn-sm" v-show="false">
			Show Budget
		</button>

		<div v-show="!searchClient">
			<div class="d-flex border-bottom border-muted pb-1 mb-2">
				<span class="fa-stack me-2 ms-n1">
					<i class="fas fa-circle fa-stack-2x text-300"></i>
					<i class="fa-inverse fa-stack-1x text-primary fas fa-spinner"></i>
				</span>
				<div class="col">
					<h5 class="mb-0 text-primary position-relative">
						<span class="border position-absolute top-50 translate-middle-y w-100 start-0 z-n1"></span>
						<span class="position-relative bg-white dark__bg-1100 pe-3">Preparación del un Nuevo
							Presupuesto</span>
					</h5>
					<p class="mb-0"><small>Este asistente le ayudará a preparar un presupuesto nuevo</small></p>
				</div>
			</div>
			<div class="px-3 mb-2" style="min-height: 20.5em">
				<div v-show="tabWinzarIndexSelected == 0">
					<h6>Para empezar ingresaremos la información general</h6>
					<div class="row">
						<div class="col-md-4 fs--1 mb-2">
							<label class="form-label fs--1">Nombre del trabajo:</label>
							<input class="form-control form-control-sm" type="text" placeholder=""
								v-model="objectBudget.name" />
							<small><i>Ejemplo: Afiche Semana Santa 2025</i></small>
						</div>
						<div class="col-md-2 fs--1 mb-2">
							<label class="form-label fs--1">Dimenciones</label>
							<input class="form-control form-control-sm" type="text"
								placeholder="ancho x altura x profundidad" v-model="objectBudget.dimensions">
							<small><i>Ejemplo: 13x21 o 6x6x6</i></small>
						</div>
						<div class="col-md-4 fs--1 mb-2">
							<label class="form-label fs--1">Tipo de impreso</label>
							<select class="form-select form-select-sm" v-model="objectBudget.print_type_id">
								<option :value="null" disabled>Selecione el tipo de impreso</option>
								<option v-for="(pt, i) in listPrintTypesFilter" :key="i" :value="pt.id">{{ pt.name }}
								</option>
							</select>
						</div>
						<div class="col-md-2 fs--1 mb-2">
							<label class="form-label fs--1">Diseño</label>
							<div>
								<div class="form-check form-check-inline">
									<input class="form-check-input" id="inlineRadio1" type="radio"
										name="inlineRadioOptions" :value="1" v-model="objectBudget.design" />
									<label class="form-check-label" for="inlineRadio1">Si</label>
								</div>
								<div class="form-check form-check-inline">
									<input class="form-check-input" id="inlineRadio2" type="radio"
										name="inlineRadioOptions" :value="0" v-model="objectBudget.design" />
									<label class="form-check-label" for="inlineRadio2">No</label>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-4 fs--1 mb-2">
							<label class="form-label fs--1">Cliente</label>
							<input class="form-control form-control-sm fs--1" v-model="searchClientsLocal"
								placeholder="Ingrese código, nombre o RUT" @keyup="clearDataObjectBudgetClient"
								@keyup.enter="getClientLocal()" ref="inputSearchClients"
								@focus="$event.target.select()">
							<small>
								<i>
									<span class="text-warning" v-if="objectBudget.client_id == null">
										Sin cliente asociado
									</span>
									<span class="text-success" v-else>
										Cod: {{ objectBudget.client_id }} - RUT: {{ objectBudget.client.number_document
										}}
									</span>
								</i>
							</small>
						</div>
						<div class="col-md-3 fs--1 mb-2">
							<label class="form-label fs--1">Contacto</label>
							<input class="form-control form-control-sm" type="text" placeholder=""
								v-model="objectBudget.contact" ref="inputContact" @focus="$event.target.select()">
						</div>
						<div class="col-md-2 fs--1 mb-2">
							<label class="form-label fs--1">Telefono</label>
							<input class="form-control form-control-sm" type="text" placeholder=""
								v-model="objectBudget.phone" ref="inputPhone" @focus="$event.target.select()">
						</div>
						<div class="col-md-3 fs--1 mb-2">
							<label class="form-label fs--1">Email</label>
							<input class="form-control form-control-sm" type="text" placeholder=""
								v-model="objectBudget.email" ref="inputEmail" @focus="$event.target.select()">
						</div>
					</div>
				</div>
				<div v-show="tabWinzarIndexSelected == 1" v-if="objectBudget.components.length > 0">
					<div v-for="(c, e) in objectBudget.components" :key="e" v-show="componentIndexSelected == e"
						class="mb-2">
						<h6>
							Componente impreso {{ c.name }}
							<span title="Eliminar Componente" class="float-end pointer link-secondary"
								v-show="objectBudget.components.length > 1"
								@click="deleteItemObjectBudgetComponentLocal(e)">x</span>
						</h6>
						<div class="fs--1">
							<div class="row">
								<div class="col-md-4 fs--1 mb-2">
									<label class="form-label fs--1">Nombre del componente:</label>
									<input class="form-control form-control-sm" type="text" placeholder=""
										v-model="c.name" />
									<small><i>Ejemplo: Portada, Interior, Insert</i></small>
								</div>
								<div class="col-md-3 fs--1 mb-2">
									<label class="form-label fs--1">Páginas:</label>
									<input class="form-control form-control-sm" type="number" placeholder=""
										v-model="c.pages" />
									<small><i>Ejemplo: 2, 4, 6, etc. (de este componente)</i></small>
								</div>
								<div class="col-md-3 fs--1 mb-2">
									<label class="form-label fs--1">Formato acabado :</label>
									<input class="form-control form-control-sm" type="text" placeholder=""
										v-model="c.widthxheight" />
									<small><i>Ejemplo: 21x14.85, etc. (abierto)</i></small>
								</div>
								<div class="col-md-2 fs--1 mb-2">
									<label class="form-label fs--1">Tintas:</label>
									<input class="form-control form-control-sm" type="text" placeholder=""
										v-model="c.print_mode" />
									<small><i>Ejemplo: 4-0, 4-2, 4-4 (Cara - dorso)</i></small>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3 fs--1 mb-2">
									<label class="form-label fs--1">Tipo de Papel:</label>
									<select class="form-select form-select-sm"
										v-model="c.material_type_sub_classification_id">
										<option v-for="(mtsc, i) in listMaterialTypeSubClassificationsFilter" :key="i"
											:value="mtsc.id">
											{{ mtsc.name }}
										</option>
									</select>
									<small><i>Ejemplo: Bond, Couche, etc.</i></small>
								</div>
								<div class="col-md-2 fs--1 mb-2">
									<label class="form-label fs--1">Gramaje:</label>
									<input class="form-control form-control-sm" type="number" placeholder=""
										v-model="c.thickness" />
									<small><i>Ejemplo: 80</i></small>
								</div>
								<div class="col-md-2 fs--1 mb-2">
									<label class="form-label fs--1">Dimensiones:</label>
									<input class="form-control form-control-sm" type="text" placeholder=""
										v-model="c.material_widthxheight" />
									<small><i>Ejemplo: 52x70 (sin cortar)</i></small>
								</div>
								<div class="col-md-3 fs--1 mb-2">
									<label class="form-label fs--1">Máquina:</label>
									<select class="form-select form-select-sm" v-model="c.equipment_id">
										<option :value="null">Tercerizado</option>
										<option v-for="(e, i) in listEquipmentsFilter" :key="i" :value="e.id"> {{ e.name
											}}
										</option>
									</select>
									<small><i>Ejemplo: Komori</i></small>
								</div>
								<div class="col-md-2 fs--1 mb-2">
									<label class="form-label fs--1">Pliego:</label>
									<input class="form-control form-control-sm" type="text" placeholder=""
										v-model="c.material_widthxheight_in_equipment" />
									<small><i>Ejemplo: 52x70 (como entra a la maquina)</i></small>
								</div>
							</div>
							<div>
								<label class="form-label fs--1">Acabados</label>
								<div class="row">
									<div class="col-md-3" v-for="(f, i) in listFinishsFilter" :key="i"
										v-if="f.finish_type_id == 2">
										<input class="form-check-input" id="flexCheckDefault" type="checkbox"
											name="flexCheckDefault" @change="selectedFinishLocal(f)"
											v-model="f.selected" />
										<span class="ms-2">{{ f.name }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="objectBudget.components.length > 1" class="fs--1 ps-1">
						<span v-for="(c, e) in objectBudget.components" :key="e" class="px-3 pointer"
							:class="(componentIndexSelected != e) ? 'text-400 border-top' : 'border border-top-0'"
							@click="changeComponentIndexSelected(e)">
							<span>
								{{ (c.name == '') ? 'Componente ' + (e + 1) : c.name }}
							</span>
						</span>
					</div>
					<div class="text-end fs--1" v-show="visibleAddOtherComponent == true">
						<span @click="addNewItemBlankObjectBudgetComponentLocal">
							<i class="text-info pointer hover">
								agregar otro componente
							</i>
						</span>
					</div>
				</div>
				<div v-show="tabWinzarIndexSelected == 2">
					<h6>
						Ya con esto terminamos de crear el Presupuesto
					</h6>
					<div>
						<label class="form-label fs--1">Acabados Generales</label>
						<div class="row fs--1">
							<div class="col-md-3" v-for="(f, i) in listFinishsFilter" :key="i"
								v-if="f.finish_type_id == 1">
								<input class="form-check-input" id="flexCheckDefault" type="checkbox"
									name="flexCheckDefault" @change="selectedFinishLocal(f)" v-model="f.selected" />
								<span class="ms-2">{{ f.name }}</span>
							</div>
						</div>
					</div>
					<br>
					<div>
						<label class="form-label fs--1">Cantidades de Presupuestar</label>
						<input class="form-control form-control-sm text-center" placeholder="100,500,1000, etc"
							type="text" v-model="quantities">
					</div>
				</div>
			</div>
			<div class="text-end">
				<button class="btn btn-sm btn-primary ms-2" :disabled="disabledBtnBack" @click="backTabIdSelected">
					<i class="fas fa-angle-double-left"></i>
					Anterior
				</button>
				<button class="btn btn-sm btn-primary ms-2" :disabled="disabledBtnNext" @click="nextTabIdSelected">
					<span v-show="tabWinzarIndexSelected < (listTabs.length - 1)">
						Siguiente
						<i class="fas fa-angle-double-right"></i>
					</span>
					<span v-show="tabWinzarIndexSelected == (listTabs.length - 1)">
						Guardar
					</span>
				</button>
				<button class="btn btn-sm btn-secondary ms-2" @click="cancelBudgetWinzar">
					Cancelar
				</button>
			</div>
		</div>
		<div v-show="searchClient">
			<client-list-seach-component @manyResults="clientManyResults" @selectClient="selectClientLocal"
				:searchParent="searchClientsLocal" ref="clientListSearchComponent"></client-list-seach-component>
			<div class="text-end">
				<button class="btn btn-default bnt-sm" @click="searchClient = false">Cancelar</button>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import budget from '@/mixins/budgets'
import printType from '@/mixins/productions/printType.js'
import equipment from '@/mixins/generals/equipment.js'
import finish from '@/mixins/generals/finish.js'
import materialType from '@/mixins/materials/materialType.js'

import ClientListSearchComponent from '@/components/ClientListSearchComponent.vue'

export default {
	name: 'BudgetWizardComponent',
	mixins: [mixin, axiosAction, errors, budget, printType, equipment, finish, materialType],
	props: ['id', 'showType'],
	data() {
		return {
			lastMessage: '',
			listTabs: [
				{ id: 1, title: 'Indique el tipo de trabajo' },
				{ id: 2, title: 'Componente impreso' },
				{ id: 3, title: 'Ultimos Ajustes para el Presupuesto' }
			],
			searchClientsLocal: '',
			tabWinzarIndexSelected: 0,
			typeJobId: 0,
			quantities: '',
			componentIndexSelected: 0,
			searchClient: false
		}
	},
	components: {
		'client-list-seach-component': ClientListSearchComponent,
	},
	computed: {
		disabledBtnBack() {
			if (this.tabWinzarIndexSelected == 0)
				return true
			return false
		},
		disabledBtnNext() {
			if (this.tabWinzarIndexSelected > (this.listTabs.length - 1))
				return true
			if (this.tabWinzarIndexSelected == 0) {
				if (this.validateBudget == false) {
					return true
				}
			}
			if (this.tabWinzarIndexSelected == 1) {
				if (this.validateBudgetComponent == false) {
					return true
				}
			}
			if (this.tabWinzarIndexSelected == 2) {
				let quantities = this.quantities.split(',');
				let r = false
				quantities.forEach(q => {
					if (isNaN(q) || q == '' || q < 0) {
						r = true
						return
					}
				})
				return r
			}
			return false
		},
		listEquipmentsFilter() {
			return this.listEquipments
		},
		listFinishsFilter() {
			return this.listFinishs
		},
		listPrintTypesFilter() {
			return this.listPrintTypes
		},
		listMaterialTypeSubClassificationsFilter() {
			return this.listMaterialTypeSubClassifications
		},
		visibleAddOtherComponent() {
			let i = this.listPrintTypesFilter.map(pt => parseInt(pt.id)).indexOf(parseInt(this.objectBudget.print_type_id))
			if (i > -1) {
				if (this.listPrintTypesFilter[i].multi_component == 1) {
					return true
				}
			}
			return false
		},
	},
	methods: {
		addNewItemBlankObjectBudgetComponentLocal() {
			this.addNewItemBlankObjectBudgetComponent()
			this.changeComponentIndexSelected(this.objectBudget.components.length - 1)
		},
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getPrintTypesResult': this.getPrintTypesResult(response); break;
				case 'getEquipmentsResult': this.getEquipmentsResult(response); break;
				case 'getFinishsResult': this.getFinishsResult(response); break;
				case 'getMaterialTypeSubClassificationsResult': this.getMaterialTypeSubClassificationsResult(response); break;
				case 'saveBudgetResult': this.saveBudgetResult(response); break;
				case 'showBudgetResult': this.showBudgetResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		cancelBudgetWinzar() {
			this.$emit('cancel')
		},
		clearDataObjectBudgetClient(e) {
			if (e.keyCode != 13) {
				this.objectBudget.client_id = null
				this.objectBudget.client = null
			}
		},
		clientManyResults(listClient) {
			this.searchClient = (listClient.length > 0)
		},
		backTabIdSelected() {
			if (this.tabWinzarIndexSelected > 0)
				this.tabWinzarIndexSelected--
		},
		getClientLocal() {
			if (this.searchClientsLocal.trim().length > 2) {
				setTimeout(() => { this.$refs.clientListSearchComponent.$refs.btnGetClients.click() }, 50)
			}
		},
		getAllClientsResultLocal(response) {
		},
		getEquipmentsResultLocal(response) { },
		getFinishsResultLocal(response) { },
		getMaterialTypeSubClassificationsResultLocal(response) { },
		changeComponentIndexSelected(i) {
			this.componentIndexSelected = i
			this.listFinishs.forEach(f => {
				let ind = this.objectBudget.components[this.componentIndexSelected].finishes.map(bcf => parseInt(bcf.id)).indexOf(parseInt(f.id))
				f.selected = (ind > -1) ? 1 : 0
			});
		},
		deleteItemObjectBudgetComponentLocal(i) {
			this.objectBudget.components.splice(i, 1)
			if (i != 0) {
				this.componentIndexSelected = i - 1
			} else {
				this.componentIndexSelected = 0
			}
		},
		getPrintTypesResultLocal(response) { },
		initModuleLocal() {
			this.setBudgetWizardComponentInit()
		},
		nextTabIdSelected() {
			if (this.tabWinzarIndexSelected < (this.listTabs.length - 1)) {
				this.tabWinzarIndexSelected++
			} else {
				this.saveBudgetLocal()
			}
		},
		resetFormBudget() {
			this.clearObjectBudget()
			this.searchClientsLocal = ''
			this.tabWinzarIndexSelected = 0
			this.componentIndexSelected = 0
			this.quantities = ''
			this.listFinishs.forEach(f => {
				f.selected = false
			})
		},
		saveBudgetLocal() {
			if (this.disabledBtnNext == false) {
				this.setObjectBurgetLastValues()
				this.saveBudget(this.objectBudget)
			}
		},
		saveBudgetResultLocal(response) {
			if (response.data.success == true) {
				this.$emit('saveBudgetResult', response.data.data)
				this.resetFormBudget()
			}
		},
		selectClientLocal(c) {
			this.setObjectBurgetClient(c)
			this.searchClient = false
			setTimeout(() => { this.$refs.inputContact.focus() }, 50)
		},
		selectedFinishLocal(f) {
			if (f.finish_type_id == 1) {
				let i = this.objectBudget.finishes.map(bf => parseInt(bf.id)).indexOf(parseInt(f.id))
				if (i > -1) {
					if (f.selected == false) {
						return this.objectBudget.finishes.splice(i, 1)
					}
				} else {
					if (f.selected == true) {
						this.objectBudget.finishes.push(f)
					}
				}
			} else if (f.finish_type_id == 2) {
				let i = this.objectBudget.components[this.componentIndexSelected].finishes.map(bcf => parseInt(bcf.id)).indexOf(parseInt(f.id))
				if (i > -1) {
					if (f.selected == false) {
						return this.objectBudget.components[this.componentIndexSelected].finishes.splice(i, 1)
					}
				} else {
					if (f.selected == true) {
						this.objectBudget.components[this.componentIndexSelected].finishes.push(f)
					}
				}
			}
		},
		setObjectBurgetLastValues() {
			let quantities = this.quantities.split(',')
			quantities.forEach(q => {
				this.objectBudget.quantities.push({ id: 0, budget_id: 0, quantity: q, created_at: null, updated_at: null })
			})
		},
		setObjectBurgetClient(c) {
			this.objectBudget.client_id = c.id
			this.objectBudget.client = c
			this.objectBudget.contact = c.name + ' ' + c.surname
			this.objectBudget.phone = c.phone
			this.objectBudget.email = c.email
			this.searchClientsLocal = c.name + ' ' + c.surname
		},
		setBudgetWizardComponentInit() {
			this.resetFormBudget()
			this.getPrintTypes()
			this.getEquipments('1,2')
			this.getFinishs('1,2')
			this.materialTypeClassificationIds = '3'
			this.getMaterialTypeSubClassifications()
		},
		showBudgetLocal() {
			this.resetFormBudget()
			if (this.id > 0) {
				this.showBudget(this.id, 'Full')
			}
		},
		showBudgetResultLocal(response) {
			if (response.data.success == true) {
				if (this.showType == 'Copy') {
					this.objectBudget.id = 0
				}
			}
			this.searchClientsLocal = this.objectBudget.client.name + ' ' + this.objectBudget.client.surname
			this.changeComponentIndexSelected(0)
			this.objectBudget.finishes.forEach(bf => {
				let i = this.listFinishs.map(f => parseInt(f.id)).indexOf(parseInt(bf.id))
				if (i > -1) {
					this.listFinishs[i].selected = true
				}
			})
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>

<style lang="scss"></style>
