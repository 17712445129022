<template>
    <div id="settingsModule">
        <div>
            <div>
                <div class="d-flex border-bottom border-muted pb-1 mb-2">
                    <span class="fa-stack me-2 ms-n1">
                        <i class="fas fa-circle fa-stack-2x text-300"></i>
                        <i class="fa-inverse fa-stack-1x text-primary far fa-file-image"></i>
                    </span>
                    <div class="col">
                        <h5 class="mb-0 text-primary position-relative">
                            <span class="border position-absolute top-50 translate-middle-y w-100 start-0 z-n1"></span>
                            <span class="position-relative bg-200 dark__bg-1100 pe-3">Configuraciones varias</span>
                        </h5>
                        <p class="mb-0"><small>Diferentes configuraciones para el area de producción</small></p>
                    </div>
                </div>
                <div>
                    <print-type-list-component></print-type-list-component>
                </div>
                <div>
                    <price-calculation-list-component></price-calculation-list-component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

import PriceCalculationListComponent from '@/components/PriceCalculationListComponent.vue'
import PrintTypeListComponent from '@/components/PrintTypeListComponent.vue'

export default {
    name: 'SettingsModule',
    mixins: [mixin, axiosAction, errors],
    data() {
        return {
        }
    },
    components: {
        'price-calculation-list-component': PriceCalculationListComponent,
        'print-type-list-component': PrintTypeListComponent
    },
    computed: {
    },
    methods: {
        axiosResultLocal(responseLocal, response) {
            switch (responseLocal) {
                case 'defaultFunction': this.defaultFunction(response); break;
                default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
            }
        },
        initModuleLocal() {
            this.setSettingsModule()
        },
        setSettingsModule() {

        }
    },
    mounted() {
        this.initModule(this.$options.name)
    },
}	
</script>

<style lang="scss"></style>
