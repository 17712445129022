<template>
	<div id="JumpsellerOrderListComponent" class="jumpsellerOrderListComponent">
		<button @click="getJumpsellerOrdersLocal(1)" ref="btnGetJumpSellerOrders" class="btn btn-primary btn-sm"
			v-show="false" :disabled="loadingJumsellerOrders">
			<span v-show="!loadingJumsellerOrders">Buscar Pedidos WEB</span>
			<span v-show="loadingJumsellerOrders">Buscando . . .</span>
		</button>
		<table class="table table-sm table-12 table-hover fs--1">
			<thead>
				<tr>
					<th class="text-center">ID</th>
					<th>Completado</th>
					<th>Cliente</th>
					<th>Forma de Pago</th>
					<th class="text-center">Pago</th>
					<th class="text-center">Despacho</th>
					<th class="text-end">Total</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr v-show="loadingJumsellerOrders">
					<td colspan="8" class="text-center">
						Buscando Ordenes de Pedido en Jumpseller . . . <img src="/assets/img/search-color.gif"
							width="30px" />
					</td>
				</tr>
				<tr v-for="(jo, i) in listJumsellerOrdersFilter" :key="i" class="hover-actions-trigger">
					<td class="align-middle white-space-nowrap border-bottom border-muted"><span
							@dblclick="console(jo.order)">{{ jo.order.id }}</span></td>
					<td class="align-middle white-space-nowrap border-bottom border-muted">{{
						jo.order.completed_at }}</td>
					<td class="align-middle white-space-nowrap border-bottom border-muted">{{
						jo.order.customer.fullname }}</td>
					<td class="align-middle white-space-nowrap border-bottom border-muted">{{
						jo.order.payment_method_name }}</td>
					<td class="align-middle white-space-nowrap border-bottom border-muted text-center">
						<span :class="setDataByJumpsellerStatus(jo.order.status, 'class')" style="padding: 3px 10px;">
							{{ setDataByJumpsellerStatus(jo.order.status, 'spanish') }}
						</span>
					</td>
					<td class="align-middle white-space-nowrap border-bottom border-muted text-center">{{
						jo.order.shipment_status }}
					</td>
					<td class="align-middle white-space-nowrap border-bottom border-muted text-end">${{
						methodFormatNumber(jo.order.total) }}</td>
					<td class="align-middle white-space-nowrap border-bottom border-muted text-end">
						<button class="btn btn-sm" @click="selectedJumpsellerOrderLocal(jo.order)"
							:disabled="!setDataByJumpsellerStatus(jo.order.status, 'selected')">
							<i class="fas fa-cart-arrow-down text-info"></i>
						</button>
					</td>
				</tr>
			</tbody>
		</table>
		<div>
			<button class="btn btn-sm fs--1" :disabled="page == 1 || loadingJumsellerOrders"
				@click="getJumpsellerOrdersLocal(page - 1)">
				<< Anterior </button>
					<button class="btn btn-sm float-end fs--1" @click="getJumpsellerOrdersLocal(page + 1)"
						:disabled="loadingJumsellerOrders || listJumsellerOrdersFilter.length == 0">
						Siguiente >>
					</button>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import errors from '@/mixins/errors.js'
import axiosAction from '@/mixins/axiosAction.js'
import jumpsellerOrder from '@/mixins/jumpseller/order.js'

export default {
	name: 'JumpsellerOrderListComponent',
	mixins: [axiosAction, errors, mixin, jumpsellerOrder],
	data() {
		return {
			page: 1
		}
	},
	computed: {
		listJumsellerOrdersFilter() {
			return this.listJumsellerOrders
		}
	},
	components: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getJumpsellerOrdersResult': this.getJumpsellerOrdersResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
		},
		getJumpsellerOrdersLocal(page = 1) {
			this.page = page
			if (this.loadingJumsellerOrders == false) {
				this.getJumpsellerOrders(page)
			}
		},
		getJumpsellerOrdersResultLocal(response) { },
		selectedJumpsellerOrderLocal(jo) {
			this.$emit('selectedJumpsellerOrder', jo)
		}
	},
	mounted() {
		this.initModule()
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
