var jumpsellerCustomer = {
	data() {
		return {
			searchingJumpsellerLinkCustomer: false,
		}
	},
	methods: {
		showLinkJumpsellerCustomer(jumpsellerCustomerId) {
			this.searchingJumpsellerLinkCustomer = true
			let url = this.getApiBackendNew + 'jumpseller/link/customer/' + jumpsellerCustomerId
			this.axiosActionIS(url, 'GET', 'showLinkJumpsellerCustomerResult')
		},
		showLinkJumpsellerCustomerResult(response) {
			this.searchingJumpsellerLinkCustomer = false
			this.showLinkJumpsellerCustomerResultLocal(response)
		},
		showLinkJumpsellerCustomerResultLocal(response) {
			console.log('showLinkJumpsellerCustomerResultLocal no desarrollado', response)
		},
	},
};

export default jumpsellerCustomer;