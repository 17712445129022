var printType = {
    data() {
        return {
            listPrintTypes: [],
            paginationPrintType: [],
            searchPrintType: '',
            qRowsPrintType: 10
        }
    },
    computed: {
    },
    filters: {
    },
    methods: {
        getPrintTypes(page = 1) {
            let param = '?search=' + this.searchPrintType + '&page=' + page + '&qRows=' + this.qRowsPrintType
            let url = this.getApiBackendNew + 'printTypes' + param
            this.axiosActionIS(url, 'GET', 'getPrintTypesResult')
        },
        getPrintTypesResult(response) {
            if (response.data.success == true) {
                this.listPrintTypes = response.data.data
                this.paginationPrintType = response.data.paginate
            }
            this.getPrintTypesResultLocal(response)
        },
        getPrintTypesResultLocal(response) {
            console.log('getPrintTypesResultLocal no desarrollado', response)
        },
    }
};

export default printType;