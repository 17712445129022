var jumpsellerProduct = {
    data() {
        return {
            listJumsellerOrders: [],
            loadingJumsellerOrders: false,
        }
    },
    methods: {
        getJumpsellerProductIdBySku(sku) {
            if (!isNaN(sku.trim())) {
                return sku.trim()
            }
            let skuSplit = sku.split(' ')
            if (skuSplit.length == 2) {
                if (!isNaN(skuSplit[1].trim())) {
                    return skuSplit[1].trim()
                }
            }
            skuSplit = sku.split('.')
            if (skuSplit.length == 2) {
                if (!isNaN(skuSplit[1].trim())) {
                    return skuSplit[1].trim()
                }
            }
            return -1
        },
    },
};

export default jumpsellerProduct;