<template>
	<div id="budgetComponent">
		<div v-show="showMensaggeLoadingComponent == true">
			<mensagge-loading-component class="bg-aasinet text-white" :text="'Buscando Presupuesto Nº ' + id">
			</mensagge-loading-component>
		</div>

		<div v-if="objectBudget.id > 0 && searchingBudget == false">
			<div class="card mb-3">
				<div class="bg-holder d-none d-lg-block bg-card"
					style="background-image:url(/assets/img/icons/spot-illustrations/corner-4.png);opacity: 0.7;">
				</div>
				<!--/.bg-holder-->

				<div class="card-body position-relative">
					<h5>Presupuesto: <span @dblclick="showBudgetLocal" class="pointer"
							title="Doble click para recargar los datos">#{{ id }}</span>
						<span class="float-end text-danger pointer opacity-hover" title="Generar PDF">
							<i class="far fa-file-pdf"></i>
						</span>
					</h5>
					<p class="fs--1">{{ objectBudget.created_at | formatDateFull }}</p>
					<div>
						<strong class="me-2" :title="objectBudget.status_id">Estado: </strong>
						<span v-if="objectBudget.status != null" class="badge rounded-pill"
							:class="'badge-soft-' + objectBudget.status.color">{{ objectBudget.status.name }}</span>
					</div>
				</div>
			</div>

			<div class="card mb-3">
				<div class="card-body">
					<div class="row">
						<div class="col-md-6 col-lg-4 mb-3 mb-lg-0">
							<div class="fs-0 text-900">
								<input class="input-clear text-900 fw-semi-bold font-sans-serif"
									v-model="objectBudget.name" @focus="$event.target.select()" :disabled="disabledAll">
							</div>
							<div class="ps-1 fs--1">
								<div class="mb-3 row">
									<strong class="col-sm-5">Tipo de Impreso: </strong>
									<div class="col-sm-7 p-0">
										<select class="select-clear text-800" v-model="objectBudget.print_type_id"
											:disabled="disabledAll">
											<option v-for="(pt, i) in listPrintTypesFilter" :key="i" :value="pt.id">
												<span class="ms-2">{{ pt.name }}</span>
											</option>
										</select>
									</div>
									<strong class="col-sm-5">Dimensiones: </strong>
									<div class="col-sm-7 p-0">
										<input class="input-clear text-800" v-model="objectBudget.dimensions"
											@focus="$event.target.select()" :disabled="disabledAll">
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-lg-4 mb-3 mb-lg-0 icon-hiden">
							<h5 class="mb-1 fs-0">
								Datos del Cliente
								<span class="float-end me-3 fs--1 text-700 pointer visible-hover" data-bs-toggle="modal"
									data-bs-target="#clientListSearchModal">
									<i class="fas fa-edit"></i>
								</span>
							</h5>
							<div class="ps-1">
								<h6 class="mb-1">{{ objectBudget.client.name }} {{ objectBudget.client.surname }}</h6>
								<p class="mb-0 fs--1">
									<strong>Email: </strong>
									<a :href="'mailto:' + objectBudget.client.email">{{ objectBudget.client.email }}</a>
								</p>
								<p class="mb-0 fs--1">
									<strong>Telfono: </strong>
									<a :href="'tel:' + objectBudget.client.phone">{{ objectBudget.client.phone }}</a>
								</p>
							</div>
						</div>
						<div class="col-md-6 col-lg-4 mb-lg-0">
							<h5 class="mb-0 fs-0">Contacto</h5>
							<input class="input-clear fs--1 m-0" v-model="objectBudget.contact"
								@focus="$event.target.select()">
							<div class="ps-1 fs--1">
								<div class="mb-3 row">
									<strong class="col-sm-3">Email: </strong>
									<div class="col-sm-8 p-0">
										<input class="input-clear text-800" v-model="objectBudget.email"
											@focus="$event.target.select()" :disabled="disabledAll">
									</div>
									<strong class="col-sm-3">Telefono: </strong>
									<div class="col-sm-8 p-0">
										<input class="input-clear text-800" v-model="objectBudget.phone"
											@focus="$event.target.select()" :disabled="disabledAll">
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="text-end fs--1">
						<button class="btn btn-default btn-sm" @click="saveBudgetLocal"
							:disabled="disabledBtnSaveBudget">
							<span v-show="!savingBudget">Guardar</span>
							<span v-show="savingBudget">Guardando</span>
						</button>
					</div>
				</div>
			</div>

			<div class="card mb-3">
				<div class="card-body">
					<div class="table-responsive fs--1">
						<table class="table table-striped1 border-bottom">
							<thead class="bg-200">
								<tr>
									<th class="text-900 border-0 ps-2 pe-2 pt-2 pb-2">Componente</th>
									<th class="text-900 border-0 ps-2 pe-2 pt-2 pb-2 text-center">Paginas</th>
									<th class="text-900 border-0 ps-2 pe-2 pt-2 pb-2 text-center">Dimensiones</th>
									<th class="text-900 border-0 ps-2 pe-2 pt-2 pb-2 text-center">Tipo de Impresión</th>
									<th class="text-900 border-0 ps-2 pe-2 pt-2 pb-2">Tipo de Papel</th>
									<th class="text-900 border-0 ps-2 pe-2 pt-2 pb-2 text-center">Gramaje</th>
									<th class="text-900 border-0 ps-2 pe-2 pt-2 pb-2 text-center">Dimensiones Papel</th>
									<th class="text-900 border-0 ps-2 pe-2 pt-2 pb-2">Maquina</th>
									<th class="text-900 border-0 ps-2 pe-2 pt-2 pb-2 text-center">Dimensiones Maquina
									</th>
									<th class="text-900 border-0 ps-2 pe-2 pt-2 pb-2 text-end">
										<span class="text-primary" title="Agregar un nuevo componente"
											@click="addNewItemBlankObjectBudgetComponentLocal" v-show="!disabledAll">
											<i class="fas fa-plus-circle"></i>
										</span>
									</th>
								</tr>
							</thead>
							<tbody v-for="(bc, i) in listBudgetComponentsFilter" :key="i">
								<tr class="border-100">
									<td class="align-middle ps-2 pe-2 pt-2 pb-2">
										<input class="input-clear text-secondary" @focus="$event.target.select()"
											v-model="bc.name" :disabled="disabledAll" placeholder="Nuevo componente">
									</td>
									<td class="align-middle ps-2 pe-2 pt-2 pb-2 text-center">
										<input class="input-clear text-secondary text-center"
											@focus="$event.target.select()" v-model="bc.pages" :disabled="disabledAll"
											placeholder="Paginas">
									</td>
									<td class="align-middle ps-2 pe-2 pt-2 pb-2 text-center">
										<input class="input-clear text-secondary text-center"
											@focus="$event.target.select()" v-model="bc.widthxheight"
											:disabled="disabledAll" placeholder="Dimensiones">
									</td>
									<td class="align-middle ps-2 pe-2 pt-2 pb-2 text-center">
										<input class="input-clear text-secondary text-center"
											@focus="$event.target.select()" v-model="bc.print_mode"
											:disabled="disabledAll" placeholder="Tipo impresión">
									</td>
									<td class="align-middle ps-2 pe-2 pt-2 pb-2">
										<select class="select-clear text-800"
											v-model="bc.material_type_sub_classification_id" :disabled="disabledAll">
											<option disabled :value="null" style="color:red">Tipo de Papel</option>
											<option v-for="(mtsc, i) in listMaterialTypeSubClassificationsFilter"
												:key="i" :value="mtsc.id">
												{{ mtsc.name }}
											</option>
										</select>
									</td>
									<td class="align-middle ps-2 pe-2 pt-2 pb-2 text-center">
										<input class="input-clear text-secondary text-center"
											@focus="$event.target.select()" v-model="bc.thickness"
											:disabled="disabledAll" placeholder="Gramaje">
									</td>
									<td class="align-middle ps-2 pe-2 pt-2 pb-2 text-center">
										<input class="input-clear text-secondary text-center"
											@focus="$event.target.select()" v-model="bc.material_widthxheight"
											:disabled="disabledAll" placeholder="Dimenciones Papel">
									</td>
									<td class="align-middle ps-2 pe-2 pt-2 pb-2">
										<select class="select-clear text-800" v-model="bc.equipment_id"
											:disabled="disabledAll">
											<option :value="null">Tercerizado</option>
											<option v-for="(e, i) in listEquipmentsFilter" :key="i" :value="e.id"> {{
												e.name
											}}
											</option>
										</select>
									</td>
									<td class="align-middle ps-2 pe-2 pt-2 pb-2 text-center">
										<input class="input-clear text-secondary text-center"
											@focus="$event.target.select()"
											v-model="bc.material_widthxheight_in_equipment" :disabled="disabledAll"
											placeholder="Dimensiones Maquina">
									</td>
									<td class="align-middle ps-2 pe-2 pt-2 pb-2 text-end">
										<span class="text-danger pointer" :title="'Eliminar Componente ' + bc.name"
											v-show="!disabledAll && actionSecurity(22)"
											@click="deleteBudgetComponentLocal(bc)">
											<i class="far fa-trash-alt"></i>
										</span>
									</td>
								</tr>
								<tr v-show="true">
									<td colspan="10" class="p-0 ps-2 pe-2">
										<table class="table table-sm fs--1">
											<thead class="bg-100">
												<tr>
													<th class="p-1">Función</th>
													<th class="p-1">Equipo</th>
													<th class="p-1 text-end">Valor Hora Equipo</th>
													<th class="p-1">Empleado</th>
													<th class="p-1 text-end">Valor Hora Funcion</th>
													<th class="p-1 text-center">Horas</th>
													<th class="p-1 text-end">Total</th>
													<th class="text-end">
														<span class="text-info"
															@click="addNewItemBlankObjectBudgetComponentProductionFunctionLocal(bc)">
															<i class="far fa-plus-square"></i>
														</span>
													</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(bcpf, x) in bc.production_functions" :key="x">
													<td class="p-0 ps-1">
														<select class="select-clear text-800"
															v-model="bcpf.production_function_id"
															@change="changeProductionFunction(bcpf)">
															<option disabled :value="null">Función</option>
															<option v-for="(pf, z) in listProductionFunctionsFilter"
																:key="z" :value="pf.id">{{ pf.name }}
															</option>
														</select>
													</td>
													<td class="p-0 ps-1">
														<select class="select-clear text-800"
															v-model="bcpf.equipment_id"
															v-show="listProductionFuntionOptionsFilter(bcpf, 'equipments').length > 0">
															<option :value="null">Tercerizado</option>
															<option
																v-for="(e, z) in listProductionFuntionOptionsFilter(bcpf, 'equipments')"
																:key="z" :value="e.id">{{ e.name }}</option>
														</select>
														<span
															v-show="listProductionFuntionOptionsFilter(bcpf, 'equipments').length == 0">
															Sin equipo
														</span>
													</td>
													<td>
														<input class="input-clear text-800 text-end"
															v-model="bcpf.price_hour_equipment">
													</td>
													<td>
														<select class="select-clear text-800" v-model="bcpf.employee_id"
															v-show="listProductionFuntionOptionsFilter(bcpf, 'employees').length > 0"
															@change="changeProductionFunctionEmployee(bcpf)">
															<option disabled :value="null">Empleado</option>
															<option
																v-for="(e, z) in listProductionFuntionOptionsFilter(bcpf, 'employees')"
																:key="z" :value="e.id">{{ e.name }} {{ e.surname }}
															</option>
														</select>
														<span
															v-show="listProductionFuntionOptionsFilter(bcpf, 'employees').length == 0">
															Generico
														</span>
													</td>
													<td>
														<input class="input-clear text-800 text-end"
															v-model="bcpf.price_hour_function">
													</td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="card-footer fs--1 text-end">
					<button class="btn btn-default btn-sm" @click="saveBudgetComponentsLocal"
						:disabled="disabledBtnSaveBudgetComponents">
						<span v-show="!savingBudgetComponent">Guardar</span>
						<span v-show="savingBudgetComponent">Guardando</span>
					</button>
				</div>
			</div>
		</div>

		<pre>
			{{ listProductionFunctionsFilter }}
		</pre>

		<!-- Modal Client List Seach -->
		<div class="modal fade" id="clientListSearchModal" tabindex="-1" aria-labelledby="clientListSearchModalLabel"
			aria-hidden="true" style="overflow-y: scroll;">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<button type="button" class="btn-close" data-bs-dismiss="modal" ref="btnClientListSearchModal"
						aria-label="Close" v-show="false"></button>
					<client-list-seach-component @selectClient="selectClientLocal"></client-list-seach-component>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import printType from '@/mixins/productions/printType.js'
import equipment from '@/mixins/generals/equipment.js'
import finish from '@/mixins/generals/finish.js'
import materialType from '@/mixins/materials/materialType.js'
import budget from '@/mixins/budgets'
import budgetComponent from '@/mixins/budgets/budgetComponent.js'
import productionFunction from '@/mixins/productions/productionFunction.js'

import ClientListSearchComponent from '@/components/ClientListSearchComponent.vue'
import MessageLoadingComponent from '@/components/MessageLoadingComponent.vue'

export default {
	name: 'Budget',
	mixins: [mixin, axiosAction, errors, printType, equipment, finish, materialType, budget, budgetComponent, productionFunction],
	props: ['id'],
	data() {
		return {
			objectBudgetChanged: false,
			statusIdsChange: [73]
		}
	},
	components: {
		'client-list-seach-component': ClientListSearchComponent,
		'mensagge-loading-component': MessageLoadingComponent
	},
	computed: {
		disabledAll() {
			let r = true
			let i = this.statusIdsChange.map(id => id).indexOf(parseInt(this.objectBudget.status_id))
			r = (i == -1)
			return r
		},
		disabledBtnSaveBudget() {
			let r = true
			r = !this.changedObject(this.objectBudget)

			if (r == false) {
				if (this.validateBudget == false) {
					r = true
				}
			}
			return r
		},
		disabledBtnSaveBudgetComponents() {
			if (this.savingBudgetComponent) {
				return true
			}
			let r = !this.validateBudgetComponent
			if (r == false) {
				if (!this.changedListBudgetComponents && (this.listBudgetComponents.map(bc => parseInt(bc.id)).indexOf(0) == -1)) {
					r = true
				}
			}
			return r
		},
		listBudgetComponentsFilter() {
			this.objectBudget.components = this.listBudgetComponents
			return this.objectBudget.components
		},
		listEquipmentsFilter() {
			return this.listEquipments
		},
		listFinishsFilter() {
			return this.listFinishs
		},
		listMaterialTypeSubClassificationsFilter() {
			return this.listMaterialTypeSubClassifications
		},
		listPrintTypesFilter() {
			return this.listPrintTypes
		},
		listProductionFunctionsFilter() {
			return this.listProductionFunctions
		},
		showMensaggeLoadingComponent() {
			if (this.searchingBudget == true) {
				return true
			}
			return false
		}
	},
	methods: {
		addNewItemBlankObjectBudgetComponentLocal() {
			if (this.listBudgetComponents.map(bc => parseInt(bc.id)).indexOf(0) == -1) {
				this.addNewItemBlankObjectBudgetComponent()
			} else {
				this.showMsgErrorHandling('Aviso', 'Tienes que guardar la fila nueva que agregaste', 'warn', 5000, 'top right')
			}
		},
		addNewItemBlankObjectBudgetComponentProductionFunctionLocal(bc) {
			this.addNewItemBlankObjectBudgetComponentProductionFunction(bc)
		},
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'deleteBudgetComponentResult': this.deleteBudgetComponentResult(response); break;
				case 'getBudgetComponentsResult': this.getBudgetComponentsResult(response); break;
				case 'getPrintTypesResult': this.getPrintTypesResult(response); break;
				case 'getEquipmentsResult': this.getEquipmentsResult(response); break;
				case 'getFinishsResult': this.getFinishsResult(response); break;
				case 'getMaterialTypeSubClassificationsResult': this.getMaterialTypeSubClassificationsResult(response); break;
				case 'getProductionFunctionsResult': this.getProductionFunctionsResult(response); break;
				case 'saveBudgetResult': this.saveBudgetResult(response); break;
				case 'saveBudgetComponentResult': this.saveBudgetComponentResult(response); break;
				case 'showBudgetResult': this.showBudgetResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		changeProductionFunction(pf) {
			let i = this.listProductionFunctions.map(f => parseInt(f.id)).indexOf(parseInt(pf.production_function_id))
			pf.price_hour_function = this.listProductionFunctions[i].price_hour.value
			pf.employee_id = null
			pf.equipment_id = null
			pf.price_hour_equipment = 0
		},
		changeProductionFunctionEmployee(pf) {
			console.log(pf.production_function_id)
			let i = this.listProductionFunctions.map(f => parseInt(f.id)).indexOf(parseInt(pf.production_function_id))
			if (i > -1) {
				let x = this.listProductionFunctions[i].employees.map(e => parseInt(e.id)).indexOf(parseInt(pf.employee_id))
				console.log(this.listProductionFunctions[i].employees[x])
				if (this.listProductionFunctions[i].employees[x].current_salary_hour > 0) {
					pf.price_hour_function = this.listProductionFunctions[i].employees[x].current_salary_hour
				} else {
					this.showMsgErrorHandling('SIN DEFINIR', 'No se puede definir el valor con este empleado', 'warn', 5000, 'top right')
				}
				//let x = this.listProductionFunctions[i].employees.map(e => parseInt(e.id)).indexOf(parseInt(pf.employee_id))
			}
		},
		deleteBudgetComponentLocal(bc) {
			if (bc.id > 0) {
				this.$confirm(
					{
						message: 'Está seguro que quiere eliminar el componente <strong class="text-info">"' + bc.name + '"</strong>',
						button: {
							no: 'No, de ninguna manera',
							yes: 'Si, dale'
						},
						callback: confirm => {
							if (confirm) {
								this.deleteBudgetComponent(bc)
							}
						}
					}
				)
			} else {
				this.deleteItemListBudgetComponent(bc.id)
			}
		},
		deleteBudgetComponentResultLocal(response) {
			if (response.data.success == true) {
				this.showMsgErrorHandling('Borrado', 'Se borro el componente Nº ' + response.data.data, 'warn', 5000, 'top right')
			}
		},
		getBudgetComponentsResultLocal(response) {
			if (this.listBudgetComponents.length > 0) {
				this.setListBudgetComponentCopyLocal()
			}
		},
		getEquipmentsResultLocal(response) { },
		getFinishsResultLocal(response) { },
		getMaterialTypeSubClassificationsResultLocal(response) { },
		getPrintTypesResultLocal(response) { },
		getProductionFunctionsResultLocal(response) { },
		initModuleLocal() {
			this.setBudgetInit()
			this.getPrintTypes()
			this.getEquipments('1,2')
			this.getFinishs('1,2')
			this.materialTypeClassificationIds = '3'
			this.getMaterialTypeSubClassifications()
			this.getProductionFunctions(1, 'full')
		},
		listProductionFuntionOptionsFilter(pf, type = "equipments") {
			let l = []
			if (pf.production_function_id != null) {
				let i = this.listProductionFunctions.map(f => parseInt(f.id)).indexOf(parseInt(pf.production_function_id))
				if (i > -1) {
					if (type == "equipments") {
						l = this.listProductionFunctions[i].equipments
					} else if (type == "employees") {
						if (this.listProductionFunctions[i].price_hour.type == 'undefined') {
							l = this.listProductionFunctions[i].employees
						}
					}
				}
			}
			return l
		},
		saveBudgetLocal() {
			this.saveBudget(this.objectBudget)
		},
		saveBudgetComponentsLocal() {
			this.objectBudget.components.forEach(bc => {
				if (bc.id == 0 || bc.changed == true) {
					bc.budget_id = (bc.budget_id == null) ? this.objectBudget.id : bc.budget_id
					this.saveBudgetComponent(bc)
				}
			});
		},
		saveBudgetComponentResultLocal(response) {
			if (response.data.success == true) {
				if (response.data.message == 'CREATED') {
					this.deleteItemListBudgetComponent(0)
					let index = this.addItemListBudgetComponents(response.data.data)
					this.setListBudgetComponentCopy(this.listBudgetComponents[index])
					this.showMsgErrorHandling('Creado', 'Se guardo con exito el componente Nº ' + response.data.data.name, 'success', 5000, 'top right')
				} else if (response.data.message == 'UPDATED') {
					let index = this.updateItemListBudgetComponents(response.data.data)
					if (index > -1) {
						this.setListBudgetComponentCopy(this.listBudgetComponents[index])
					}
					this.showMsgErrorHandling('Actualizado', 'Se actualizó con exito el componente Nº ' + response.data.data.name, 'info', 5000, 'top right')
				}
			}
		},
		saveBudgetResultLocal(response) {
			if (response.data.success == true) {
				this.setObjectBudgetCopy()
				this.showMsgErrorHandling('Actualizado', 'Se actualizó con exito el presupuesto Nº ' + response.data.data.id, 'info', 5000, 'top right')
			} else {
				this.showMsgErrorHandling('ERROR', 'No se puedo Actualizar el presupuesto Nº ' + response.data.data.id, 'error', 5000, 'top right')
			}
		},
		selectClientLocal(c) {
			this.objectBudget.client_id = c.id
			this.objectBudget.client = c
			this.$refs.btnClientListSearchModal.click()
		},
		setBudgetInit() {
			if (this.id > 0) {
				this.showBudgetLocal()
			}
		},
		showBudgetLocal() {
			this.objectBudgetChanged = false
			document.title = 'Buscando Presupuesto Nº ' + this.id
			this.showBudget(this.id)
		},
		setListBudgetComponentCopyLocal() {
			this.listBudgetComponents.forEach(bc => {
				this.setListBudgetComponentCopy(bc)
			})
		},
		showBudgetResultLocal(response) {
			if (response.data.success == true) {
				document.title = 'Presupuesto ' + response.data.data.id
				this.setObjectBudgetCopy()
				this.getBudgetComponentsByBudgetId(this.objectBudget.id)
			} else {
				document.title = 'Ocurrio una Problema!!!'
			}
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>