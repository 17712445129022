<template>
	<div id="priceCalculationListComponent">
		<div v-show="loadingPriceCalculations == true">
			<mensagge-loading-component class="bg-aasinet text-white" text="Buscando Calculos de Precio">
			</mensagge-loading-component>
		</div>
		<div class="card mb-1" id="shippingsCard">
			<div class="card-header">
				<div class="row flex-between-center">
					<div class="col-sm-auto d-flex align-items-center pe-0">
						<div class="row gy-2 gx-3 align-items-center">
							<div class="col-auto">
								<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Calculos de Precios</h5>
							</div>
							<div class="col-auto">
								<div class="input-group">
									<input class="form-control form-control-sm shadow-none search" type="search"
										placeholder="" aria-label="search" ref="searchPriceCalculations"
										@keyup.enter="getPriceCalculationsLocal(1)" @focus="$event.target.select()">
									<div class="input-group-text bg-transparent pointer"
										@click="getPriceCalculationsLocal(1)">
										<span class="fa fa-search fs--1 text-600"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-8 col-sm-auto ms-auto text-end ps-0">
						<div id="orders-actions">
							<button class="btn btn-falcon-default btn-sm me-2" type="button"
								@click="showPriceCalculationLocal(0)" ref="btnShowPriceCalculation"
								v-show="showPriceCalculations" data-bs-toggle="modal"
								data-bs-target="#priceCalculationModal">
								<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Nuevo</span>
							</button>
							<span @click="showPriceCalculations = !showPriceCalculations" class="pointer">
								<span v-show="!showPriceCalculations">
									<i class="far fa-eye"></i>
								</span>
								<span v-show="showPriceCalculations">
									<i class="far fa-eye-slash"></i>
								</span>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div v-show="showPriceCalculations">
				<div class="card-body p-0">
					<div class="table-responsive scrollbar">
						<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden table-hover">
							<thead class="bg-200">
								<tr>
									<th class="text-900 text-center">ID</th>
									<th class="text-900">Descripción</th>
									<th class="text-900">Tipo de Calculo</th>
									<th class="text-900 text-center">Estado</th>
									<th class="text-900 text-end"></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(pc, i) in listPriceCalculationsFilter" :key="i">
									<td class="text-center">{{ pc.id }}</td>
									<td>{{ pc.observation }}</td>
									<td>{{ pc.price_calculation_type.name }}</td>
									<td class="text-center">{{ pc.status.name }}</td>
									<td class="text-end">
										<span class="ms-2 text-info pointer" @click="showPriceCalculationLocal(pc.id)"
											data-bs-toggle="modal" data-bs-target="#priceCalculationModal">
											<i class="far fa-edit"></i>
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="card-footer">
					<div class="d-flex align-items-center justify-content-center">
						<pagination :pagination="paginationListPriceCalculations"
							@getAllList="getPriceCalculationsLocal">
						</pagination>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="priceCalculationModal" tabindex="-1" aria-labelledby="priceCalculationModalLabel"
			aria-hidden="true" data-bs-backdrop="static">
			<div class="modal-dialog modal-xl">
				<div class="modal-content p-3">
					<button type="button" class="btn-close" data-bs-dismiss="modal" ref="btnClosePriceCalculationModal"
						aria-label="Close" v-show="false"></button>
					<price-calculation-component ref="priceCalculationComponent" :id="priceCalculationIdSelected"
						@savePriceCalculationResult="savePriceCalculationResultLocal"
						@closePriceCalculation="closePriceCalculationLocal"></price-calculation-component>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import priceCalculation from '@/mixins/prices/priceCalculation.js'

import Pagination from '@/components/Pagination.vue'
import MessageLoadingComponent from '@/components/MessageLoadingComponent.vue'
import PriceCalculationComponent from '@/components/PriceCalculationComponent.vue'

export default {
	name: 'PriceCalculation',
	mixins: [mixin, axiosAction, errors, priceCalculation],
	props: [],
	data() {
		return {
			showPriceCalculations: true,
			priceCalculationIdSelected: 0
		}
	},
	components: {
		'pagination': Pagination,
		'mensagge-loading-component': MessageLoadingComponent,
		'price-calculation-component': PriceCalculationComponent
	},
	computed: {
		listPriceCalculationsFilter() {
			return this.listPriceCalculations
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getPriceCalculationsResult': this.getPriceCalculationsResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		closePriceCalculationLocal() {
			this.$refs.btnClosePriceCalculationModal.click()
		},
		getPriceCalculationsLocal(page = 1) {
			this.getPriceCalculations(page)
		},
		getPriceCalculationsResultLocal(response) { },
		initModuleLocal() {
			this.setPriceCalculationInit()
		},
		savePriceCalculationResultLocal(response) {
			if (response.data.success == true) {
				if (response.data.message == 'CREATED') {
					this.addItemListPriceCalculations(response.data.data)
				} else if (response.data.message == 'UPDATED') {
					this.updateItemListPriceCalculations(response.data.data)
				}
			}
		},
		setPriceCalculationInit() {
			this.getPriceCalculationsLocal(1)
		},
		showPriceCalculationLocal(id) {
			this.priceCalculationIdSelected = id
			setTimeout(() => { this.$refs.priceCalculationComponent.$refs.btnShowPriceCalculation.click() }, 50)
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>