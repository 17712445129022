<template>
	<div id="teamWizardComponent">
		<button ref="btnShowTeam" @click="showTeamLocal" class="btn btn-info btn-sm" v-show="false">Buscar Team</button>
		<div class="row">
			<div class="col-md-6 mb-2">
				<select class="form-select form-select-sm" v-model="objectTeam.type_team_id"
					@change="changeSelectTypeTeam()" :disabled="disabledFormTeam">
					<option disabled :value="0">Eliga el tipo de campaña</option>
					<option v-for="(tt, i) in listTypeTeamsFilter" :key="i" :value="tt.id">{{ tt.name }}</option>
				</select>
			</div>
			<div class="col-md-6 mb-2">
				<input class="form-control form-control-sm" v-model="objectTeam.name" placeholder="Nombre de la Campaña"
					:disabled="disabledFormTeam">
			</div>
		</div>
		<div class="row">
			<div class="col-12 mb-2">
				<input class="form-control form-control-sm" v-model="objectTeam.description" placeholder="Descripción"
					:disabled="disabledFormTeam">
			</div>
		</div>
		<div class="row">
			<div class="col-md-3 mb-2">
				<input class="form-control form-control-sm text-center" v-model="objectTeam.begin_date" type="date"
					placeholder="Fecha de inicio" :disabled="disabledFormTeam">
			</div>
			<div class="col-md-3 mb-2">
				<input class="form-control form-control-sm text-end" v-model="objectTeam.viatic"
					placeholder="% de viatico" :disabled="disabledFormTeam">
			</div>
			<div class="col-md-3 mb-2">
				<input class="form-control form-control-sm text-end" v-model="objectTeam.commission"
					placeholder="% de comisión" :disabled="disabledFormTeam">
			</div>
			<div class="col-md-3 mb-2">
				<div class="form-check form-switch">
					<input class="form-check-input" id="flexSwitchCheckChecked" type="checkbox"
						v-model="objectTeam.show_remuneration" :disabled="disabledFormTeam" />
					<label class="form-check-label" for="flexSwitchCheckChecked">Mostrar Comisión</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6 mb-2">
				<select class="form-select form-select-sm" v-model="objectTeam.price_list_id"
					:disabled="disabledFormTeam">
					<option disabled :value="0">Eliga la lista de precio</option>
					<option v-for="(pl, i) in listPriceListsFilter" :key="i" :value="pl.id">{{ pl.name }}</option>
				</select>
			</div>
			<div class="col-md-6 mb-2">
				<select class="form-select form-select-sm" v-model="objectTeam.entity_id" :disabled="disabledFormTeam">
					<option disabled :value="0">Eliga el campo misionero</option>
					<option v-for="(e, i) in listEntitiesFilter" :key="i" :value="e.id">{{ e.name }}</option>
				</select>
			</div>
		</div>
		<div class="row">
			<div class="col-12 mb-2">
				<autocomplete-input-component ref="autocompleteInputComponentClient"
					_class="form-control form-control-sm" :_path="getApiBackendNew + 'clients'" :_disabled="false"
					@actionSelectItem="setClientLocal" :_defaultString="defaultStringClient">
				</autocomplete-input-component>
				<small v-if="(objectTeam.client_id == 0)" class="text-warning"><i>Debe seleccionar un cliente desde la
						lista</i></small>

			</div>
		</div>
		<div class="text-end">
			<button class="btn btn-sm btn-secondary" @click="cancelWizardLocal"
				v-show="(textButtonCancel != undefined && textButtonCancel != '')">{{ textButtonCancel }}</button>
			<button class="btn btn-sm btn-primary ms-2" :disabled="disabledBtnSaveTeam"
				v-show="visibleBtnSaveOrder != false" ref="btnSaveTeam" @click="saveTeamLocal">
				<span v-show="savingTeam == false">Guardar</span>
				<span v-show="savingTeam == true">Guardando...</span>
			</button>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import team from '@/mixins/teams'
import typeTeam from '@/mixins/teams/typeTeam.js'
import entity from '@/mixins/generals/entity.js'
import priceList from '@/mixins/generals/priceList.js'

import AutoCompleteInputComponent from '@/components/AutoCompleteInputComponent.vue'

export default {
	name: 'TeamWizardComponent',
	mixins: [mixin, axiosAction, errors, team, typeTeam, entity, priceList],
	props: ['id', 'visibleBtnSaveOrder', 'textButtonCancel'],
	data() {
		return {
			defaultStringClient: '',
		}
	},
	components: {
		'autocomplete-input-component': AutoCompleteInputComponent
	},
	computed: {
		disabledFormTeam() {
			if (this.savingTeam == true) {
				return true
			}
			if (this.loadingTeams == true) {
				return true
			}
			return false
		},
		disabledBtnSaveTeam() {
			if (this.disabledFormTeam == true) {
				this.$emit('disabledBtnSaveTeam', true)
				return true
			}
			if (this.validateFormTeam == false) {
				this.$emit('disabledBtnSaveTeam', true)
				return true
			}
			this.$emit('disabledBtnSaveTeam', false)
			return false
		},
		listEntitiesFilter() {
			return this.listEntities.filter(e => { return e.status_id == 1 })
		},
		listPriceListsFilter() {
			return this.listPriceLists.filter(pl => { return pl.status_id == 1 })
		},
		listTypeTeamsFilter() {
			return this.listTypeTeams.filter(tt => { return tt.status_id == 1 })
		},
		validateFormTeam() {
			if (this.objectTeam.type_team_id == 0 || this.objectTeam.type_team_id == null) {
				return false
			}
			if (this.objectTeam.name == undefined || this.objectTeam.name == null || this.objectTeam.name.trim().length < 5) {
				return false
			}
			let i = this.listTypeTeams.map(tt => parseInt(tt.id)).indexOf(parseInt(this.objectTeam.type_team_id))
			if (i > -1) {
				if (isNaN(this.objectTeam.viatic) || this.objectTeam.viatic == 0 || this.objectTeam.viatic == '') {
					return false
				}
				if (isNaN(this.objectTeam.commission) || this.objectTeam.commission == 0 || this.objectTeam.commission == '') {
					return false
				}
			}
			if (this.objectTeam.price_list_id == 0 || this.objectTeam.price_list_id == null) {
				return false
			}
			if (this.objectTeam.entity_id == 0 || this.objectTeam.entity_id == null) {
				return false
			}
			if (this.objectTeam.client_id == 0 || this.objectTeam.client_id == null) {
				return false
			}
			return true
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getAllPriceListsResult': this.getAllPriceListsResult(response); break;
				case 'getAllTypeTeamsResult': this.getAllTypeTeamsResult(response); break;
				case 'getAllEntitiesResult': this.getAllEntitiesResult(response); break;
				case 'saveTeamResult': this.saveTeamResult(response); break;
				case 'showTeamResult': this.showTeamResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		cancelWizardLocal() {
			this.$emit('cancelWizard', this.objectTeam)
		},
		changeSelectTypeTeam() {
			let i = this.listTypeTeams.map(tt => parseInt(tt.id)).indexOf(parseInt(this.objectTeam.type_team_id))
			if (i > -1) {
				this.objectTeam.viatic = this.listTypeTeams[i].viatic
				this.objectTeam.commission = this.listTypeTeams[i].commission
				this.objectTeam.show_remuneration = this.listTypeTeams[i].show_remuneration
			}
		},
		clearFormTeam() {
			this.clearObjectTeam()
			this.objectTeam.begin_date = this.currentDate()
		},
		getAllEntitiesResultLocal(response) { },
		getAllPriceListsResultLocal(response) { },
		getAllTypeTeamsResultLocal(response) { },
		initModuleLocal() {
			this.setTeamWizardInit()
		},
		setClientLocal(c) {
			if (c.id > 0) {
				this.objectTeam.client_id = c.id
				this.objectTeam.client = c
			} else {
				this.objectTeam.client_id = 0
				this.objectTeam.client = null
			}
		},
		setTeamWizardInit() {
			this.getAllEntities()
			this.getAllPriceLists()
			this.getAllTypeTeams()
			this.clearFormTeam()
			this.showTeamLocal()
		},
		saveTeamLocal() {
			if (this.validateFormTeam == true) {
				this.saveTeam(this.objectTeam)
			}
		},
		saveTeamResultLocal(response) {
			if (response.data.success == true) {
				if (this.objectTeam.id > 0) {
					this.$emit('saveTeamResult', response)
				}
			}
		},
		showTeamLocal() {
			this.clearObjectTeam()
			if (this.id != undefined && this.id > 0) {
				this.showTeam(this.id)
			}
		},
		showTeamResultLocal(response) {
			if (response.data.success = true) {
				if (this.objectTeam.id > 0 && this.objectTeam.client != null && this.objectTeam.client != undefined) {
					this.defaultStringClient = this.objectTeam.client.name + ' ' + this.objectTeam.client.surname
					setTimeout(() => { this.$refs.autocompleteInputComponentClient.$refs.btnSetString.click() }, 50)
				}
			}
		},
		showMsgErrorHandlingLocal(response) {
			console.log(response)
			this.savingTeam = false
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>