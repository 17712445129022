<template>
	<div id="teams">
		<div class="card mb-3" id="teamsTable">
			<div class="card-header">
				<div class="row flex-between-center">
					<div class="col-3 col-sm-auto d-flex align-items-center pe-0">
						<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Campañas</h5>
					</div>
					<div class="col-3 col-sm-13">
						<div>
							<div>
								<div class="input-group">
									<input class="form-control form-control-sm shadow-none search" type="search"
										placeholder="Buscar pedidos" aria-label="search" v-model="searchTeams"
										@keyup.enter="getAllTeamsLocal(1)" />
									<div class="input-group-text bg-transparent pointer" @click="getAllTeamsLocal(1)">
										<span class="fa fa-search fs--1 text-600"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-auto p-3">
						<form class="row align-items-center g-3">
							<div class="col-auto">
								<h6 class="text-700 mb-0">Fechas: </h6>
							</div>
							<div class="col-md-auto position-relative">
								<input class="form-control form-control-sm datetimepicker ps-4" id="teamsDateRange"
									type="text"
									data-options="{&quot;mode&quot;:&quot;range&quot;,&quot;dateFormat&quot;:&quot;M d&quot;,&quot;disableMobile&quot;:true , &quot;defaultDate&quot;: [&quot;Sep 12&quot;, &quot;Sep 19&quot;] }" /><span
									class="fas fa-calendar-alt text-primary position-absolute top-50 translate-middle-y ms-2">
								</span>
							</div>
						</form>
					</div>
					<div class="col-6 col-sm-auto ms-auto text-end ps-0">
						<div id="teams-actions">
							<button class="btn btn-falcon-default btn-sm me-2" type="button" data-bs-toggle="modal"
								data-bs-target="#teamWizardModal" @click="showTeamWizardLocal(0)"
								v-show="actionSecurity(26)">
								<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Nueva</span>
							</button>
							<button class="btn btn-falcon-default btn-sm mx-2 d-none" type="button">
								<span class="fas fa-filter" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Filter</span>
							</button>
							<router-link to="/team/statistics" class="btn btn-falcon-default btn-sm">
								<span class="fas fa-chart-bar"></span>
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body p-0">
				<div class="table-responsive scrollbar" v-if="!loadingTeams">
					<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden">
						<thead class="bg-200 text-900">
							<tr>
								<th class="sort pe-1 align-middle white-space-nowrap">Campaña</th>
								<th class="sort pe-1 align-middle white-space-nowrap" style="min-width: 12.5rem;">
									Tipo/Campo</th>
								<th class="sort pe-1 align-middle white-space-nowrap pe-7">Fecha</th>
								<th class="sort pe-1 align-middle white-space-nowrap text-center">Estado</th>
								<th class="sort pe-1 align-middle white-space-nowrap text-center">Acción</th>
							</tr>
						</thead>
						<tbody class="list" id="table-teams-body">
							<tr class="btn-reveal-trigger" v-for="(item, index) in listTeamsFilter" :key="index">
								<td class="team py-2 align-middle white-space-nowrap">
									<router-link :to="'team/' + item.id" class="text-primary" target="_black">
										<strong>#{{ item.id }}</strong>
									</router-link>
									<strong> {{ item.name }}</strong>
									<p class="mb-0 text-500">{{ item.client.name }} {{ item.client.surname }}</p>
								</td>
								<td class="address py-2 align-middle white-space-nowrap">
									<strong> {{ item.type_team.name }}</strong>
									<p class="mb-0 text-500">{{ item.entity.name }}</p>
								</td>
								<td class="date py-2 align-middle">{{ item.created_at | formatDateMin }}</td>
								<td class="status py-2 align-middle text-center fs-0 white-space-nowrap">
									<span v-if="item.status === null">-</span>
									<span v-else class="badge badge rounded-pill d-block"
										:class="'badge-soft-' + item.status.color">{{ item.status.name }}</span>
								</td>
								<td class="py-2 align-middle white-space-nowrap text-end">
									<div class="dropdown font-sans-serif position-static">
										<button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
											type="button" id="team-dropdown-0" data-bs-toggle="dropdown"
											data-boundary="viewport" aria-haspopup="true" aria-expanded="false"><span
												class="fas fa-ellipsis-h fs--1"></span></button>
										<div class="dropdown-menu dropdown-menu-end bteam py-0"
											aria-labelledby="team-dropdown-0">
											<div class="bg-white py-2">
												<span class="dropdown-item pointer"
													@click="path('team/' + item.id)">Ver</span>
												<span class="dropdown-item pointer"
													@click="path('team/' + item.id + '/admin')">Administrar</span>
												<span class="dropdown-item pointer" data-bs-toggle="modal"
													data-bs-target="#teamWizardModal"
													@click="showTeamWizardLocal(item.id)"
													v-show="actionSecurity(27)">Editar</span>

												<div class="dropdown-divider" v-show="actionSecurity(2)"></div>
												<a class="dropdown-item text-danger pointer" v-show="actionSecurity(2)"
													@click="deleteTeamLocal(item)">Eliminar</a>
											</div>
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div v-else class="text-center">
					<p class="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">Estamos buscando
						los
						pedidos...</p>
				</div>
			</div>
			<div class="card-footer">
				<div class="row flex-between-center">
					<div class="col-auto">
						<select class="form-select form-select-sm" v-model="qRowsTeam" @change="changeQRowsTeam">
							<option value="10">10 Resultados</option>
							<option value="25">25 Resultados</option>
							<option value="50">50 Resultados</option>
							<option value="100">100 Resultados</option>
							<option value="200">200 Resultados</option>
							<option value="1000">1000 Resultados</option>
						</select>
					</div>
					<pagination :pagination="paginationTeams" @getAllList="getAllTeamsLocal"></pagination>
					<div class="col-auto"><a class="fs--1 font-sans-serif" href="#!" v-show="false">Ayuda</a></div>
				</div>
			</div>
		</div>


		<div class="modal fade" id="teamWizardModal" tabindex="-1" aria-labelledby="teamWizardModalLabel"
			aria-hidden="true" data-bs-backdrop="static">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="modal-body">
						<team-wizard-component ref="teamWizardComponent" :visibleBtnSaveOrder="true"
							@saveTeamResult="saveTeamResultLocal" @cancelWizard="cancelTeamWizardLocal"
							:id="teamIdSelected" :textButtonCancel="'Cerrar'"></team-wizard-component>
					</div>
					<div class="modal-footer" v-show="false">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
							ref="btnCloseTeamWizardModal">Cerrar</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import teams from '@/mixins/teams'

import TeamWizardComponent from '@/components/TeamWizardComponent.vue'

import Pagination from '@/components/Pagination.vue'
export default {
	name: 'Teams',
	mixins: [mixin, axiosAction, errors, teams],
	data() {
		return {
			teamIdSelected: 0
		}
	},
	components: {
		'pagination': Pagination,
		'team-wizard-component': TeamWizardComponent,
	},
	computed: {
		listTeamsFilter() {
			return this.listTeams
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'deleteTeamResult': this.deleteTeamResult(response); break;
				case 'getAllTeamsResult': this.getAllTeamsResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.setTeamInitLocal()
		},
		cancelTeamWizardLocal(t) {
			this.$refs.btnCloseTeamWizardModal.click()
		},
		deleteTeamLocal(team) {
			if (confirm('¿Está seguro de eliminar la campaña "' + team.name + '"?')) {
				this.deleteTeam(team.id)
			}
		},
		deleteTeamResultLocal(response) {
			if (response.data.success == true) {
				this.$notify({ title: 'Resultado', text: 'Campaña borrada con Exito.', type: 'success' })
			}
		},
		changeQRowsTeamLocal() {
			this.getAllTeamsLocal(1)
		},
		getAllTeamsLocal(page = 1) {
			this.getAllTeamByUserClient(page)
		},
		getAllTeamsResultLocal(response) {
		},
		saveTeamResultLocal(response) {
			if (response.data.success == true) {
				if (response.data.message == 'UPDATED') {
					this.updateItemListTeams(response.data.data)
				} else if (response.data.message == 'CREATED') {
					this.addItemListTeams(response.data.data)
				}
				this.$refs.btnCloseTeamWizardModal.click()
			}
		},
		setTeamInitLocal() {
			flatpickr(
				"#teamsDateRange", {
				mode: "range",
				dateFormat: "Y-m-d",
				defaultDate: [this.dateFromTeams, this.dateToTeams],
				onClose: (selectedDates, dateStr, instance) => {
					dateStr = dateStr.split('to')
					if (dateStr.length == 2) {
						this.dateFromTeams = dateStr[0].trim()
						this.dateToTeams = dateStr[1].trim()
						this.getAllTeamsLocal()
					}
				},
			})
		},
		showTeamWizardLocal(id) {
			this.teamIdSelected = id
			setTimeout(() => { this.$refs.teamWizardComponent.$refs.btnShowTeam.click() }, 50)
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}	
</script>

<style lang="scss"></style>
