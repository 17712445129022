<template>
	<div id="printTypeListComponent">
		<div class="card z-1 mb-3" id="recentPurchaseTable">
			<div class="card-header">
				<div class="row flex-between-center">
					<div class="col-6 col-sm-auto d-flex align-items-center pe-0">
						<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Tipos de Impresos</h5>
					</div>
					<div class="col-6 col-sm-auto ms-auto text-end ps-0">
						<div id="table-purchases-replace-element">
							<button class="btn btn-falcon-default btn-sm" type="button" v-show="false">
								<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Nuevo</span>
							</button>
							<span>
								<span @click="showListPrintTypes = !showListPrintTypes" class="pointer">
									<span v-show="!showListPrintTypes">
										<i class="far fa-eye"></i>
									</span>
									<span v-show="showListPrintTypes">
										<i class="far fa-eye-slash"></i>
									</span>
								</span>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div v-show="showListPrintTypes">
				<div class="card-body px-0 py-0">
					<div class="table-responsive scrollbar">
						<table class="table table-sm fs--1 mb-0 overflow-hidden">
							<thead class="bg-200">
								<tr>
									<th class="text-900 sort pe-1 align-middle white-space-nowrap" data-sort="name">
										Tipo</th>
									<th class="text-900 sort pe-1 align-middle white-space-nowrap"
										data-sort="description">
										Descripción</th>
									<th class="text-900 sort pe-1 align-middle white-space-nowrap" data-sort="status">
										Multi Componente</th>
									<th class="text-900 sort pe-1 align-middle white-space-nowrap" data-sort="actions">
									</th>
								</tr>
							</thead>
							<tbody class="list" id="table-purchase-body">
								<tr class="btn-reveal-trigger" v-for="(tp, i) in listPrintTypesFilter" :key="i">
									<td class="align-middle white-space-nowrap name">
										<span class="d-inline-block text-truncate" style="max-width: 379px;">
											{{ tp.name }}
										</span>
									</td>
									<td class="align-middle white-space-nowrap email">
										<span class="d-inline-block text-truncate" style="max-width: 900px;">
											{{ tp.description }}
										</span>
									</td>
									<td class="align-middle text-center fs-0 white-space-nowrap payment">
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="card-footer">
					<div class="d-flex align-items-center justify-content-center">
						<pagination :pagination="paginationPrintType" @getAllList="getPrintTypesLocal">
						</pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import printType from '@/mixins/productions/printType.js'

import Pagination from '@/components/Pagination.vue'

export default {
	name: 'PrintTypeListComponent',
	mixins: [mixin, axiosAction, errors, printType],
	props: [],
	data() {
		return {
			showListPrintTypes: true,
			n: 10
		}
	},
	components: {
		'pagination': Pagination,
	},
	computed: {
		listPrintTypesFilter() {
			return this.listPrintTypes
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getPrintTypesResult': this.getPrintTypesResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		getPrintTypesLocal() {
			this.getPrintTypes()
		},
		getPrintTypesResultLocal(response) {
		},
		initModuleLocal() {
			this.setPrintTypeListComponentInit()
		},
		setPrintTypeListComponentInit() {
			this.getPrintTypesLocal()
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>