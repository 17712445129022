// Reemplazar pf por la inicial del modelo
// Reemplazar ProductionFunction por el nombre del Modelo
// Reemplazar productionFunction por el nombre del modelo
var productionFunction = {
	data() {
		return {
			listProductionFunctions: [],
			loadingProductionFunctions: false,
			paginationProductionFunctions: [],
			objectProductionFunction: {
				exists: 0,
				id: 0,
				productionFunction: '',
				created_at: null,
				updated_at: null,
			},
			savingProductionFunction: false,
			searchingProductionFunction: false,
			searchProductionFunctions: ''
		}
	},
	methods: {
		addItemListProductionFunctions(pf) {
			this.listProductionFunctions.unshift(pf)
		},
		clearObjectProductionFunction() {
			this.objectProductionFunction.exists = 0
			this.objectProductionFunction.id = 0
			this.objectProductionFunction.productionFunction = ''
			this.objectProductionFunction.created_at = null
			this.objectProductionFunction.updated_at = null
		},
		getProductionFunctions(page = 1, resource = '') {
			this.listProductionFunctions = []
			this.paginationProductionFunctions = []
			this.loadingProductionFunctions = true
			let param = '?page=' + page + '&search=' + this.searchProductionFunctions + '&resource=' + resource
			let url = this.getApiBackendNew + 'productionFunctions' + param
			this.axiosActionIS(url, 'GET', 'getProductionFunctionsResult')
		},
		getProductionFunctionsResult(response) {
			this.loadingProductionFunctions = false
			if (response.data.success == true) {
				this.listProductionFunctions = response.data.data
				this.paginationProductionFunctions = response.data.paginate
			}
			this.getProductionFunctionsResultLocal(response)
		},
		getProductionFunctionsResultLocal(response) {
			console.log('getProductionFunctionsResultLocal no desarrollado', response)
		},
		saveProductionFunction(pf) {
			this.savingProductionFunction = true
			let method = (pf.id == 0) ? 'POST' : 'PUT'
			let url = this.getApiBackendNew + 'productionFunction/'
			url += (method == 'POST') ? 'register' : pf.id + '/update'
			this.axiosActionIS(url, method, 'saveProductionFunctionResult', pf)
		},
		saveProductionFunctionResult(response) {
			this.savingProductionFunction = false
			if (response.data.success == true) {
				this.setObjectProductionFunction(response.data.data)
			}
			this.saveProductionFunctionResultLocal(response)
		},
		saveProductionFunctionResultLocal(response) {
			console.log('saveProductionFunctionResultLocal no desarrollado', response)
		},
		setObjectProductionFunction(pf) {
			this.clearObjectProductionFunction()
			for (const property in pf) {
				this.objectProductionFunction[property] = r[property]
			}
		},
		showProductionFunction(id) {
			this.searchingProductionFunction = true
			let url = this.getApiBackendNew + 'productionFunction/' + id
			this.axiosActionIS(url, 'GET', 'showProductionFunctionResult')
		},
		showProductionFunctionResult(response) {
			this.searchingProductionFunction = false
			if (response.data.success == true) {
				this.setObjectProductionFunction(response.data.data)
			}
			this.showProductionFunctionResultLocal(response)
		},
		showProductionFunctionResultLocal(response) {
			console.log('showProductionFunctionResultLocal no desarrollado', response)
		}
	},
};

export default productionFunction;