/* Rquiere de mixin.js */

var payment = {
	data() {
		return {
			dateFromPayments: '2020-07-15',
			dateToPayments: '2020-07-18',
			objectPayment: {
				exists: 0,
				id: 0,
				type_payment_id: 1,
				type_payment: null,
				amount: 0,
				bank_id: 0,
				bank: null,
				code: '',
				description: '',
				transaction_date: '',
				status_id: 0,
				status: {},
				created_at: '',
				updated_at: '',
				journal_item_id: null,
				source: null
			},
			listPayments: [],
			methodsPayment: {
				get: 'payments',
				show: 'payment',
				delete: 'delete'
			},
			paginationPayments: [],
			searchPayments: '',
			savingPayment: false,
			searchingPayment: false,
			searchingPaymentUploadDocument: false,
			qRowsPayment: 25,
			loadingPayments: false
		}
	},
	computed: {
		listBanks() {
			let listBanks = []
			let index = this.listTypePayments.map((el) => parseInt(el.id)).indexOf(parseInt(this.objectPayment.type_payment_id))
			if (index > -1)
				listBanks = this.listTypePayments[index].listBanks
			return listBanks
		},
		listStatusPayment() {
			let list = [
				{ id: 38, name: 'Sin Confirmar', synthetic: 'CONFIRM', type_status_id: 7, color: 'secondary', created_at: null, updated_at: null },
				{ id: 39, name: 'Confirmado', synthetic: 'CONFIRMED', type_status_id: 7, color: 'success', created_at: null, updated_at: null },
				{ id: 40, name: 'Rechazado', synthetic: 'REJECTED', type_status_id: 7, color: 'danger', created_at: null, updated_at: null }
			]

			return list
		}
	},
	filters: {
	},
	methods: {
		analizePaymentsRejectedLocal(payments) {
			let result = 0
			Object.values(payments).forEach(item => {
				if (item.status_id == 40) {
					result++
				}
			})
			return result
		},
		addListPayments(newPayment) {
			this.listPayments.push(newPayment)
		},
		changeQRowsPayment() {
			localStorage.setItem('qRowsPayment', this.qRowsPayment)
			this.changeQRowsPaymentLocal()
		},
		changeQRowsPaymentLocal() {
			console.log('changeQRowsPaymentLocal no desarrollado')
		},
		clearObjectPayment() {
			this.objectPayment.exists = 0
			this.objectPayment.id = 0
			this.objectPayment.type_payment_id = 1
			this.objectPayment.type_payment = null
			this.objectPayment.amount = 0
			this.objectPayment.bank_id = 0
			this.objectPayment.bank = null
			this.objectPayment.code = ''
			this.objectPayment.description = ''
			this.objectPayment.transaction_date = this.currentDate()
			this.objectPayment.status_id = 0
			this.objectPayment.status = null
			this.objectPayment.created_at = ''
			this.objectPayment.updated_at = ''
			this.objectPayment.journal_item_id = null
			this.objectPayment.source = null
		},
		deleteItemPayment(id) {
			let index = this.listPayments.map((el) => parseInt(el.id)).indexOf(parseInt(id))
			let payments = this.listPayments.splice(index, 1)
		},
		deletePayment(controller, controllerId, id) {
			let url = this.getApiBackendNew + controller + '/' + controllerId + '/' + this.methodsPayment.show + '/' + id + '/' + this.methodsPayment.delete
			this.axiosActionIS(url, 'DELETE', 'deletePaymentResult')
		},
		deletePaymentResult(response) {
			if (response.data.success === true) {
				this.deleteItemPayment(response.data.data)
			} else {
				console.log(response)
			}
			this.deletePaymentResultLocal(response)
		},
		deletePaymentResultLocal(response) {
			console.log('deletePaymentResultLocal no desarrollado', response)
		},
		getPayments(page = 1) {
			this.loadingPayments = true
			let param = '?search=' + this.searchPayments + '&page=' + page + '&qRow=' + this.qRowsPayment + '&fromDate=' + this.dateFromPayments + '&toDate='
			let url = this.getApiBackendNew + 'payments' + param
			this.axiosActionIS(url, 'GET', 'getAllPaymentsResult')
		},
		getAllPayments(page = 1) {
			this.loadingPayments = true
			let parameters = '?search=' + this.searchPayments + '&page=' + page + '&qRow=' + this.qRowsPayment + '&fromDate=' + this.dateFromPayments + '&toDate='
			let url = this.getApiBackendNew + this.methodsPayment.get + parameters
			console.log(url)
			this.axiosActionIS(url, 'GET', 'getAllPaymentsResult')
		},
		getAllPaymentsResult(response) {
			this.loadingPayments = false
			if (response.data.success == true) {
				this.listPayments = response.data.data
				this.paginationPayments = response.data.paginate
			}
			this.getAllPaymentsResultLocal(response)
		},
		getAllPaymentsResultLocal(response) {
			console.log('getAllPaymentsResultLocal no desarrollado', response)
		},
		getPaymentByController(id, controller = '', method = null) {
			this.loadingPayments = true
			method = (method == null) ? this.methodsPayment.get : method
			let url = this.getApiBackendNew + controller + '/' + id + '/' + this.methodsPayment.get
			this.axiosActionIS(url, 'GET', 'getPaymentByControllerResult')
		},
		getPaymentByControllerResult(response) {
			this.loadingPayments = false
			if (response.data.success === true) {
				this.listPayments = response.data.data
			} else {
				console.warn('El formato de la respuesta no es valida')
			}
			this.getPaymentByControllerResultLocal(response)
		},
		getPaymentByControllerResultLocal(response) {
			console.log('getPaymentByControllerResultLocal no desarrollado', response)
		},
		getPaymentUploadDocument(id) {
			this.searchingPaymentUploadDocument = true
			let url = this.getApiBackendNew + 'payment/' + id + '/getUploadDocument'
			this.axiosActionIS(url, 'GET', 'getPaymentUploadDocumentResult')
		},
		getPaymentUploadDocumentResult(response) {
			this.searchingPaymentUploadDocument = false
			this.getPaymentUploadDocumentResultLocal(response)
		},
		getPaymentUploadDocumentResultLocal(response) {
			console.log('getPaymentUploadDocumentResult no desarrollado', response)
		},
		paymentSource(source) {
			if (source.type != undefined) {
				if (source.type == 'order') {
					return source.data[0].typeOrder.syntethic + ' ' + source.data[0].id
				}
				if (source.type == 'consignment') {
					return 'REMESA ' + source.data[0].id
				}
			}
			return '-'
		},
		savePayment(controller, id) {
			this.savingPayment = true
			let url = this.getApiBackendNew + controller + '/' + id + '/' + this.methodsPayment.show + '/register'
			console.log(url, this.objectPayment)
			this.axiosActionIS(url, 'POST', 'savePaymentResult', this.objectPayment)
		},
		savePaymentResult(response) {
			this.savingPayment = false
			if (response.data.success) {
				this.addListPayments(response.data.data)
			} else {
				console.log(response)
			}
			this.savePaymentResultLocal(response)
		},
		savePaymentResultLocal(response) {
			console.log('savePaymentResultLocal no desarrollado')
		},
		setObjectPayment(p) {
			this.clearObjectPayment()
			let newItem = Object.create(p)
			for (const property in newItem) {
				this.objectPayment[property] = newItem[property]
			}
		},
		setPaymentInit() {
			this.qRowsPayment = (localStorage.getItem('qRowsPayment') === null) ? this.qRowsPayment : localStorage.getItem('qRowsPayment')
			this.dateFromPayments = this.currentDate(-30)
			this.dateToPayments = this.currentDate()
			this.setPaymentInitLocal()
		},
		showPayment(id) {
			this.searchingPayment = true
			let url = this.getApiBackendNew + 'payment/' + id + '?resource=paymentFull'
			this.axiosActionIS(url, 'GET', 'showPaymentResult')
		},
		showPaymentResult(response) {
			this.searchingPayment = false
			if (response.data.success == true) {
				this.setObjectPayment(response.data.data)
			}
			this.showPaymentResultLocal(response)
		},
		showPaymentResultLocal(response) {
			console.log('showPaymentResultLocal no desarrollado', response)
		},
		updateListPayments(p) {
			let i = this.listPayments.map(lp => parseInt(lp.id)).indexOf(parseInt(p.id))
			if (i > -1) {
				let newItem = Object.create(p)
				for (const property in newItem) {
					this.listPayments[i][property] = newItem[property]
				}
			}
		},
		updatePayment(id, column, value, resource = 'paymentFull') {
			this.savingPayment = true
			let data = {
				column: column,
				value: value,
				resource: resource
			}
			let url = this.getApiBackendNew + 'payment/' + id + '/update'
			this.axiosActionIS(url, 'PUT', 'updatePaymentResult', data)
		},
		updatePaymentResult(response) {
			this.savingPayment = false
			if (response.data.success == true) {
				this.setObjectPayment(response.data.data)
				this.updateListPayments(response.data.data)
			}
			this.updatePaymentResultLocal(response)
		},
		updatePaymentResultLocal(response) {
			console.log('updatePaymentResultLocal no desarrollado', response)
		}
	},
};

export default payment;