var analizeSTR = {
	data() {
		return {
			lengthISBN: 13,
			lengthSE: 20,
			lengthMA: 9,
		}
	},
	methods: {
		analizeSTR(s) {
			let result = this.analizeIsNumber(s)
			result = (result == null) ? this.analizeIsSKU(s) : result
			result = (result == null) ? this.analizeIsShipping(s) : result
			result = (result == null) ? this.analizeIsShippingRequest(s) : result
			result = (result == null) ? this.analizeIsManifesto(s) : result
			result = (result == null) ? this.analizeIsStoreRackSideRowColumn(s) : result
			result = (result == null) ? this.analizeIsDocumentNumber(s) : result
			result = (result == null) ? { result: 'CHARACTER', data: s, original: s } : result
			return result
		},
		analizeIsNumber(s) {
			if (!isNaN(s)) {
				if (s.trim() != '') {
					if (this.isInt(s)) {
						return (s.trim().length == this.lengthISBN) ? { result: 'ISBN', data: s.trim(), original: s } : { result: 'INTEGER', data: s, original: s }
					}
					if (this.isFloat(s)) {
						return { result: 'FLOAT', data: s, original: s }
					}
				}
			}
			return null
		},
		analizeIsSKU(s) {
			if (s.trim().length > 1 && s.trim().substring(0, 1).toUpperCase() == 'H' && this.isInt(s.trim().substring(1, s.trim().length)))
				return { result: 'PRODUCT', data: s.trim().substring(1, s.trim().length), original: s }
			return null
		},
		analizeIsShipping(s) {
			if (s.trim().length > 2 && s.trim().substring(0, 2).toUpperCase() == 'EV' && this.isInt(s.trim().substring(2, s.trim().length)) && s.trim().length <= this.lengthSE + 2) {
				let data = s.trim().substring(2, s.trim().length)
				data = (data.length > 16) ? data.substring(0, 16) : data
				return { result: 'SHIPPING', data: parseInt(data), original: s }
			}
			return null
		},
		analizeIsShippingRequest(s) {
			if (s.trim().length > 2 && s.trim().substring(0, 2).toUpperCase() == 'SE' && this.isInt(s.trim().substring(2, s.trim().length)) && s.trim().length <= this.lengthSE + 2) {
				let data = s.trim().substring(2, s.trim().length)
				data = (data.length > 16) ? data.substring(0, 16) : data
				return { result: 'SHIPPINGREQUEST', data: parseInt(data), original: s }
			}
			return null
		},
		analizeIsManifesto(s) {
			if (s.trim().length > 2 && s.trim().substring(0, 2).toUpperCase() == 'MA' && this.isInt(s.trim().substring(2, s.trim().length)) && s.trim().length <= this.lengthMA + 2) {
				let data = s.trim().substring(2, s.trim().length)
				return { result: 'MANIFESTO', data: data, original: s }
			}
			return null
		},
		analizeIsStoreRackSideRowColumn(s) {
			let arr = s.split('.')
			if (arr.length == 5) {
				let result = true
				arr.forEach(element => {
					result = (this.isInt(element) && result) ? true : false
				})
				if (result)
					return { result: 'STORERACKSIDEROWCOLUMN', data: arr[4], original: s }
			}
			return null
		},
		analizeIsDocumentNumber(s) {
			let str = s.replaceAll('.', '')
			let format = /^\d{1,2}\d{3}\d{3}[-][0-9kK]{1}$/
			return (format.test(str)) ? { result: 'DOCUMENTNUMBER', data: str, original: s } : null
		}
	},
};

export default analizeSTR;