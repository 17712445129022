<template>
	<div id="priceCalculationComponent">
		<button ref="btnShowPriceCalculation" @click="showPriceCalculationLocal" v-show="false" class="btn btn-primary">
			Show Price Calculation
		</button>
		<div>
			<div class="mb-3">
				<label class="form-label">Descripción</label>
				<input class="form-control form-control-sm" type="text" v-model="objectPriceCalculation.observation" />
			</div>
			<div class="mb-3">
				<div class="row">
					<div class="col-md-7">
						<label class="form-label">Script</label>
						<text-area-component ref="textAreaComponent" :text="objectPriceCalculation.script"
							:_class="'form-control form-control-sm fs--1'" :_rows="15"
							@keyup="keyupLocal"></text-area-component>
					</div>
					<div class="col-md-5">

						<ul class="nav nav-tabs" id="myTab" role="tablist">
							<li class="nav-item">
								<button class="nav-link active" id="variables-tab" data-bs-toggle="tab"
									data-bs-target="#variables" type="button" role="tab" aria-controls="variables"
									aria-selected="true" ref="navItemVariables">Variables</button>
							</li>
							<li class="nav-item">
								<button class="nav-link" id="result-tab" data-bs-toggle="tab" data-bs-target="#result"
									type="button" role="tab" aria-controls="result" aria-selected="false"
									:disabled="result == null" ref="navItemResult"
									:class="(result == null) ? 'not-allowed' : ''">Resultado</button>
							</li>
						</ul>
						<div class="tab-content" id="myTabContent">
							<div class="tab-pane fade show active" id="variables" role="tabpanel"
								aria-labelledby="variables-tab">
								<table class="table table-sm border fs--1">
									<tr v-for="(vs, i) in listVariablesScriptFilter" :key="i">
										<td class="text-end">{{ vs.name }}:</td>
										<td class="text-end">
											<input class="form-control form-control-sm fs--1" v-model="vs.val">
										</td>
									</tr>
								</table>
								<div class="text-end">
									<button class="btn btn-sm btn-info"
										@click="execPriceCalculationById(objectPriceCalculation.id, listVariablesScriptFilterInline)">Probar</button>
								</div>

							</div>
							<div class="tab-pane fade" id="result" role="tabpanel" aria-labelledby="result-tab">
								<pre>{{ result }}</pre>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="mb-3">
				<label class="form-label">Tipo de Calculo de precio</label>
				<select class="form-select form-select-sm" v-model="objectPriceCalculation.price_calculation_type_id">
					<option :value="null" disabled>Seleccione un tipo</option>
					<option v-for="(pct, i) in listPriceCalculationTypes" :key="i" :value="pct.id">{{ pct.name }}
					</option>
				</select>
			</div>
			<div class="text-end">
				<button class="btn btn-default btn-sm ms-2" @click="closePriceCalculation">Cerrar</button>
				<button class="btn btn-primary btn-sm ms-2" :disabled="disabledBtnSavePriceCalculation"
					@click="savePriceCalculationLocal">
					<span v-show="!savingPriceCalculation">Guardar</span>
					<span v-show="savingPriceCalculation">Guardando...</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import priceCalculation from '@/mixins/prices/priceCalculation.js'
import priceCalculationType from '@/mixins/prices/priceCalculationType.js'

import TextAreaComponent from '@/components/TextAreaComponent.vue'

export default {
	name: 'PriceCalculationComponent',
	mixins: [mixin, axiosAction, errors, priceCalculation, priceCalculationType],
	props: ['id'],
	data() {
		return {
			text: '',
			listVariablesScriptF: [],
			result: null
		}
	},
	components: {
		'text-area-component': TextAreaComponent
	},
	computed: {
		disabledBtnSavePriceCalculation() {
			if (this.validateObjectPriceCalculation == false) {
				return true
			}
			if (this.savingPriceCalculation == true) {
				return true
			}
			return false
		},
		listPriceCalculationTypesFilter() {
			return this.listPriceCalculationTypes
		},
		listVariablesScriptFilter() {
			let result = []
			let str = (this.objectPriceCalculation.script == undefined) ? '' : this.objectPriceCalculation.script
			let search_1 = '{{'
			let search_2 = '}}'
			let begin = 0
			let variable
			while (begin != -1 && str.length > 0) {
				begin = str.indexOf(search_1)
				if (begin > -1) {
					str = str.substr(begin + search_1.length, str.length - begin - search_1.length)
					begin = str.indexOf(search_2)
					if (begin > -1) {
						variable = str.substr(0, begin)
						let index = result.map((el) => el.name).indexOf(variable.trim())
						if (index == -1) {
							result.push({ 'name': variable.trim(), 'val': '', 'original': search_1 + variable + search_2 })
						}
						str = str.substr(begin + search_2.length, str.length - begin - search_2.length)
					}
				}
			}
			this.listVariablesScriptF = result
			return result
		},
		listVariablesScriptFilterInline() {
			let p = ''
			this.listVariablesScriptFilter.forEach(e => {
				p += (p == '') ? e.name + '=' + e.val : '&' + e.name + '=' + e.val
			});
			return p
		},
		validateObjectPriceCalculation() {
			if (this.objectPriceCalculation.observation.trim().length < 5) {
				return false
			}
			return true
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'execPriceCalculationByIdResult': this.execPriceCalculationByIdResult(response); break;
				case 'getPriceCalculationTypesResult': this.getPriceCalculationTypesResult(response); break;
				case 'savePriceCalculationResult': this.savePriceCalculationResult(response); break;
				case 'showPriceCalculationResult': this.showPriceCalculationResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		clearObjectPriceCalculationLocal() {
			this.clearObjectPriceCalculation()
			setTimeout(() => { this.$refs.navItemVariables.click() }, 50)
			this.result = null
		},
		closePriceCalculation() {
			this.clearObjectPriceCalculationLocal()
			this.$emit('closePriceCalculation')
		},
		execPriceCalculationByIdResultLocal(response) {
			this.result = null
			if (response.data.success == true) {
				this.result = response.data.data
				setTimeout(() => { this.$refs.navItemResult.click() }, 50)
			} else {
				this.result = response.data.data
				this.showMsgErrorHandling(response.data.message, '<pre>' + response.data.data + '</pre>', 'error', 5000, 'top right')
			}
		},
		getPriceCalculationTypesLocal(page = 1) {
			this.getPriceCalculationTypes(page)
		},
		getPriceCalculationTypesResultLocal(response) {
		},
		initModuleLocal() {
			this.setPriceCalculationComponentInit()
		},
		keyupLocal(text) {
			this.objectPriceCalculation.script = text
		},
		savePriceCalculationLocal() {
			this.savePriceCalculation(this.objectPriceCalculation)
		},
		savePriceCalculationResultLocal(response) {
			this.$refs.navItemVariables.click()
			if (response.data.success == true) {
				console.log()
				this.$emit('savePriceCalculationResult', response)
			}
		},
		setPriceCalculationComponentInit() {
			this.getPriceCalculationTypesLocal()
		},
		showPriceCalculationLocal() {
			this.clearObjectPriceCalculationLocal()
			setTimeout(() => { this.$refs.textAreaComponent.$refs.btnSetText.click() }, 10)
			if (this.id > 0) {
				this.showPriceCalculation(this.id)
			}
		},
		showPriceCalculationResultLocal(response) {
			if (response.data.success == true) {
				setTimeout(() => { this.$refs.textAreaComponent.$refs.btnSetText.click() }, 10)
			} else {
				document.title = 'Ocurrio una Problema!!!'
			}
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>