var jumpsellerPromotion = {
	data() {
		return {
			listJumsellerPromotions: [],
			loadingJumsellerPromotions: false,
		}
	},
	methods: {
		getJumpsellerPromotions(page = 1, limit = 20) {
			this.loadingJumsellerPromotions = true
			this.listJumsellerPromotions = []
			let param = '?page=' + page + '&limit=' + limit
			let url = this.getApiBackendNew + 'jumpseller/promotions' + param
			this.axiosActionIS(url, 'GET', 'getJumpsellerPromotionsResult')
		},
		getJumpsellerPromotionsResult(response) {
			this.loadingJumsellerPromotions = false
			if (response.data.success == true) {
				this.listJumsellerPromotions = response.data.data
			}
			this.getJumpsellerPromotionsResultLocal(response)
		},
		getJumpsellerPromotionsResultLocal(response) {
			console.og('getJumpsellerPromotionsResultLocal no desarrollado', response)
		},
	},
};

export default jumpsellerPromotion;