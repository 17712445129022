/* Rquiere de mixin.js */

var priceList = {
    data() {
        return {
            listPriceLists: [],
            methods: {
                priceListGet: 'priceLists',
            },
            paginationPriceLists: [],
            searchPriceLists: '',
            priceListIdSelected: 0,
            searchingPriceList: false,
            priceListIdSelected: 0,
            defaultPriceListId: 1
        }
    },
    computed: {
    },
    filters: {
    },
    methods: {
        getAllPriceLists(page = 1) {
            this.searchingPriceList = true
            let parameters = '?search=' + this.searchPriceLists + '&page=' + page
            let url = this.getApiBackend + this.methods.priceListGet + parameters
            this.axiosActionIS(url, 'GET', 'getAllPriceListsResult')
        },
        getAllPriceListsResult(response) {
            this.searchingPriceList = false
            if (response.data.success == true) {
                this.listPriceLists = response.data.data
            }
            this.getAllPriceListsResultLocal(response)
        },
        getAllPriceListsResultLocal(response) {
            console.log('getAllPriceListsResultLocal no desarrollado', response)
        }
    },
};

export default priceList;