var teamClient = {
	data() {
		return {
			objectTeamClient: {
				id: 0,
				team_id: 0,
				team: {},
				client_id: 0,
				client: {},
				status_id: 1,
				status: {},
				university_career_id: 0,
				universityCareer: {},
				tuition: 1,
				lodging: 1,
				cost: 1,
				scholarship: 1,
				scholarship_media: 1,
				created_at: null,
				updated_at: null,
			},
			loadingTeamClient: false,
			loadingTeamClientExpenses: false
		}
	},
	methods: {
		deleteTeamClient(id) {
			this.deletingTeamClient = true
			let url = this.getApiBackendNew + 'teamClient/' + id + '/delete'
			this.axiosActionIS(url, 'DELETE', 'deleteTeamClientResult')
		},
		deleteTeamClientResult(response) {
			this.deletingTeamClient = false
			this.deleteTeamClientResultLocal(response)
		},
		deleteTeamClientResultLocal(response) {
			console.log('deleteTeamClientResult no desarrollado', response)
		},
		clearObjectTeamClient() {
			objectTeamClient.id = 0
			objectTeamClient.team_id = 0
			objectTeamClient.team = {}
			objectTeamClient.client_id = 0
			objectTeamClient.client = {}
			objectTeamClient.status_id = 1
			objectTeamClient.status = {}
			objectTeamClient.university_career_id = 0
			objectTeamClient.universityCareer = {}
			objectTeamClient.tuition = 1
			objectTeamClient.lodging = 1
			objectTeamClient.cost = 1
			objectTeamClient.scholarship = 1
			objectTeamClient.scholarship_media = 1
			objectTeamClient.created_at = null
			objectTeamClient.updated_at = null
		},
		getTeamClientExpenses(id) {
			this.loadingTeamClientExpenses = true
			let url = this.getApiBackendNew + 'teamClient/' + id + '/expenses'
			this.axiosActionIS(url, 'GET', 'getTeamClientExpensesResult', null)
		},
		getTeamClientExpensesResult(response) {
			this.loadingTeamClientExpenses = false
			if (response.data.success == true) {
				this.listExpenses = response.data.data
			}
			this.getTeamClientExpensesResultLocal(response)
		},
		getTeamClientExpensesResultLocal(response) {
			console.log('getTeamClientExpensesResultLocal no desarrollado')
		},
		saveTeamClient(teamId, clientId) {
			this.savingTeamClient = true
			let param = {
				client_id: clientId
			}
			let url = this.getApiBackendNew + 'team/' + teamId + '/client/register'
			console.log(url)
			this.axiosActionIS(url, 'POST', 'saveTeamClientResult', param)
		},
		saveTeamClientResult(response) {
			this.savingTeamClient = false
			this.saveTeamClientResultLocal(response)
		},
		saveTeamClientResultLocal(response) {
			console.log('saveTeamClientResultLocal no desarrollado', response)
		},
		setObjectTeamClient(item) {
			let newItem = Object.create(item)
			for (const property in newItem) {
				this.objectTeamClient[property] = newItem[property]
			}
		},
		showTeamClient(teamId, clientId) {
			this.loadingTeamClient = true
			let url = this.getApiBackendNew + 'team/' + teamId + '/client/' + clientId
			this.axiosActionIS(url, 'GET', 'showTeamClientResult', null)
		},
		showTeamClientResult(response) {
			if (response.data.success == true) {
				this.setObjectTeamClient(response.data.data)
			}
			this.showTeamClientResultLocal(response)
		},
		showTeamClientResultLocal(response) {
			console.log('showTeamClientResultLocal no desarrollado', response)
		},
		updateTeamClient(id, data) {
			this.loadingTeamClient = true
			let url = this.getApiBackendNew + 'teamClient/' + id
			this.axiosActionIS(url, 'PUT', 'updateTeamClientResult', data)
		},
		updateTeamClientResult(response) {
			this.loadingTeamClient = false
			if (response.data.success == true) {
				this.setObjectTeamClient(response.data.data)
			}
			this.updateTeamClientResultLocal(response)
		},
		updateTeamClientResultLocal(response) {
			console.log('updateTeamClientResultLocal no desarrollado', response)
		},
		sumTeamClientPayment(payments) {
			let total = payments.reduce(function (a, b) {
				return parseFloat(a) + parseFloat(b.amount)
			}, 0);
			return total.toFixed(0)
		}
	},
};

export default teamClient;