<template>
	<div id="autoCompleteInputComponent">
		<button class="btn btn-sm btn-primary" ref="btnSetString" @click="setDefautlString" v-show="false">Set
			String</button>
		<div class="autocomplete-input-componenet">
			<input text="text" v-model="string" @focus="focusF" @blur="blourF" :class="classLocal"
				:placeholder="placeholderLocal" :disabled="_disabled" @keyup="keyupLocal($event)" />
			<button @click="clear" v-show="false" ref="clear">Limpiar</button>
			<small class="form-text text-muted" v-show="searching">
				<i class="spinner-border" style="width: 16px; height: 16px"></i> <i>Buscando información </i>
			</small>
			<ul class="list-result-input" v-show="showList">
				<li v-for="(item, index) in list" :key="index" @click="selectItem(item)">
					<span>{{ item.text }}</span>
					<i class="float-end"><small>{{ item.extra }}</small></i>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import errors from '@/mixins/errors.js'
import axiosAction from '@/mixins/axiosAction.js'

export default {
	name: 'AutoCompleteInputComponent',
	mixins: [mixin, errors, axiosAction],
	props: ['_class', '_placeholder', '_path', '_disabled', '_defaultString'],
	data() {
		return {
			click: false,
			list: [],
			string: '',
			searching: false,
			minChar: 3,
			data: {
				id: 0,
				string: '',
				item: [],
				text: ''
			},
			focus: false
		}
	},
	computed: {
		classLocal() {
			return this._class
		},
		placeholderLocal() {
			return this._placeholder
		},
		showList() {
			return (this.string.length > this.minChar && this.list.length > 0 && this.focus)
		},
		selectedTrue() {
			return this.data.id > 0
		}
	},
	methods: {
		blourF() {
			setTimeout(() => {
				this.focus = false
				this.list = []
			}, 300);
			if (this.data.id == 0) {
				this.data.id = 0
				this.data.string = this.string
				this.data.item = {}
				this.$emit('actionSelectItem', this.data, false)
			}
		},
		clear() {
			this.string = ''
			this.list = []
			this.clearData()
		},
		clearData() {
			this.data.id = 0
			this.data.string = ''
			this.data.item = {}
		},
		focusF() {
			this.focus = true
		},
		getData() {
			this.searching = true
			let param = '?search=' + this.string
			this.axiosActionIS(this._path + param, 'GET', 'getDataResult')
		},
		getDataResult(response) {
			if (response.data.success === true) {
				this.list = response.data.data
			} else {
				console.log('No encontró nada o estal mal el formato de lo obtenido', response.data)
			}
			this.searching = false
		},
		keyupLocal(event) {
			this.click = false
			if (this.data.id > 0) {
				this.clearData()
				this.$emit('actionSelectItem', this.data, false)
			}

			this.string = this.string.trimStart()
			if (this.string.length > this.minChar) {
				this.getData()
			} else {
				this.list = []
			}
		},
		setDefautlString() {
			this.clear()
			this.string = this._defaultString
		},
		axiosResultLocal(responseLocal, response) {
			this.$emit('hideLockScreen')
			switch (responseLocal) {
				case 'getDataResult': this.getDataResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')'); break;
			}
		},
		selectItem(item) {
			this.string = item.text
			this.list = []
			this.data.id = item.id
			this.data.string = item.text
			this.data.item = item
			this.click = true
			setTimeout(() => {
				this.$emit('actionSelectItem', this.data, true)
			}, 500)
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.autocomplete-input-componenet {
	position: relative;

	ul {
		margin-top: -19px;
		border: 1px solid #ccc;
		background-color: white;
		position: absolute;
		z-index: 1;
		width: 100%;
		box-shadow: 1px 1px #ccc;
		list-style: none;
		padding: 0;

		li {
			padding: 5px 10px;
			border-bottom: 1px solid #ddd;
		}

		li:hover {
			background: #5bc0de;
			color: #fff;
			cursor: pointer;
		}
	}
}
</style>