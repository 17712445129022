<template>
	<div id="shippingRequestComponent">
		<button ref="btnClearShippingRequest" @click="clearShippingRequestLocal" v-show="false">Clear
			ShippingRequestComponent</button>
		<button ref="btnDeleteShippingRequest" @click="deleteShippingRequestLocal" v-show="false">Delete
			ShippingRequestComponent</button>
		<button ref="btnSaveShippingRequest" @click="saveShippingRequestLocal" v-show="false">Save
			ShippingRequestComponent</button>
		<button ref="btnShowShippingRequest" @click="showShippingRequestLocal" v-show="false">Show
			ShippingRequestComponent </button>
		<button ref="btnShowShippingRequestConsole" @click="showShippingRequestConsole" v-show="false">Show
			ShippingRequestComponent </button>
		<button ref="btnToCompleteData" @click="toCompleteShippingRequestLocal" v-show="false">Show
			ShippingRequestComponent </button>
		<div v-show="!this.gettingShippingRequest">
			<div v-show="objectShippingRequest.id > 0" class="mb-1">
				<label class="form-label" for="statusSelect">
					Estado:
				</label>
				<select class="form-select form-select-sm" aria-label=".form-select-sm example"
					v-model="trackingsShippingIDSelected" @change="changeStatusSelectLocal"
					:disabled="disabledSelectdTrackingsShipping">
					<option v-for="(tsr, i) in listTrackingsShippingRequestFilter" :key="i" :value="tsr.id"
						v-if="tsr.status_id != null" :disabled="objectShippingRequest.status_id == tsr.status_id">{{
							tsr.status.name }}</option>
				</select>
			</div>
			<div class="border-bottom border-muted mb-2" v-if="linkGenesys && objectShippingRequest.id == 0">
				<div class="text-center fs-1">Vinculación con Genesys</div>
				<div class="row g-2">
					<div class="col-7">
						<div class="mb-1">
							<label class="form-label" for="tipocompSelect">Comprobante:</label>
							<select class="form-select form-select-sm" aria-label=".form-select-sm example"
								v-model="tipocompcodSearch" @change="changeTipocompSelectLocal" :disabled="disabledAll">
								<option value="0" selected>Sin vinculación</option>
								<option :value="tc.tipocompcod" v-for="(tc, i) in listTipocompFilter" :key="i">{{
									tc.tipocompdesc }}</option>
							</select>
						</div>
					</div>
					<div class="col-5">
						<div class="mb-1">
							<label class="form-label" for="nrocompInput">Número:</label>
							<div class="input-group">
								<input class="form-control form-control-sm shadow-none search" type="search"
									placeholder="Número" aria-label="search" ref="nrocompInput" v-model="nrocompSearch"
									@keyup.enter="getMovimientoLocal()" @change="changeNrocompInputLocal"
									:disabled="!(tipocompcodSearch > 0) || disabledAll">
								<div class="input-group-text bg-transparent pointer" @click="getMovimientoLocal()">
									<span class="fa fa-search fs--1 text-600"></span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="mb-1">
					<small id="emailHelp" class="fs--2 form-text text-muted">
						<div v-if="searchingMovimiento">
							<small>
								<small class="spinner-border spinner-border-sm" role="status">
									<span class="visually-hidden">Loading...</span>
								</small>
							</small>
							<i>Buscando el comprobante en genesys ...</i>
						</div>
						<div v-else>
							<span v-if="objectCabmov.nrocomp > 0">{{ objectCabmov.fecmov }} - {{ objectCabmov.succod }}
								| {{ objectCabmov.codbareti }}</span>
						</div>
					</small>
				</div>
			</div>
			<div class="border-bottom border-muted mb-2">
				<div class="mb-1">
					<label class="form-label" for="transportSelect">Transporte:</label>
					<div class="row g-2">
						<div class="col-6">
							<div class="">
								<select class="form-select form-select-sm" aria-label=".form-select-sm example"
									v-model="objectShippingRequest.recipient_data.transport_id" :disabled="disabledAll"
									@change="changeTransportSelectLocal">
									<option value="0" disabled selected>Seleccione el transporte</option>
									<option v-for="(t, index) in listTransportsFilter" :key="index" :value="t.id">{{
										t.name }}</option>
								</select>
							</div>
						</div>
						<div class="col-3">
							<div class="mb-1">
								<input class="form-control form-control-sm text-end" type="text" placeholder=""
									v-model="objectShippingRequest.total_price" :disabled="disabledAll" />
							</div>
						</div>
						<div class="col-3">
							<div class="form-check form-switch">
								<input class="form-check-input" type="checkbox"
									v-model="objectShippingRequest.recipient_data.paid_shipping"
									:disabled="disabledAll" />
								<label class="form-check-label" for="flexSwitchCheckDefault">Cta Cte</label>
							</div>
						</div>
					</div>
				</div>
				<div class="mb-1">
					<label class="form-label" for="sendToInput">Enviar a:</label>
					<input class="form-control form-control-sm" ref="sendToInput" type="text"
						placeholder="Juanito Perez" v-model="objectShippingRequest.recipient_data.sendto"
						:disabled="disabledAll" />
				</div>
				<div class="row g-2">
					<div class="col-6">
						<div class="mb-1">
							<label class="form-label" for="documentNumberInput">RUT:</label>
							<input class="form-control form-control-sm" type="text" placeholder="12345678-9"
								v-model="objectShippingRequest.recipient_data.number_document"
								:disabled="disabledAll" />
						</div>
					</div>
					<div class="col-6">
						<div class="mb-1">
							<label class="form-label" for="phoneInput">Telefono:</label>
							<input class="form-control form-control-sm" type="text" placeholder="987654321"
								v-model="objectShippingRequest.recipient_data.phone" :disabled="disabledAll" />
						</div>
					</div>
				</div>
				<div class="mb-1">
					<label class="form-label" for="emailInput">Email:</label>
					<input class="form-control form-control-sm" type="email" placeholder="juanito.perez@mail.com"
						v-model="objectShippingRequest.recipient_data.email" :disabled="disabledAll" />
				</div>
				<div class="mb-1">
					<div class="row">
						<div class="col-6">
							<label class="form-label" for="addressInput">Dirección:</label>
							<span v-show="validatedAddress" class="ms-1" title="Dirección Normalizada">
								<i class="fas fa-check text-success"></i>
							</span>
						</div>
						<div class="col-6">
							<span class="form-check form-switch">
								<label class="form-check-label float-end" for="flexSwitchCheckDefault">Agencia</label>
								<input class="form-check-input float-end me-1" type="checkbox"
									v-model="objectShippingRequest.recipient_data.office" :disabled="disabledAll" />
							</span>
						</div>
					</div>
					<input class="form-control form-control-sm" type="text"
						placeholder="Nombre Calle número, otros datos" @blur="validateAddress()"
						@keyup.enter="validateAddress()" @keypress="validatedAddress = false"
						v-model="objectShippingRequest.recipient_data.address"
						:disabled="disabledAll || disabledAddress" />
				</div>
				<div class="mb-1">
					<label class="form-label" for="comunaSelect">
						Comuna:
						<i class="text-warning" v-if="objectShippingRequest.id > 0">
							<small
								v-if="objectShippingRequest.recipient_data.comuna != null && objectShippingRequest.recipient_data.comuna_id == null">
								iSystem4: {{ objectShippingRequest.recipient_data.comuna.name }}
							</small>
						</i>
					</label>
					<select class="form-select form-select-sm" aria-label=".form-select-sm example"
						v-model="objectShippingRequest.recipient_data.comuna_id" @change="changeComunaSelectLocal"
						:disabled="disabledAll || disabledAddress">
						<option value="0" disabled selected>Seleccione la Comuna</option>
						<option v-for="(c, i) in listComunasFilter" :key="i" :value="c.id">{{ c.name }} - {{
							c.region_synthetic }}</option>
					</select>
				</div>
				<div class="mb-3">
					<label class="form-label" for="observationInput">Observación:</label>
					<input class="form-control form-control-sm" type="text" placeholder="Algo para tomar en cuenta"
						v-model="objectShippingRequest.commentary" :disabled="disabledAll" />
				</div>
			</div>
			<div class="border-bottom border-muted mb-2"
				v-if="(objectShippingRequest.id == 0) || (objectShippingRequest.id > 0 && objectShippingRequest.legal_documents.length > 0)">
				<div class="text-center fs-1">Documento Legal</div>
				<div class="row g-2" v-if="objectShippingRequest.id == 0">
					<div class="col-7">
						<div class="mb-1">
							<label class="form-label" for="typeLegalDocumentSelect">Tipo Documento:</label>
							<select class="form-select form-select-sm" aria-label=".form-select-sm example"
								v-model="typeLegalDocumentId" :disabled="disabledAll"
								@change="changeTypeLegalDocumentSelect">
								<option value="0" selected>NO LLEVA</option>
								<option v-for="(td, i) in listTypeLegalDocumentsFilter" :key="i" :value="td.id">{{
									td.name }}</option>
							</select>
						</div>
					</div>
					<div class="col-5">
						<div class="mb-1">
							<label class="form-label" for="legaDocumentNumberInput">Número:</label>
							<input class="form-control form-control-sm" type="text" placeholder="Numero"
								v-model="legalDocumentNumber" :disabled="disabledAll || typeLegalDocumentId == 0" />
						</div>
					</div>
				</div>
				<div v-else>
					<table class="table tablesm fs--2">
						<thead>
							<tr>
								<th class="text-center">#</th>
								<th>Tipo</th>
								<th class="text-center">Numero</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(ld, i) in objectShippingRequest.legal_documents" :key="i">
								<td class="text-center">{{ ld.id }}</td>
								<td>{{ ld.type_legal_document.name }}</td>
								<td class="text-center">{{ ld.legal_document_number }}</td>
								<td class="text-end">
									<a class="text-danger" :href="ld.url"
										:download="ld.type_legal_document.syntetic + ld.legal_document_number"
										v-show="ld.url != ''">
										<i class="far fa-file-pdf"></i>
									</a>
									<a class="text-warnign" :href="ld.url_assignable"
										:download="ld.type_legal_document.syntetic + 'C' + ld.legal_document_number"
										v-show="ld.url_assignable != ''">
										<i class="far fa-file-pdf"></i>
									</a>
									<span @click="toPrintLegalDocumentLocal(ld)"
										:class="(getSuccessFullConnectionFacturacionCLPrint) ? 'text-primary pointer' : 'not-allowed'">
										<i class="fas fa-print ms-2"></i>
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="border-bottom border-muted mb-2" v-if="this.objectShippingRequest.id > 0">
				<div class="border-bottom border-muted mb-2">
					<ul class="nav nav-tabs" id="myTab" role="tablist">
						<li class="nav-item fs--1"
							v-if="linkGenesys && this.objectShippingRequest.genesys_cabmov.length > 0">
							<a class="nav-link active" id="genesys-tab" data-bs-toggle="tab" href="#tab-genesys"
								role="tab" aria-controls="tab-genesys" aria-selected="true">Genesys</a>
						</li>
						<li class="nav-item fs--1"><a class="nav-link" id="orders-tab" data-bs-toggle="tab"
								href="#tab-orders" role="tab" aria-controls="tab-orders" aria-selected="false">Orden</a>
						</li>
						<li class="nav-item fs--1"><a class="nav-link" id="shippings-tab" data-bs-toggle="tab"
								href="#tab-shippings" role="tab" aria-controls="tab-shippings"
								aria-selected="false">Envío</a></li>
						<li class="nav-item fs--1"><a class="nav-link" id="tracking-shipping-request-tab"
								data-bs-toggle="tab" href="#tab-tracking-shipping-request" role="tab"
								aria-controls="tab-tracking-shipping-request" aria-selected="false">Seguimiento</a></li>
					</ul>
					<div class="tab-content p-0" id="myTabContent" style="height: 100%; overflow: auto; ">
						<div class="tab-pane fade show active" id="tab-genesys" role="tabpanel"
							aria-labelledby="genesys-tab" style="padding: 0px"
							v-if="linkGenesys && this.objectShippingRequest.genesys_cabmov.length > 0">
							<table class="table table-sm fs--2">
								<thead>
									<tr class="text-center">
										<th>#</th>
										<th>Comp</th>
										<th>Fecha</th>
										<th>Codigo</th>
										<th>Numero</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(gc, i) in listGenesysCabmovFilter" :key="i" class="text-center">
										<td>{{ gc.id }}</td>
										<td>{{ gc.tipocompcod }}</td>
										<td>{{ gc.fecmov }}</td>
										<td>{{ gc.codbareti }}</td>
										<td>{{ gc.nrocomp }}</td>
										<td>
											<span v-if="gc.printLD"
												:class="(getSuccessFullConnectionFacturacionCLPrint) ? 'text-primary pointer' : 'not-allowed'"
												@click="toPrintLegalDocumentGenesysLocal(gc)">
												<i class="fas fa-print ms-2"></i>
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="tab-pane fade" id="tab-orders" role="tabpanel" aria-labelledby="orders-tab">
							<table class="table table-sm fs--2">
								<thead>
									<tr>
										<th class="text-center">ID</th>
										<th>Cliente</th>
										<th class="text-center">Total</th>
										<th>Estado</th>
										<th>Tipo Orden</th>
										<th>Iglesia</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(o, i) in this.objectShippingRequest.orders" :key="i">
										<td class="text-center">{{ o.id }}</td>
										<td>{{ o.client.name }} {{ o.client.surname }}</td>
										<td class="text-center">{{ o.total }}</td>
										<td>{{ o.status.name }}</td>
										<td class="text-center">{{ o.type_order.name }}</td>
										<td class="text-center">
											<span v-if="o.church != null">
												{{ o.church.name }}
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="tab-pane fade" id="tab-shippings" role="tabpanel" aria-labelledby="shippings-tab">
							<table class="table table-sm fs--2">
								<thead>
									<tr>
										<th>Enviado a</th>
										<th>Dirección</th>
										<th class="text-center">Cta Cte</th>
										<th>Transporte</th>
										<th class="text-center">OF</th>
										<th class="text-center">Bultos</th>
										<th class="text-center">Peso</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(s, i) in this.objectShippingRequest.shipping" :key="i">
										<td>{{ s.sendto }}</td>
										<td>{{ s.address }}</td>
										<td class="text-center">{{ s.paid_shipping }}</td>
										<td class="text-center">
											<strong :class="'text-' + s.transport.color"
												:title="'Manifiesto Nº ' + s.manifesto.id">
												{{ s.transport.name }}
											</strong>
										</td>
										<td class="text-center">{{ s.freight_order }}</td>
										<td class="text-center">{{ s.packages }}</td>
										<td class="text-center">{{ s.weight }}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="tab-pane fade" id="tab-tracking-shipping-request" role="tabpanel"
							aria-labelledby="tracking-shipping-request-tab">
							<table class="table table-sm table-striped table-hover fs--2">
								<thead>
									<tr>
										<th class="text-center">ID</th>
										<th>Fecha</th>
										<th>Seguimiento</th>
										<th>Usuario</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(tsr, i) in this.objectShippingRequest.trackings_shipping_request"
										:key="i" @dblclick="console(tsr)" :title="tsr.observation">
										<td class="text-center">{{ tsr.id }}</td>
										<td class="text-center">{{ tsr.created_at }}</td>
										<td>{{ tsr.trackings_shipping_request.description }}</td>
										<td>{{ tsr.user.name }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-show="this.gettingShippingRequest">
			Buscando Solicitud de envio Nº {{ this.objectShippingRequest.id }}...
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import shippingsRequest from '@/mixins/shippingsRequest'
import transport from '@/mixins/generals/transport.js'
import comuna from '@/mixins/generals/comuna.js'
import correosChile from '@/mixins/generals/correosChile.js'
import genesys from '@/mixins/genesys'
import movimiento from '@/mixins/genesys/movimiento.js'
import domicilio from '@/mixins/genesys/domicilio.js'
import typeLegalDocument from '@/mixins/legalDocuments/typeLegalDocument.js'
import facturacionCL from '@/mixins/generals/facturacionCL.js'
import status from '@/mixins/status'
import trackingShippingRequest from '@/mixins/shippingsRequest/trackingShippingRequest.js'

export default {
	name: 'ShippingRequestComponent',
	mixins: [mixin, axiosAction, errors, shippingsRequest, transport, comuna, correosChile, genesys, movimiento, domicilio, typeLegalDocument, facturacionCL, status, trackingShippingRequest],
	props: ['withLinkGenesys', 'id', 'data'],
	data() {
		return {
			fromShow: 'internal',
			clicodsNotConsider: [0, 1, 5006121],
			typeLegalDocumentId: 0,
			legalDocumentNumber: '',
			idLocal: 0,
			trackingsShippingIDSelected: -1,
			validatedAddress: false,
			validatingAddress: false
		}
	},
	components: {
	},
	computed: {
		comunaSelected() {
			let comuna = null
			if (this.objectShippingRequest.recipient_data.comuna_id > 0) {
				let i = this.listComunasFilter.map(c => parseInt(c.id)).indexOf(parseInt(this.objectShippingRequest.recipient_data.comuna_id))
				if (i > -1) {
					comuna = this.listComunasFilter[i]
				}
			}
			return comuna
		},
		disabledAll() {
			if (this.searchingMovimiento) {
				return true
			}
			if (this.searchingDomicilio) {
				return true
			}
			if (this.savedShippingRequest) {
				return true
			}
			let i = this.listStatusEditable.map(s => parseInt(s)).indexOf(parseInt(this.objectShippingRequest.status_id))
			if (i == -1 && this.objectShippingRequest.status_id != null) {
				return true
			} else if (i > -1) {
				if (this.objectShippingRequest.user_id != this.getUser.id && !this.actionSecurity(17)) {
					return true
				}
			}
			return false
		},
		disabledAddress() {
			return (this.objectShippingRequest.recipient_data.transport_id == 0)
		},
		disabledSelectdTrackingsShipping() {
			if (this.disabledAll) {
				if (!this.searchingMovimiento && !this.searchingDomicilio && !this.savedShippingRequest) {
					if (this.actionSecurity(16)) {
						return false
					}
				}
			}
			return this.disabledAll
		},
		linkGenesys() {
			if (!this.needGenesys) {
				return false
			}
			if (!this.withLinkGenesys || this.withLinkGenesys == undefined) {
				return false

			}
			return true
		},
		listComunasFilter() {
			return this.listComunas
		},
		listGenesysCabmovFilter() {
			this.objectShippingRequest.genesys_cabmov.forEach(gc => {
				gc.printLD = (gc.tipocompcod == 33 || gc.tipocompcod == 39 || gc.tipocompcod == 52 || gc.tipocompcod == 61)
			})
			return this.objectShippingRequest.genesys_cabmov
		},
		listTypeLegalDocumentsFilter() {
			return this.listTypeLegalDocuments.filter(td => { return (td.weGenerate == 1) })
		},
		listTipocompFilter() {
			return this.listTipocomp.filter(tc => {
				return tc.view
			})
		},
		listTrackingsShippingRequestFilter() {
			return this.listTrackingsShippingRequest
		},
		listTransportsFilter() {
			return this.listTransports
		},
		listStatusFilter() {
			return this.listStatus
		},
		transportSelected() {
			let transport = null
			if (this.objectShippingRequest.recipient_data.transport_id > 0) {
				let i = this.listTransportsFilter.map(t => parseInt(t.id)).indexOf(parseInt(this.objectShippingRequest.recipient_data.transport_id))
				if (i > -1) {
					transport = this.listTransportsFilter[i]
				}
			}
			return transport
		},
		validateFormShippingRequest() {
			let result = true
			this.messageError = []
			if (this.linkGenesys) {
				if (this.tipocompcodSearch > 0) {
					if (this.nrocompSearch == '' || this.nrocompSearch < 1) {
						this.messageError.push('Falta ingresar en Numero de comprobante')
						result = false
					} else {
						if (this.objectCabmov.nrocomp == 0) {
							this.messageError.push('No hay informacion de genesys del comprobante Nº ' + this.nrocompSearch)
							result = false
						} else {
							if (this.objectCabmov.nrocomp != this.nrocompSearch || this.objectCabmov.tipocompcod != this.tipocompcodSearch) {
								this.messageError.push('No coincide el Tipo de comprobante o el Numero del comprobante con lo que se rescató de Genesys')
								result = false
							}
						}
					}
				}
			}
			if (result && (this.tipocompcodSearch != 2 || !this.linkGenesys)) {
				if (this.objectShippingRequest.recipient_data.transport_id == 0) {
					this.messageError.push('Debes seleccionar el transporte')
					result = false
				} else {
					if (this.objectShippingRequest.recipient_data.sendto.trim() == '') {
						this.messageError.push('Debes ingresar a quien hay que enviar')
						result = false
					}
					if (this.objectShippingRequest.recipient_data.number_document.trim() != '' && this.formatRUT(this.objectShippingRequest.recipient_data.number_document.trim()) == 'NOT VALID') {
						this.messageError.push('El RUT ingresado no es valido')
						result = false
					}
					if (this.objectShippingRequest.recipient_data.email.trim() != '' && !this.validateEmail(this.objectShippingRequest.recipient_data.email.trim())) {
						this.messageError.push('La dirección de Email no es valida')
						result = false
					}
					if (this.objectShippingRequest.recipient_data.address.trim() == '') {
						this.messageError.push('Debes ingresar una dirección')
						result = false
					}
					if (this.objectShippingRequest.recipient_data.comuna_id == 0 || this.objectShippingRequest.recipient_data.comuna_id == null) {
						this.messageError.push('Debes seleccionar una comuna')
						result = false
					}
					if (this.transportSelected.need_number_document == 1 && this.objectShippingRequest.recipient_data.number_document.trim() == '') {
						this.messageError.push('El transporte necesita el RUT')
						result = false
					}
					if (this.transportSelected.need_email == 1 && this.objectShippingRequest.recipient_data.email.trim() == '') {
						this.messageError.push('El transporte necesita un Email')
						result = false
					}
					if (this.typeLegalDocumentId > 0 && this.legalDocumentNumber == '') {
						this.messageError.push('Se necesita un numero de documento Legal')
						result = false
					} else if (this.typeLegalDocumentId > 0) {
						if (isNaN(this.legalDocumentNumber)) {
							this.messageError.push('Se necesita que el documento legal sea número')
							result = false
						} else if (this.legalDocumentNumber == 0) {
							this.messageError.push('Se necesita que el documento legal sea un número mayor a 0')
							result = false
						}
					}
				}
			}
			return result
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'addShippingRequestTrackingsShippingRequestResult': this.addShippingRequestTrackingsShippingRequestResult(response); break;
				case 'deleteShippingRequestResult': this.deleteShippingRequestResult(response); break;
				case 'getAllComunasResult': this.getAllComunasResult(response); break;
				case 'getAllStatusResult': this.getAllStatusResult(response); break;
				case 'getAllTypeLegalDocumentsResult': this.getAllTypeLegalDocumentsResult(response); break;
				case 'getAllTrackingsShippingRequestResult': this.getAllTrackingsShippingRequestResult(response); break;
				case 'getAllTransportsResult': this.getAllTransportsResult(response); break;
				case 'getDomicilioWithClienteResult': this.getDomicilioWithClienteResult(response); break;
				case 'getMovimientoResult': this.getMovimientoResult(response); break;
				case 'normalizacionCorreosChileResult': this.normalizacionCorreosChileResult(response); break;
				case 'saveShippingRequestResult': this.saveShippingRequestResult(response); break;
				case 'showShippingRequestResult': this.showShippingRequestResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.setShippingRequestComponentInit()
		},
		addShippingRequestTrackingsShippingRequestResultLocal(response) {
			if (response.data.success == true) {
				this.$emit('updateStatusShippingRequestResultComponent', response.data.data)
				this.showMsgErrorHandling('Actualizado', 'Se actualizó el estado de la SE', 'info', 5000, 'top right')
			}
		},
		analyzeResultConsultarBoletaLocal(cabmov) {
			if (cabmov.tipocompcodLegal > 0 && cabmov.nrocompLegal != '') {
				this.typeLegalDocumentId = cabmov.tipocompcodLegal
				this.legalDocumentNumber = cabmov.nrocompLegal
			}
		},
		analyzeResultConsultarCobroLocal(brutocomp) {
			if (this.fromShow == 'internal') {
				this.objectShippingRequest.recipient_data.paid_shipping = 1
				this.objectShippingRequest.total_price = parseInt(brutocomp)
			}
		},
		analyzeResultGetMovimientoLocal(response) {
		},
		analyzeResultConsultarClienteEnvioLocal(climov) {
			if (climov.length == 1) {
				let index = this.clicodsNotConsider.map(c => c).indexOf(parseInt(climov[0].clicod))
				if (index == -1) {
					this.objectShippingRequest.recipient_data.sendto = climov[0].cargadoa
					this.getDomicilioWithCliente(climov[0].domicod)
				} else {
					setTimeout(() => { this.$refs.sendToInput.focus() }, 50)
				}
			}
		},
		changeNrocompInputLocal() {
			if (this.fromShow == 'internal') {
				this.clearObjectShippingRequest()
				this.clearObjectCabmov()
			}
		},
		changeComunaSelectLocal() {
			this.validateAddress()
			this.objectShippingRequest.recipient_data.comuna = this.comunaSelected
		},
		changeTipocompSelectLocal() {
			if (this.fromShow == 'internal') {
				this.clearObjectShippingRequest()
				this.clearObjectCabmovLocal()
			}
		},
		changeTransportSelectLocal() {
			this.objectShippingRequest.recipient_data.transport = this.transportSelected
			if (this.fromShow == 'internal') {
				if (this.transportSelected.address != '' && this.transportSelected.address != null) {
					this.objectShippingRequest.recipient_data.address = this.transportSelected.address
				} else {
					this.objectShippingRequest.recipient_data.address = ''
				}
				if (this.transportSelected.comuna_id != null) {
					this.objectShippingRequest.recipient_data.comuna_id = this.transportSelected.comuna_id
					this.objectShippingRequest.recipient_data.comuna = this.comunaSelected
				} else {
					this.objectShippingRequest.recipient_data.comuna_id = 0
				}
			}
		},
		changeTypeLegalDocumentSelect() {
			if (this.typeLegalDocumentId == 0) {
				this.legalDocumentNumber = ''
			}
		},
		changeStatusSelectLocal() {
			this.addShippingRequestTrackingsShippingRequest(this.objectShippingRequest.id, this.trackingsShippingIDSelected)
		},
		clearObjectCabmovLocal() {
			this.nrocompSearch = ''
			this.clearObjectCabmov()
			if (this.tipocompcodSearch > 0) {
				setTimeout(() => { this.$refs.nrocompInput.focus() }, 50)
			}
		},
		clearShippingRequestLocal() {
			this.trackingsShippingIDSelected = 0
			this.tipocompcodSearch = 0
			this.typeLegalDocumentId = 0
			this.legalDocumentNumber = ''
			this.idLocal = ''
			this.validatedAddress = false
			this.validatingAddress = false
			this.clearObjectShippingRequest()
			this.clearObjectCabmovLocal()
			setTimeout(() => { this.$refs.sendToInput.focus() }, 400)
		},
		deleteShippingRequestLocal() {
			if (this.objectShippingRequest.id > 0) {
				this.$confirm(
					{
						message: 'Está seguro que quiere eliminar la SE Nº ' + this.objectShippingRequest.id,
						button: {
							no: 'No, de ninguna manera',
							yes: 'Si, dale'
						},
						callback: confirm => {
							if (confirm) {
								this.deleteShippingRequest(this.objectShippingRequest.id)
							}
						}
					}
				)
			} else {
				this.showMsgErrorHandling('IMPOSIBLE', 'No puede Eliminar algo que no existe', 'warn', 5000, 'top right')
			}
		},
		deleteShippingRequestResultLocal(response) {
			if (response.data.success == true) {
				if (response.data.data == this.objectShippingRequest.id) {
					this.showMsgErrorHandling('Elmininado', 'Se eliminó con exito la SE Nº ' + response.data.data, 'info', 5000, 'top right')
					this.$emit('deleteShippingRequestResultComponent', response)
				}
			}
		},
		determineStatusTracking(statusId) {
			let i = this.listTrackingsShippingRequest.map(ts => parseInt(ts.status_id)).indexOf(parseInt(statusId))
			return (i > -1) ? this.listTrackingsShippingRequest[i].id : -1
		},
		getAllComunasResultLocal(response) {
		},
		getAllTypeLegalDocumentsResultLocal(response) {
		},
		getAllTransportsResultLocal(response) {
		},
		getAllStatusResultLocal(response) { },
		getAllTrackingsShippingRequestResultLocal(response) { },
		getDomicilioWithClienteResultLocal(response) {
			if (response.data.data.length > 0) {
				this.objectShippingRequest.recipient_data.number_document = (response.data.data[0].clicuit == null) ? '' : response.data.data[0].clicuit
				this.objectShippingRequest.recipient_data.phone = (response.data.data[0].domitel == null) ? '' : response.data.data[0].domitel
				this.objectShippingRequest.recipient_data.email = (response.data.data[0].domimail == null) ? '' : response.data.data[0].domimail
				this.objectShippingRequest.recipient_data.address = (response.data.data[0].domicalle == null) ? '' : response.data.data[0].domicalle + ' ' + response.data.data[0].dominro
				let comuna = this.selectComunaByStr(response.data.data[0].dptodesc)
				this.objectShippingRequest.recipient_data.comuna_id = comuna.id
				this.objectShippingRequest.recipient_data.comuna = comuna
				let transport = this.selectTransporByStr(response.data.data[0].trandesc)
				this.objectShippingRequest.recipient_data.transport_id = transport.id
				this.objectShippingRequest.recipient_data.transport = transport
			}
		},
		getMovimientoLocal() {
			if (!isNaN(this.nrocompSearch) && this.nrocompSearch > 0) {
				if (this.fromShow == 'internal') {
					this.clearObjectCabmov()
					this.clearObjectShippingRequest()
				}
				this.nrocompSearch = this.nrocompSearch.trim()
				this.getMovimiento(this.tipocompcodSearch, this.nrocompSearch.trim())
			} else {
				this.showMsgErrorHandling('Sin Dato', 'Tienes que ingresar el numero de comprobante a buscar', 'warn', 5000, 'top right')
			}
		},
		getMovimientoResultLocal(response) {
			if (response.data.data.cabmov.nrocomp === this.nrocompSearch) {
				this.analyzeResultGetMovimiento(response, this.data != undefined)
			} else {
				this.showMsgErrorHandling('No Encontrado', 'El comprobante ' + this.nrocompSearch + ' no se ha encontrado en genesys', 'warn', 5000, 'top right')
			}
		},
		normalizacionCorreosChileLocal() {
			let i = this.listComunas.map(c => parseInt(c.id)).indexOf(this.objectShippingRequest.recipient_data.comuna_id)
			if (i > -1) {
				this.validatingAddress = true
				this.validatedAddress = false
				console.log('Validando Dirección', this.objectShippingRequest.recipient_data.address.trim(), this.listComunas[i].name)
				this.normalizacionCorreosChile(this.objectShippingRequest.recipient_data.address.trim(), this.listComunas[i].name)
			}
		},
		normalizacionCorreosChileResultLocal(response) {
			this.validatingAddress = false
			if (response.data.message == "Successful Execution") {
				if (parseInt(response.data.data.codPostal) == 0) {
					this.showMsgErrorHandling('ERROR COMUNA', 'No se pudo indentificar bien la comuna' + this.listMessageError(), 'warn', 5000, 'top right')
				} else {
					if (response.data.data.codPostal.substring(3) == '0000') {
						this.showMsgErrorHandling('ERROR DIRECCION', 'No se pudo indentificar la dirección exacta' + this.listMessageError(), 'warn', 5000, 'top right')
					} else {
						this.objectShippingRequest.recipient_data.address = response.data.data.calle
						this.objectShippingRequest.recipient_data.address += ' ' + response.data.data.numero
						this.objectShippingRequest.recipient_data.address += ' ' + response.data.data.resto.trim()
						this.objectShippingRequest.recipient_data.address = this.objectShippingRequest.recipient_data.address.trim()
						this.validatedAddress = true
					}
				}
			}
			console.log(response.data.data)
		},
		saveShippingRequestLocal() {
			if (!this.savedShippingRequest) {
				if (this.validateFormShippingRequest) {
					this.objectShippingRequest.genesys_cabmov = []
					if (this.tipocompcodSearch > 0 &&
						this.objectCabmov.fecmov != '' &&
						this.objectCabmov.succod != '' &&
						!isNaN(this.objectCabmov.nrocomp) &&
						this.objectCabmov.nrocomp > 0 &&
						this.objectCabmov.nrocomp == this.nrocompSearch) {
						this.objectShippingRequest.genesys_cabmov.push({
							id: 0,
							shipping_request_id: this.objectShippingRequest.id,
							tipocompcod: this.objectCabmov.tipocompcod,
							fecmov: this.objectCabmov.fecmov,
							succod: this.objectCabmov.succod,
							codbareti: this.objectCabmov.codbareti,
							nrocomp: this.objectCabmov.nrocomp
						})
					}
					if (this.typeLegalDocumentId > 0 && !isNaN(this.legalDocumentNumber) && this.legalDocumentNumber > 0) {
						let fecmov = '1900-01-01'
						let succod = 0
						let codbareti = 'S/BARCODE'
						if (this.objectCabmov.nrocompLegal == this.legalDocumentNumber) {
							fecmov = this.objectCabmov.fecmovLegal
							succod = this.objectCabmov.succodLegal
							codbareti = this.objectCabmov.codbaretiLegal
						}
						this.objectShippingRequest.genesys_cabmov.push({
							id: 0,
							shipping_request_id: this.objectShippingRequest.id,
							tipocompcod: this.typeLegalDocumentId,
							fecmov: fecmov,
							succod: succod,
							codbareti: codbareti,
							nrocomp: this.legalDocumentNumber
						})
					}
					this.$emit('savedShippingRequest', true)
					this.saveShippingRequest(this.objectShippingRequest)
				} else {
					this.showMsgErrorHandling('Falta Información', 'Faltan datos por llenar' + this.listMessageError(), 'warn', 5000, 'top right')
				}
			}
		},
		saveShippingRequestResultLocal(response) {
			this.$emit('savedShippingRequest', false)
			if (response.data.success = true) {
				if (response.data.message[0].message == 'INSERT' || response.data.message[1].message == 'UPDATED') {
					this.$emit('saveShippingRequestResultComponent', response)
					this.clearObjectShippingRequest()
				}
			}
		},
		setShippingRequestComponentInit() {
			this.typeStatusId = 3
			this.getAllTransports()
			this.getAllComunas()
			this.getAllTypeLegalDocuments()
			this.getAllStatus()
			this.getAllTrackingsShippingRequest()
		},
		showMsgErrorHandlingLocal(msg) {
			this.gettingShippingRequest = false
			this.savedShippingRequest = false
			console.log(msg)
		},
		showShippingRequestLocal() {
			this.idLocal = this.id
			if (this.idLocal > 0) {
				this.showShippingRequest(this.idLocal)
			}
		},
		showShippingRequestResultLocal(response) {
			if (response.data.success == true) {
				this.objectShippingRequest.total_price = parseInt(this.objectShippingRequest.total_price)
				this.trackingsShippingIDSelected = this.determineStatusTracking(this.objectShippingRequest.status_id)
				if (this.objectShippingRequest.recipient_data != null) {
					this.objectShippingRequest.recipient_data.paid_shipping = (this.objectShippingRequest.recipient_data.paid_shipping == 1)
				}
				this.$emit('shippingRequestFoundResult', response.data.data)
			}
		},
		toCompleteShippingRequestLocal() {
			this.fromShow = 'external'
			let data = this.data
			if (!(data.recipient_data.comuna_id > 0) && data.recipient_data.comuna != null) {
				let comuna = this.selectComunaByStr(data.recipient_data.comuna)
				data.recipient_data.comuna_id = comuna.id
				data.recipient_data.comuna = comuna
			}
			if (data.recipient_data.transport != null) {
				let transport = this.selectTransporByStr(data.recipient_data.transport)
				data.recipient_data.transport_id = transport.id
				data.recipient_data.transport = transport
			}
			this.setObjectShippingRequest(data)
			if (transport.normalizer == 1 && comuna.id > 0) {
				console.log('normalizar')
				this.normalizacionCorreosChileLocal()
			}
		},
		toPrintLegalDocumentGenesysLocal(gc) {
			this.toPrintFacturacionCL(gc.tipocompcod, gc.nrocomp)
		},
		toPrintLegalDocumentLocal(ld) {
			this.toPrintFacturacionCL(ld.type_legal_document_id, ld.legal_document_number)
		},
		validateAddress() {
			this.objectShippingRequest.recipient_data.office = this.objectShippingRequest.recipient_data.address.toLowerCase().includes('agencia')
			this.objectShippingRequest.recipient_data.office = (this.objectShippingRequest.recipient_data.office == 0) ? this.objectShippingRequest.recipient_data.address.toLowerCase().includes('sucursal') : this.objectShippingRequest.recipient_data.office
			if (this.objectShippingRequest.recipient_data.comuna_id > 0) {
				let i = this.listTransports.map(t => parseInt(t.id)).indexOf(this.objectShippingRequest.recipient_data.transport_id)
				if (i > -1) {
					if (this.listTransports[i].normalizer == 1) {
						if (this.objectShippingRequest.recipient_data.address.trim() != '') {
							if (!this.validatedAddress) {
								this.normalizacionCorreosChileLocal()
							}
						}
					}
				}
			}
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>