<template>
	<div id="JumpsellerPromotionListComponent" class="jumpsellerPromotionListComponent">
		<button @click="getJumpsellerPromotionsLocal(1)" ref="btnGetJumpSellerPromotions" class="btn btn-primary btn-sm"
			v-show="false" :disabled="loadingJumsellerPromotions">
			<span v-show="!loadingJumsellerPromotions">Buscar Pedidos WEB</span>
			<span v-show="loadingJumsellerPromotions">Buscando . . .</span>
		</button>
		<table class="table table-sm table-12 table-hover fs--1">
			<thead>
				<tr>
					<th class="text-center">ID</th>
					<th>Nombre</th>
					<th>cupón(es)</th>
					<th class="text-center">Veces Usado</th>
					<th class="text-center">Acumulativa</th>
					<th class="text-center">Descuento</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr v-show="loadingJumsellerPromotions">
					<td colspan="8" class="text-center">
						Buscando Promociones en Jumpseller . . . <img src="/assets/img/search-color.gif" width="30px" />
					</td>
				</tr>
				<tr v-for="(jp, i) in listJumsellerPromotionsFilter" :key="i" class="hover-actions-trigger">
					<td class="align-middle white-space-nowrap border-bottom border-muted text-center">
						<span @dblclick="console(jp.promotion)">{{ jp.promotion.id }}</span>
					</td>
					<td class="align-middle white-space-nowrap border-bottom border-muted">{{
						jp.promotion.name }}</td>
					<td class="align-middle white-space-nowrap border-bottom border-muted">{{
						jp.promotion.code }}</td>
					<td class="align-middle white-space-nowrap border-bottom border-muted text-center">{{
						jp.promotion.times_used }}</td>
					<td class="align-middle white-space-nowrap border-bottom border-muted text-center">
						<span v-show="jp.promotion.cumulative == true">
							<i class="fas fa-check text-success"></i>
						</span>
					</td>
					<td class="align-middle white-space-nowrap border-bottom border-muted text-center">{{
						jp.promotion.discount_amount_percent }}%</td>
					<td class="align-middle white-space-nowrap border-bottom border-muted text-center">
						<button class="btn btn-sm" @click="selectedJumpsellerPromotionLocal(jp.promotion)"
							:disabled="!(jp.promotion.status == 'active')">
							<i class="fas fa-cart-arrow-down text-info"></i>
						</button>
					</td>
				</tr>
			</tbody>
		</table>
		<div>
			<button class="btn btn-sm fs--1" :disabled="page == 1 || loadingJumsellerPromotions"
				@click="getJumpsellerPromotionsLocal(page - 1)">
				<< Anterior </button>
					<button class="btn btn-sm float-end fs--1" @click="getJumpsellerPromotionsLocal(page + 1)"
						:disabled="loadingJumsellerPromotions || listJumsellerPromotionsFilter.length == 0">
						Siguiente >>
					</button>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import errors from '@/mixins/errors.js'
import axiosAction from '@/mixins/axiosAction.js'
import jumpsellerPromotion from '@/mixins/jumpseller/promotion.js'

export default {
	name: 'JumpsellerPromotionListComponent',
	mixins: [axiosAction, errors, mixin, jumpsellerPromotion],
	data() {
		return {
			page: 1
		}
	},
	computed: {
		listJumsellerPromotionsFilter() {
			return this.listJumsellerPromotions.filter(jp => {
				return jp.promotion.status == 'active'
			})
		}
	},
	components: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getJumpsellerPromotionsResult': this.getJumpsellerPromotionsResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
		},
		getJumpsellerPromotionsLocal(page = 1) {
			this.page = page
			if (this.loadingJumsellerPromotions == false) {
				this.getJumpsellerPromotions(page)
			}
		},
		getJumpsellerPromotionsResultLocal(response) { },
		selectedJumpsellerPromotionLocal(jp) {
			this.$emit('selectedJumpsellerPromotion', jp)
		}
	},
	mounted() {
		this.initModule()
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
