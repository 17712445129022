<template>
	<div id="budgetListComponent">
		<div v-show="loadingBudgets == true">
			<mensagge-loading-component class="bg-aasinet text-white" text="Buscando Titulo">
			</mensagge-loading-component>
		</div>
		<div class="card mb-1" id="shippingsCard">
			<div class="card-header">
				<div class="row flex-between-center">
					<div class="col-sm-auto d-flex align-items-center pe-0">
						<div class="row gy-2 gx-3 align-items-center">
							<div class="col-auto">
								<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Presupuestos</h5>
							</div>
							<div class="col-auto">
								<div class="input-group">
									<input class="form-control form-control-sm shadow-none search" type="search"
										placeholder="" aria-label="search" ref="searchBudgets"
										@focus="$event.target.select()" v-model="searchBudgets"
										@keyup.enter="getBudgetsLocal(1)">
									<div class="input-group-text bg-transparent pointer" @click="getBudgetsLocal(1)">
										<span class="fa fa-search fs--1 text-600"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-8 col-sm-auto ms-auto text-end ps-0">
						<div id="orders-actions">
							<button class="btn btn-falcon-default btn-sm me-2" type="button" @click="showBudgetLocal(0)"
								ref="btnShowBudget" data-bs-toggle="modal" data-bs-target="#budgetWizardModal">
								<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Nuevo</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body p-0">
				<div class="table-responsive scrollbar">
					<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden table-hover">
						<thead class="bg-200">
							<tr>
								<th class="text-900 text-center">ID</th>
								<th class="text-900">Fecha</th>
								<th class="text-900">Nombre</th>
								<th class="text-900 text-center">Tipo</th>
								<th class="text-900">Cliente</th>
								<th class="text-900">Contacto</th>
								<th class="text-900 text-center">Estado</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(b, i) in listBudgetsFilter" :key="i">
								<td class="text-center">{{ b.id }}</td>
								<td>{{ b.created_at | formatDateMin }}</td>
								<td>{{ b.name }}</td>
								<td class="text-center">{{ b.print_type.name }}</td>
								<td>{{ b.client.name }} {{ b.client.surname }}</td>
								<td>{{ b.contact }}</td>
								<td class="text-center">
									<span v-if="b.status != null" class="badge rounded-pill d-block"
										:class="'badge-soft-' + b.status.color">{{ b.status.name }}</span>
									<span v-else>Sin Estado</span>
								</td>
								<td class="text-end pointer">
									<span :title="'Copiar presupuesto Nº ' + b.id" class="ms-2"
										@click="showBudgetLocal(b.id, 'Copy')" data-bs-toggle="modal"
										data-bs-target="#budgetWizardModal">
										<i class="far fa-copy"></i>
									</span>
									<router-link :title="'Editar Presupuesto Nº ' + b.id" class="ms-2 text-info"
										:to="'/budget/' + b.id" target="_blank">
										<i class="far fa-edit"></i>
									</router-link>
									<span class="ms-2 text-danger" :title="'Borrar Presupuesto Nº ' + b.id"
										v-show="showBtnDeleteBudget(b)" @click="deleteBudgetLocal(b)">
										<i class="far fa-trash-alt text-dander"></i>
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="card-footer">
				<div class="d-flex align-items-center justify-content-center">
					<pagination :pagination="paginationBudgets" @getAllList="getBudgetsLocal">
					</pagination>
				</div>
			</div>
		</div>

		<div class="modal fade" id="budgetWizardModal" tabindex="-1" aria-labelledby="budgetWizardModalLabel"
			aria-hidden="true" data-bs-backdrop="static">
			<div class="modal-dialog modal-xl">
				<div class="modal-content p-3">
					<button type="button" class="btn-close" data-bs-dismiss="modal" ref="btnCloseBudgetWizardModal"
						aria-label="Close" v-show="false"></button>
					<budget-wizard-component ref="budgetWizardComponent" :id="budgetId" :showType="typeShowBudget"
						@saveBudgetResult="saveBudgetResultLocal"
						@cancel="closeBudgetWizardModal"></budget-wizard-component>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import budget from '@/mixins/budgets'

import Pagination from '@/components/Pagination.vue'
import MessageLoadingComponent from '@/components/MessageLoadingComponent.vue'

import BudgetWizardComponent from '@/components/BudgetWizardComponent.vue'

export default {
	name: 'BudgetListComponent',
	mixins: [mixin, axiosAction, errors, budget],
	props: [],
	data() {
		return {
			budgetId: 0,
			typeShowBudget: 'New'
		}
	},
	components: {
		'pagination': Pagination,
		'mensagge-loading-component': MessageLoadingComponent,
		'budget-wizard-component': BudgetWizardComponent
	},
	computed: {
		listBudgetsFilter() {
			return this.listBudgets
		},
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getBudgetsResult': this.getBudgetsResult(response); break;
				case 'deleteBudgetResult': this.deleteBudgetResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		closeBudgetWizardModal() {
			this.$refs.btnCloseBudgetWizardModal.click()
		},
		deleteBudgetLocal(b) {
			if (b.id > 0) {
				this.$confirm(
					{
						message: 'Está seguro que quiere eliminar el Presupuesto Nº ' + b.id,
						button: {
							no: 'No, de ninguna manera',
							yes: 'Si, dale'
						},
						callback: confirm => {
							if (confirm) {
								this.deleteBudget(b.id)
							}
						}
					}
				)
			} else {
				this.showMsgErrorHandling('IMPOSIBLE', 'No puede Eliminar algo que no existe', 'warn', 5000, 'top right')
			}
		},
		deleteBudgetResultLocal(response) { },
		initModuleLocal() {
			document.title = 'Lista de Presupuestos'
			this.setBudgetListComponentInit()
		},
		getBudgetsLocal(page = 1) {
			this.getBudgets(page)
		},
		getBudgetsResultLocal(response) {

		},
		saveBudgetResultLocal(b) {
			this.addItemListbudgets(b)
			this.closeBudgetWizardModal()
		},
		showBtnDeleteBudget(b) {
			if (!this.actionSecurity(21)) {
				return false
			}
			return (b.status_id == null || b.status_id == 73)
		},
		showBudgetLocal(id, type = 'New') {
			this.budgetId = id
			this.typeShowBudget = type
			setTimeout(() => { this.$refs.budgetWizardComponent.$refs.btnShowBudget.click() }, 50)
		},
		setBudgetListComponentInit() {
			this.getBudgetsLocal(1)
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>