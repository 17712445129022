var order = {
	data() {
		return {
			dateFromOrders: this.currentDate(-30),
			dateToOrders: this.currentDate(),
			listOrders: [],
			loadingOrders: false,
			searchingOrder: false,
			objectOrder: {
				exists: 0,
				id: 0,
				client_id: 0,
				client: null,
				client_address_client_sendto_id: 0,
				client_address_client_sendto: null,
				client_sendto_id: null,
				client_sendto: null,
				total: 0,
				tithe: 0,
				status_id: 0,
				status: null,
				type_order_id: 0,
				type_order: null,
				church_id: 0,
				church: null,
				subscription_id: 0,
				subscription: null,
				paid: 0,
				team_id: 0,
				team: null,
				freight: 0,
				subtotal: 0,
				discount: 0,
				token: null,
				created_at: '',
				updated_at: '',
				order_genesys: [],
				order_group: [],
				order_jumpseller: [],
				order_legal_document: [],
				order_order: [],
				order_payment: [],
				order_product_groups: [],
				order_product_subscription_products: [],
				order_products: [],
				order_shipping_request: [],
				order_trackings_order: [],
				order_transbank_result_output: [],
				order_upload_document: [],
				others: {},
				// Esto es Antiguo
				sendto: [{
					exists: 0,
					id: 0,
					client_id: 0,
					address_id: 0,
					street: '',
					comuna_id: 0,
					client_sendto_id: 0,
					sendto: '',
					phone: '',
					created_at: '',
					updated_at: '',
					number_document: '',
					email: '',
					comuna: '',
				}],
				observations: [{
					id: 0,
					order_id: 0,
					observation: '',
					created: '',
					user_id: 0,
					user: '',
					img: ''
				}],
				order_observations: [{
					id: 0,
					order_id: 0,
					observation: '',
					created: '',
					user_id: 0,
					user: '',
					img: ''
				}],
				legal_document: [],
				products: [],
				tracking_order: [],
				order_tracking_order: [],
				order_payments: []
			},
			observation: '',
			paginationOrders: [],
			qRowsOrder: 25,
			searchOrders: '',
			typeOrderIdsShow: [2],
			urlsOrder: {
				get: 'orders',
				show: 'order/',
				delete: '/delete',
				create: 'register'
			},
			savingOrder: false,
			tax: 0.19,
			removingOrder: false
		}
	},
	computed: {
		titheOrderProducts() {
			let total = this.objectOrder.order_products.reduce((a, op) => {
				let b = 0
				if (op.tithable == true) {
					if (op.quantity > 0) {
						let price = op.price - op.with_freight
						b = ((op.quantity * price) / (1 + parseFloat(op.tax))) * 0.1
					}
				}
				return a + b
			}, 0);
			this.objectOrder.tithe = Math.round(total)
			return this.objectOrder.tithe.toFixed(0)
		},
		subTotalOrder() {
			let total = this.objectOrder.order_products.reduce((a, b) => {
				let c = Math.trunc(Math.abs(b.quantity)) * Math.abs(b.price)
				return a + c
			}, 0);
			return total.toFixed(0)
		},
		totalOrder() {
			this.objectOrder.total = parseInt(this.subTotalOrder) + parseInt(this.objectOrder.freight) + parseInt(this.titheOrderProducts)
			return this.objectOrder.total
		},
		totalOrderPayment() {
			let total = this.objectOrder.order_payment.reduce((a, b) => {
				let c = Math.trunc(Math.abs(b.amount))
				return a + c
			}, 0);
			return total.toFixed(0)
		}
	},
	methods: {
		addItemListOrderPayment(op) {
			this.objectOrder.order_payment.push(op)
			return this.objectOrder.order_payment.length - 1
		},
		addListOrders(newOrder) {
			this.listOrders.unshift(newOrder)
			if (this.listOrders.length > this.qRowsOrder) {
				this.listOrders.splice(this.qRowsOrder, 1)
			}
		},
		addListOrderObservation(newOrderObservation) {
			let index = this.listOrders.map((el) => parseInt(el.id)).indexOf(parseInt(newOrderObservation.order_id))
			if (index > -1) {
				this.listOrders[index].observations.push(newOrderObservation)
			}
			if (this.objectOrder.id == newOrderObservation.order_id) {
				this.objectOrder.observations.push(newOrderObservation)
			}
		},
		addListOrderTrackingOrder(newOrderTrakingOrder) {
			let index = this.listOrders.map((el) => parseInt(el.id)).indexOf(parseInt(newOrderTrakingOrder.order_id))
			if (index > -1) {
				this.listOrders[index].trackings_order.push(newOrderTrakingOrder)
				if (newOrderTrakingOrder.tracking_order.status_id != null) {
					this.listOrders[index].status_id = newOrderTrakingOrder.tracking_order.status_id
					this.listOrders[index].status = newOrderTrakingOrder.tracking_order.status
				}
			}
			if (this.objectOrder.id == newOrderTrakingOrder.order_id) {
				this.objectOrder.trackings_order.push(newOrderTrakingOrder)
				if (newOrderTrakingOrder.tracking_order.status_id != null) {
					this.objectOrder.status_id = newOrderTrakingOrder.tracking_order.status_id
					this.objectOrder.status = newOrderTrakingOrder.tracking_order.status
				}
			}
		},
		addOrderTrackingsOrder(id, trackingsOrderId, observation = '') {
			let param = {
				'trackingsOrderId': trackingsOrderId,
				'observation': observation
			}
			let url = this.getApiBackendNew + 'order/' + id + '/trackingsOrder/register'
			this.axiosActionIS(url, 'POST', 'addOrderTrackingsOrderResult', param)
		},
		addOrderTrackingsOrderResult(response) {
			if (response.data.success == true) {
				console.log(response.data.data)
				this.addListOrderTrackingOrder(response.data.data)
			}
			this.addOrderTrackingsOrderResultLocal(response)
		},
		addOrderTrackingsOrderResultLocal(response) {
			console.log('addOrderTrackingsOrderResultLocal no desarrollado', response)
		},
		deleteItemListOrder(id) {
			let index = this.listOrders.map((el) => parseInt(el.id)).indexOf(parseInt(id))
			let orderDeleted = this.listOrders.splice(index, 1)
		},
		deleteItemListOrderObservation(orderObservarion) {
			console.log('deleteItemListOrderObservation', orderObservarion)
			let index = this.listOrders.map((el) => parseInt(el.id)).indexOf(parseInt(orderObservarion.order_id))
			if (index > -1) {
				let ind = this.listOrders[index].observations.map((el) => parseInt(el.id)).indexOf(parseInt(orderObservarion.id))
				if (ind > -1) {
					let orderObservacionDeleted = this.listOrders[index].observations.splice(ind, 1)
				}
			}
			if (this.objectOrder.id == orderObservarion.order_id) {
				let ind = this.objectOrder.observations.map((el) => parseInt(el.id)).indexOf(parseInt(orderObservarion.id))
				if (ind > -1) {
					let orderObservacionDeleted = this.objectOrder.observations.splice(ind, 1)
				}
			}
		},
		deleteOrder(id) {
			this.removingOrder = true
			let url = this.getApiBackendNew + this.urlsOrder.show + id
			this.axiosActionIS(url, 'DELETE', 'deleteOrderResult')
		},
		deleteOrderResult(response) {
			this.removingOrder = false
			if (response.data.success) {
				this.deleteItemListOrder(response.data.data)
			}
			this.deleteOrderResultLocal(response)
		},
		deleteOrderResultLocal(response) {
			console.log('deleteOrderResultLocal no desarrollado', response)
		},
		deleteOrderObservation(order_id, id) {
			let url = this.getApiBackendNew + 'order/' + order_id + '/observation/' + id + '/delete'
			this.axiosActionIS(url, 'DELETE', 'deleteOrderObservationResult')
		},
		deleteOrderObservationResult(response) {
			if (response.data.success) {
				this.deleteItemListOrderObservation(response.data.data);
			}
			this.deleteOrderObservationResultLocal(response)
		},
		deleteOrderObservationResultLocal(response) {
			console.log('deleteOrderObservationResultLocal no desarrollado', response)
		},
		clearObjectOrder() {
			this.objectOrder.exists = 0
			this.objectOrder.id = 0
			this.objectOrder.client_id = 0
			this.objectOrder.client = null
			this.objectOrder.client_address_client_sendto_id = 0
			this.objectOrder.client_address_client_sendto = null
			this.objectOrder.client_sendto_id = null
			this.objectOrder.client_sendto = null
			this.objectOrder.total = 0
			this.objectOrder.tithe = 0
			this.objectOrder.status_id = 0
			this.objectOrder.status = null
			this.objectOrder.type_order_id = 0
			this.objectOrder.type_order = ''
			this.objectOrder.church_id = 0
			this.objectOrder.church = null
			this.objectOrder.subscription_id = 0
			this.objectOrder.subscription = null
			this.objectOrder.paid = 0
			this.objectOrder.team_id = 0
			this.objectOrder.team = null
			this.objectOrder.freight = 0
			this.objectOrder.subtotal = 0
			this.objectOrder.discount = 0
			this.objectOrder.token = null
			this.objectOrder.created_at = ''
			this.objectOrder.updated_at = ''
			this.objectOrder.order_genesys = []
			this.objectOrder.order_group = []
			this.objectOrder.order_jumpseller = []
			this.objectOrder.order_legal_document = []
			this.objectOrder.order_order = []
			this.objectOrder.order_payment = []
			this.objectOrder.order_product_groups = []
			this.objectOrder.order_product_subscription_products = []
			this.objectOrder.order_products = []
			this.objectOrder.order_shipping_request = []
			this.objectOrder.order_trackings_order = []
			this.objectOrder.order_transbank_result_output = []
			this.objectOrder.order_upload_document = []
			this.objectOrder.others = {}
			// Esto es Antiguo
			this.objectOrder.sendto[0].exists = 0
			this.objectOrder.sendto[0].id = 0
			this.objectOrder.sendto[0].client_id = 0
			this.objectOrder.sendto[0].address_id = 0
			this.objectOrder.sendto[0].street = ''
			this.objectOrder.sendto[0].comuna_id = 0
			this.objectOrder.sendto[0].client_sendto_id = 0
			this.objectOrder.sendto[0].sendto = ''
			this.objectOrder.sendto[0].phone = ''
			this.objectOrder.sendto[0].created_at = ''
			this.objectOrder.sendto[0].updated_at = ''
			this.objectOrder.sendto[0].number_document = ''
			this.objectOrder.sendto[0].email = ''
			this.objectOrder.sendto[0].comuna = ''
			if (this.objectOrder.order_observations.length == 1) {
				this.objectOrder.order_observations[0].id = 0
				this.objectOrder.order_observations[0].order_id = 0
				this.objectOrder.order_observations[0].observation = ''
				this.objectOrder.order_observations[0].created = ''
				this.objectOrder.order_observations[0].user_id = 0
				this.objectOrder.order_observations[0].user = ''
				this.objectOrder.order_observations[0].img = ''
				this.objectOrder.order_tracking_order = []
				this.objectOrder.order_payments = []
			}
		},
		changeQRowsOrder() {
			localStorage.setItem('qRowsOrder', this.qRowsOrder)
			this.changeQRowsOrderLocal()
		},
		changeQRowsOrderLocal() {
			console.log('changeQRowsOrderLocal no desarrollado')
		},
		getAllOrders(page = 1, resource = 'orders') {
			this.loadingOrders = true
			this.listOrders = []
			this.paginationOrders = []
			let param = '?search=' + this.searchOrders + '&page=' + page + '&qRow=' + this.qRowsOrder + '&fromDate=' + this.dateFromOrders + '&toDate=' + this.dateToOrders
			let url = this.getApiBackendNew + this.urlsOrder.get + param
			this.axiosActionIS(url, 'GET', 'getAllOrdersResult')
		},
		getAllOrdersResult(response) {
			this.loadingOrders = false
			if (response.data.success == true) {
				this.listOrders = response.data.data
				this.paginationOrders = response.data.paginate
			}
			this.getAllOrdersResultLocal(response)
		},
		getAllOrdersResultLocal(response) {
			console.log('getAllOrdersResultLocalResult no desarrollado' + this.$options.name, response)
		},
		getAllOrdersByUser(page = 1) {
			this.loadingOrders = true
			this.listOrders = []
			this.paginationOrders = []
			let parameters = '?search=' + this.searchOrders + '&page=' + page + '&qRow=' + this.qRowsOrder + '&fromDate=' + this.dateFromOrders + '&toDate=' + this.dateToOrders
			let url = this.getApiBackendNew + this.urlsOrder.get + '/getByUser' + parameters
			this.axiosActionIS(url, 'GET', 'getAllOrdersResult')
		},
		getProductPriceListFromOrder(id, priceListId) {
			let url = this.getApiBackendNew + 'order/' + id + '/products/priceList/' + priceListId
			this.axiosActionIS(url, 'GET', 'getProductPriceListFromOrderResult')
		},
		getProductPriceListFromOrderResult(response) {
			this.getProductPriceListFromOrderResultLocal(response)
		},
		getProductPriceListFromOrderResultLocal(response) {
			console.log('getProductPriceListFromOrderResultLocal no desarrollado', response)
		},
		saveOrder(o, resource = 'standard', additionalUrl = '') {
			this.savingOrder = true
			let method = (o.exists == 0) ? 'POST' : 'PUT'
			let url = this.getApiBackendNew + 'order/'
			url += (method == 'POST') ? 'register' + additionalUrl : '/' + o.id
			o.resource = resource
			console.log(url, o)
			this.axiosActionIS(url, method, 'saveOrderResult', o)
		},
		saveOrderLegalDocument(id, legalDocumentId) {
			this.savingOrder = true
			let param = {
				legalDocumentId: legalDocumentId
			}
			let url = this.getApiBackendNew + 'order/' + id + '/legalDocument/register'
			this.axiosActionIS(url, 'POST', 'saveOrderLegalDocumentResult', param)
		},
		saveOrderLegalDocumentResult(response) {
			this.savingOrder = false
			this.saveOrderLegalDocumentResultLocal(response)
		},
		saveOrderLegalDocumentResultLocal(response) {
			console.log('saveOrderLegalDocumentResultLocal no desarrollado', response)
		},
		saveOrderNew(o, resource = 'standard') {
			this.savingOrder = true

		},
		saveOrderTransfer(o) {
			let method = (o.exists == 0) ? 'POST' : 'PUT'
			let url = this.getApiBackendNew + 'order/'
			url += (method == 'POST') ? 'transfer/register' : this.objectOrder.id
			this.axiosActionIS(url, method, 'saveOrderResult', o)
		},
		saveOrderResult(response) {
			this.savingOrder = false
			if (response.data.success != undefined) {
				if (response.data.success == true) {
					if (response.data.message == 'NUEVO') {
						this.addListOrders(response.data.data)
					} else if (response.data.message == 'ACTUALIZADO') {
						this.updateItemListOrders(response.data.data)
					}
				} else {
					if (this.$snotify != undefined) {
						this.$snotify.error(response.data.data, response.data.message)
					}
				}
			}
			this.saveOrderResultLocal(response)
		},
		saveOrderResultLocal(response) {
			console.log('saveOrderResultLocal no desarrollado', response)
		},
		saveOrderObservation(id) {
			let param = {
				observation: this.observation
			}
			let url = this.getApiBackendNew + 'order/' + id + '/observation/register'
			this.axiosActionIS(url, 'POST', 'saveOrderObservationResult', param)
		},
		saveOrderObservationResult(response) {
			if (response.data.success) {
				this.observation = ''
				this.addListOrderObservation(response.data.data)
			}
		},
		saveOrderObservationResultLocal(response) {
			console.log('saveOrderObservationResult no desarrollado', response)
		},
		saveOrderShippingRequest(id, shippingRequestId) {
			this.savingOrder = true
			let param = {
				shippingRequestId: shippingRequestId
			}
			let url = this.getApiBackendNew + 'order/' + id + '/shippingRequest/register'
			this.axiosActionIS(url, 'POST', 'saveOrderShippingRequestResult', param)
		},
		saveOrderShippingRequestResult(response) {
			this.saveOrderShippingRequestResultLocal(response)
		},
		saveOrderShippingRequestResultLocal(response) {
			console.log('saveOrderShippingRequestResultLocal no desarrollado', response)
		},
		setObjectOrder(item) {
			let newItem = Object.create(item)
			for (const property in newItem) {
				this.objectOrder[property] = newItem[property]
			}
		},
		setOrderInit() {
			this.qRowsOrder = (localStorage.getItem('qRowsOrder') === null) ? this.qRowsOrder : localStorage.getItem('qRowsOrder')
			this.typeStatusId = 1
			this.dateFromOrders = this.currentDate(-30)
			this.dateToOrders = this.currentDate()
			this.setOrderInitLocal()
		},
		setOrderInitLocal() { },
		showOrder(id, resource = 'OrderDefaultResource') {
			this.searchingOrder = true
			let url = this.getApiBackendNew + this.urlsOrder.show + id + '?resource=' + resource
			this.axiosActionIS(url, 'GET', 'showOrderResult')
		},
		showOrderResult(response) {
			this.searchingOrder = false
			if (response.data.success == true) {
				if (this.showTypeOrderIds(response.data.data.type_order_id)) {
					this.setObjectOrder(response.data.data)
				}
			}
			this.showOrderResultLocal(response)
		},
		showOrderResultLocal(response) {
			console.log('showOrderResultLocal no desarrollado' + this.$options.name, response)
		},
		showTypeOrderIds(typeOrderId) {
			let i = this.typeOrderIdsShow.map(id => parseInt(id)).indexOf(parseInt(typeOrderId))
			return i > -1
		}
	},
};

export default order;