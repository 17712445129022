var finish = {
	data() {
		return {
			loadingFinishs: false,
			listFinishs: [],
			objectFinish: {
				exists: 0,
				id: 0,
				name: '',
				finish_type_id: null,
				finish_type: null,
				status_id: null,
				status: null,
				created_at: null,
				updated_at: null
			},
			searchFinish: '',
			paginationListFinishs: []
		}
	},
	methods: {
		clearObjectFinish() {
			this.objectFinish.exists = 0
			this.objectFinish.id = 0
			this.objectFinish.name = ''
			this.objectFinish.finish_type_id = null
			this.objectFinish.finish_type = null
			this.objectFinish.status_id = null
			this.objectFinish.status = null
			this.objectFinish.created_at = null
			this.objectFinish.updated_at = null
		},
		getFinishs(finishTypeIds = '', page = 1, qRow = '') {
			this.loadingFinishs = true
			this.listFinishs = []
			this.paginationListFinishs = []
			let parameters = '?search=' + this.searchFinish + '&page=' + page + '&qRow=' + qRow + '&finishTypeIds=' + finishTypeIds
			let url = this.getApiBackendNew + 'finishs' + parameters
			this.axiosActionIS(url, 'GET', 'getFinishsResult')

		},
		getFinishsResult(response) {
			this.loadingFinishs = false
			if (response.data.success == true) {
				this.listFinishs = response.data.data
				this.paginationListFinishs = response.data.paginate
			}
			this.getFinishsResultLocal(response)
		},
		getFinishsResultLocal(response) {
			console.log('getFinishsResultLocal no desarollado', response)
		}
	},
};

export default finish;