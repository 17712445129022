<template>

  <div id="testsModule">
    <!--
		<h2>Bar Chart Example</h2>
		<my-canvas style="width: 50%; height: 600px;">
			<my-box
				v-for="(obj, index) of chartValues"
				:key=index
				:x1="(index / chartValues.length) * 100"
				:x2="(index / chartValues.length) * 100 + 100 / chartValues.length"
				:y1="100"
				:y2="100 - obj.val"
				:color="obj.color"
				:value="(index / chartValues.length) * 100">
			</my-box>
		</my-canvas>
    -->
  </div>
</template>

<script>
import mixin from '@/mixins'
import MyCanvas from '@/components/tests/MyCanvas.vue';
import MyBox from '@/components/tests/MyBox.vue';


export default {
  name: 'app',
  mixins: [mixin],
  components: {
    MyCanvas,
    MyBox
  },

  data() {
    return {
      storeRackSideRowColumnIds: [],//[124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 487, 488, 489, 490, 491, 492],
      chartValues: [
        { val: 24, color: 'red' },
        { val: 32, color: '#0f0' },
        { val: 66, color: 'rebeccapurple' },
        { val: 1, color: 'green' },
        { val: 28, color: 'blue' },
        { val: 60, color: 'rgba(150, 100, 0, 0.2)' },
      ],
      text: ''
    };
  },
  methods: {
    getSelectedIds() {
      this.$refs.storeRackSideComponent.$refs.btnGetSelectedIds.click()
    },
    getSelectedIdsLocal(ids) {
      console.log(ids)
    }
  },

  mounted() {

    let dir = 1;
    let selectedVal = Math.floor(Math.random() * this.chartValues.length);

    this.formatCUIT('30-50011757-1')
    setInterval(() => {
      /*if (Math.random() > 0.995) dir *= -1;
      if (Math.random() > 0.99)
        selectedVal = Math.floor(Math.random() * this.chartValues.length);

      this.chartValues[selectedVal].val = Math.min(
        Math.max(this.chartValues[selectedVal].val + dir * 0.5, 0),
        100
      );*/
    }, 16);
  },
};
</script>

<style lang="scss"></style>
