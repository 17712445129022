<template>
	<div id="ProductPriceListSearchComponent">
		<div class="car mb-3" id="productsPriceListTable">
			<div class="card-header">
				<div class="row flex-between-center">
					<div>
						<div>
							<div class="input-group">
								<input class="form-control form-control-sm shadow-none search" type="search"
									placeholder="Buscar pedidos" aria-label="search" v-model="searchProducts"
									@keyup.enter="getProductsLocal(1)" @focus="$event.target.select()"
									ref="searchClients" />
								<div class="input-group-text bg-transparent pointer" @click="getProductsLocal(1)">
									<span class="fa fa-search fs--1 text-600"></span>
								</div>
								<button ref="btnGetProducts" @click="getProductsExternalLocal" v-show="false">Get
									Clients from
									External</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body p-0" style="height: 500px; overflow-y: scroll;">
				<div class="table-responsive" v-if="!searchingProduct">
					<table class="table table-sm table-hover fs--1">
						<thead>
							<tr>
								<th class="text-center">ID</th>
								<th>Producto</th>
								<th class="text-end">Precio</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(p, i) in listProductsFilter" :key="i">
								<td class="text-center">{{ p.id }}</td>
								<td>{{ p.name }}</td>
								<td class="text-end">{{ methodFormatNumber(p.price) }}</td>
								<td class="text-end">
									<span @click="selectProductLocal(p)" v-show="p.price != null">
										<i class="fas fa-share pointer info"></i>
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div v-else class="text-center">
					<p class="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">Estamos buscando
						en la base de datos...</p>
				</div>
			</div>
			<div class="card-footer">
				<div class="row flex-between-center">
					<div class="col-auto">
						<select class="form-select form-select-sm" v-model="qRowsProduct" @change="changeQRowsProduct">
							<option value="10">10 Resultados</option>
							<option value="15">15 Resultados</option>
							<option value="25">25 Resultados</option>
							<option value="50">50 Resultados</option>
							<option value="100">100 Resultados</option>
							<option value="200">200 Resultados</option>
							<option value="1000">1000 Resultados</option>
						</select>
					</div>
					<pagination :pagination="paginationProducts" @getAllList="getProductsLocal"></pagination>
					<div class="col-auto"><a class="fs--1 font-sans-serif" href="#!" v-show="false">Ayuda</a></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import products from '@/mixins/products'

import Pagination from '@/components/Pagination.vue'

export default {
	name: 'ProductPriceListSearch',
	mixins: [mixin, axiosAction, errors, products],
	props: ['searchParent', 'priceListId', 'quantity'],
	data() {
		return {
			toShowAll: false
		}
	},
	components: {
		'pagination': Pagination,
	},
	computed: {
		listProductsFilter() {
			let list = this.listProducts.filter(p => {
				let toShow = this.toShowAll
				p.product_price_list.forEach(ppl => {
					if (ppl.id == this.priceListId) {
						toShow = true
						if (ppl.quantity <= this.quantity) {
							p.price = ppl.price
						}
					}
				});
				return toShow
			})
			return list
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getProductsResult': this.getProductsResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		getProductsLocal(page, typeSearchProduct = '') {
			this.typeSearchProduct = typeSearchProduct
			this.getProducts(page, 'productWithPriceListsCurrent')
		},
		getProductsResultLocal(response) {
			this.listProducts = this.listProducts.filter(p => {
				let toShow = this.toShowAll
				p.product_price_list.forEach(ppl => {
					if (ppl.id == this.priceListId) {
						toShow = true
						if (parseInt(ppl.quantity) <= parseInt(this.quantity) && parseInt(ppl.status_id) == 1) {
							p.price = (p.price == null || parseInt(p.price) == 0) ? parseInt(ppl.price) : (parseInt(p.price) < parseInt(ppl.price)) ? parseInt(ppl.price) : parseInt(p.price)
						}
					}
				});
				return toShow
			})
			if (this.typeSearchProduct == 'FORM') {
				if (this.listProducts.length == 0) {

				} else if (this.listProducts.length == 1) {
					this.selectProductLocal(this.listProducts[0])
				} else {
					this.$emit('manyResults')
				}
			}
		},
		getProductsExternalLocal() {
			this.toShowAll = true
			this.searchProducts = this.searchParent
			this.getProductsLocal(1, 'FORM')
		},
		initModuleLocal() {
			this.setProductPriceListSearchInit()
		},
		selectProductLocal(p) {
			this.$emit('selectProduct', p)
		},
		setProductPriceListSearchInit() {
			this.qRowsProduct = 15
		},
		showNombreLocal() { },
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>