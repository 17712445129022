<template>
	<div id="clientListSearchComponent">
		<div class="car mb-3" id="clientsTable">
			<div class="card-header">
				<div class="row flex-between-center">
					<div>
						<div>
							<div class="input-group">
								<input class="form-control form-control-sm shadow-none search" type="search"
									placeholder="Buscar pedidos" aria-label="search" v-model="searchClients"
									@keyup.enter="getAllClientsLocal(1)" @focus="$event.target.select()"
									ref="searchClients" />
								<div class="input-group-text bg-transparent pointer" @click="getAllClientsLocal(1)">
									<span class="fa fa-search fs--1 text-600"></span>
								</div>
								<button ref="btnGetClients" @click="getAllClientsExternalLocal" v-show="false">Get
									Clients from
									External</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body p-0" style="max-height: 500px;">
				<div class="table-responsive scrollbar" v-if="!loadingClients">
					<table class="table table-sm table-hover fs--1">
						<thead>
							<tr>
								<th>ID</th>
								<th>Cliente</th>
								<th colspan="2">Nº Documento</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(c, i) in listClients" :key="i">
								<td>{{ c.id }}</td>
								<td>{{ c.name }} {{ c.surname }}</td>
								<td>{{ c.number_document }}</td>
								<td class="text-end">
									<span @click="selectClientLocal(c)">
										<i class="fas fa-share pointer info"></i>
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div v-else class="text-center">
					<p class="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">Estamos buscando
						en la base de datos...</p>
				</div>
			</div>
			<div class="card-footer">
				<div class="row flex-between-center">
					<div class="col-auto">
						<select class="form-select form-select-sm" v-model="qRowsClient" @change="changeQRowsClient">
							<option value="10">10 Resultados</option>
							<option value="15">15 Resultados</option>
							<option value="25">25 Resultados</option>
							<option value="50">50 Resultados</option>
							<option value="100">100 Resultados</option>
							<option value="200">200 Resultados</option>
							<option value="1000">1000 Resultados</option>
						</select>
					</div>
					<pagination :pagination="paginationClients" @getAllList="getAllClientsLocal"></pagination>
					<div class="col-auto"><a class="fs--1 font-sans-serif" href="#!" v-show="false">Ayuda</a></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import clients from '@/mixins/clients'

import Pagination from '@/components/Pagination.vue'

export default {
	name: 'ClientListSearchComponent',
	mixins: [mixin, axiosAction, errors, clients],
	props: ['searchParent'],
	data() {
		return {
			clientIdSelected: 0,
			typeSearchClient: '',
		}
	},
	components: {
		'pagination': Pagination,
	},
	computed: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getAllClientsResult': this.getAllClientsResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		getAllClientsExternalLocal() {
			this.lastMessage = '' // Revisar si hay un error futuro
			this.searchClients = this.searchParent
			this.getAllClientsLocal(1, 'FORM')
		},
		getAllClientsLocal(page = 1, typeSearchClient = '') {
			this.typeSearchClient = typeSearchClient
			this.getAllClients(page)
		},
		getAllClientsResultLocal(response) {
			if (this.listClients.length == 1) {
				if (this.typeSearchClient == 'FORM') {
					this.selectClientLocal(this.listClients[0])
				}
			} else if (this.listClients.length > 1) {
				this.$emit('manyResults', this.listClients)
				this.$refs.searchClients.focus()
			} else {
				if (this.lastMessage != 'CLIENTNOTFOUND') {
					if (this.typeSearchClient == 'FORM') {
						this.lastMessage = 'CLIENTNOTFOUND'
						this.showMsgErrorHandling('No Encontrado', 'No se encontró ningún cliente con estos datos', 'warn', 5000, 'top right')
						this.$emit('clientNotFound')
					}
				} else {
					this.lastMessage = ''
				}
			}
		},
		initModuleLocal() {
			this.qRowsClient = 15
		},
		selectClientLocal(c) {
			this.$emit('selectClient', c)
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>