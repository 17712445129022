<template>
	<div id="budgetModule">
		<budget-component :id="id" ref="budgetComponent"></budget-component>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

import BudgetComponent from '@/components/BudgetComponent.vue'

export default {
	name: 'BudgetModule',
	mixins: [mixin, axiosAction, errors],
	props: ['id'],
	data() {
		return {
		}
	},
	components: {
		'budget-component': BudgetComponent
	},
	computed: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'defaultFunction': this.defaultFunction(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.initBudgetModule()
		},
		initBudgetModule() {
			console.log(this.id)
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}	
</script>

<style lang="scss"></style>
