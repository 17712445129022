<template>
	<div id="textAreaComponent">
		<button ref="btnSetText" @click="setTextLocal" v-show="false">Set Text</button>
		<textarea v-model="textLocal" @keydown.tab="pressTab" ref="textArea" @keyup="keyupLocal" :class="_class"
			:rows="_rows"></textarea>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

export default {
	name: 'TextAreaComponent',
	mixins: [mixin, axiosAction, errors],
	props: ['text', '_class', '_rows'],
	data() {
		return {
			textLocal: '',
		}
	},
	components: {
	},
	computed: {
	},
	methods: {
		initModuleLocal() {
			this.setTextAreaInit()
		},
		keyupLocal() {
			this.$emit('keyup', this.textLocal)
		},
		pressTab(e) {
			e.preventDefault()
			let start = e.target.selectionStart;
			let end = e.target.selectionEnd;
			let tabCharacter = "\t"
			e.target.value = e.target.value.substring(0, start) + '\t' + e.target.value.substring(end)
			e.target.selectionStart = e.target.selectionEnd = start + tabCharacter.length;
		},
		setTextLocal() {
			this.textLocal = this.text
		},
		setTextAreaInit() { },
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>