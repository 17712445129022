<template>
	<div id="orderModule">
		<order-component :token="token"></order-component>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

import OrderComponent from '@/components/OrderComponent.vue'

export default {
	name: 'OrderModule',
	mixins: [mixin, axiosAction, errors],
	props: ['token'],
	data() {
		return {
		}
	},
	components: {
		'order-component': OrderComponent
	},
	computed: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'defaultFunction': this.defaultFunction(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}	
</script>

<style lang="scss"></style>
