var budget = {
	data() {
		return {
			loadingBudgets: false,
			listBudgets: [],
			paginationBudgets: [],
			objectBudget: {
				exists: 0,
				id: 0,
				name: '',
				width: null,
				height: null,
				depth: null,
				dimensions: '',
				print_type_id: null,
				print_type: null,
				design: null,
				client_id: null,
				client: null,
				contact: '',
				phone: '',
				email: '',
				status_id: null,
				status: null,
				components: [],
				quantities: [],
				finishes: [],
				created_at: null,
				updated_at: null
			},
			objectBudgetComponent: {
				exists: 0,
				id: 0,
				budget_id: null,
				name: '',
				pages: null,
				width: null,
				height: null,
				widthxheight: '',
				print_mode: '',
				material_type_sub_classification_id: null,
				material_type_sub_classification: null,
				thickness: null,
				material_width: null,
				material_height: null,
				material_widthxheight: '',
				equipment_id: null,
				equipment: null,
				material_width_in_equipment: null,
				material_height_in_equipment: null,
				material_widthxheight_in_equipment: '',
				finishes: [],
				production_functions: [],
				changed: false,
				created_at: null,
				updated_at: null
			},
			objectBudgetComponentProductionFunction: {
				exists: 0,
				id: 0,
				budget_component_id: 0,
				budget_component: null,
				production_function_id: null,
				production_function: null,
				employee_id: null,
				employee: null,
				price_hour_function: 0,
				hours: 0,
				equipment_id: null,
				equipment: null,
				price_hour_equipment: 0,
				created_at: null,
				updated_at: null
			},
			objectBudgetQuantity: {
				id: 0,
				budget_id: 0,
				quantity: 0,
				created_at: null,
				updated_at: null
			},
			savingBudget: false,
			searchingbudget: false,
			searchBudgets: ''
		}
	},
	computed: {
		changedBudget() {
			let r = false
			for (const property in this.objectBudget) {
				if (property.substring(0, 1) != "_" && this.objectBudget['_' + property] != undefined) {
					if (this.objectBudget['_' + property] != this.objectBudget[property]) {
						r = true
					}
				}
			}
			return r
		},
		validateBudget() {
			// Comprueba que el nombre del trabajo dea de al menos 5 caracteres
			if (this.objectBudget.name.trim().length < 5) {
				return false
			}
			// Comprobamos que las dimensiones tengan un formato correcto 
			if (!this.validateDimensions(this.objectBudget.dimensions) && !this.validateDimensions(this.objectBudget.dimensions, '3D')) {
				return false
			} else {
				let d = this.objectBudget.dimensions.split('x')
				this.objectBudget.width = d[0]
				this.objectBudget.height = d[1]
				this.objectBudget.depth = d[2]
			}
			// Comprobamos que se elija un tipo de impreso
			if (this.objectBudget.print_type_id == 0 || this.objectBudget.print_type_id == null) {
				return false
			}
			// Comprobamos que se haya elegido si es con diseño o no
			if (this.objectBudget.design == null) {
				return false
			}
			// Comprobamos que tenga un cliente de la base de datos seleccionada
			if (this.objectBudget.client_id == null) {
				return false
			}
			if (this.objectBudget.contact.trim().length < 5) {
				return false
			}
			if (this.objectBudget.phone.trim().length < 9) {
				return false
			}
			if (!this.validateEmail(this.objectBudget.email)) {
				return false
			}
			return true
		},
		validateBudgetComponent() {
			let r = true
			this.objectBudget.components.forEach((c, i) => {
				if (c.name.trim().length < 5) {
					r = false
					return
				}
				if (isNaN(c.pages) || c.pages < 1) {
					r = false
					return
				}
				let wh = ''
				if (!this.validateDimensions(c.widthxheight)) {
					r = false
					return
				} else {
					wh = c.widthxheight.split('x')
					if (wh.length == 2) {
						c.width = wh[0]
						c.height = wh[1]
					}
				}
				let print_mode = c.print_mode.split('-')
				if (print_mode.length != 2 || isNaN(print_mode[0]) || isNaN(print_mode[1]) || print_mode[0] == '' || print_mode[1] == '') {
					r = false
					return
				}
				if (c.material_type_sub_classification_id == null) {
					r = false
					return
				}
				if (isNaN(c.thickness) || c.thickness < 1) {
					r = false
					return
				}
				if (c.material_widthxheight.length > 0 && !this.validateDimensions(c.material_widthxheight)) {
					r = false
					return
				} else {
					wh = c.material_widthxheight.split('x')
					if (wh.length == 2) {
						c.material_width = wh[0]
						c.material_height = wh[1]
					}
				}
				if (c.material_widthxheight_in_equipment.length > 0 && !this.validateDimensions(c.material_widthxheight_in_equipment)) {
					r = false
					return
				} else {
					wh = c.material_widthxheight_in_equipment.split('x')
					if (wh.length == 2) {
						c.material_width_in_equipment = wh[0]
						c.material_height_in_equipment = wh[1]
					}
				}
			})
			return r
		}
	},
	methods: {
		addItemListbudgets(b) {
			this.listBudgets.unshift(b)
		},
		addNewItemBlankObjectBudgetComponentProductionFunction(bc) {
			this.clearObjectBudgetComponentProductionFunction()
			let propiertiesItem = Object.create(this.objectBudgetComponentProductionFunction)
			let newObject = new Object()
			for (const property in propiertiesItem) {
				Object.defineProperty(newObject, property, { value: propiertiesItem[property], writable: true, enumerable: true, configurable: true })
			}
			bc.production_functions.push(newObject)
		},
		addNewItemBlankObjectBudgetComponent() {
			this.clearObjectBudgetComponent()
			let propiertiesItem = Object.create(this.objectBudgetComponent)
			let newObject = new Object()
			for (const property in propiertiesItem) {
				Object.defineProperty(newObject, property, { value: propiertiesItem[property], writable: true, enumerable: true, configurable: true })
			}
			this.objectBudget.components.push(newObject)
		},
		addNewItemBlankObjectBudgetQuantity() {
			this.clearObjectBudgetQuantity()
			let propiertiesItem = Object.create(this.objectBudgetQuantity)
			let newObject = new Object()
			for (const property in propiertiesItem) {
				Object.defineProperty(newObject, property, { value: propiertiesItem[property], writable: true, enumerable: true, configurable: true })
			}
			this.objectBudget.quantities.push(newObject)
		},
		clearObjectBudget() {
			this.objectBudget.exists = 0
			this.objectBudget.id = 0
			this.objectBudget.name = ''
			this.objectBudget.width = null
			this.objectBudget.height = null
			this.objectBudget.depth = null
			this.objectBudget.dimensions = ''
			this.objectBudget.print_type_id = null
			this.objectBudget.print_type = null
			this.objectBudget.design = null
			this.objectBudget.client_id = null
			this.objectBudget.client = null
			this.objectBudget.contact = ''
			this.objectBudget.phone = ''
			this.objectBudget.email = ''
			this.objectBudget.status_id = null
			this.objectBudget.status = null
			this.objectBudget.components = []
			this.objectBudget.quantities = []
			this.objectBudget.finishes = []
			this.addNewItemBlankObjectBudgetComponent()
		},
		clearObjectBudgetComponent() {
			this.objectBudgetComponent.exists = 0
			this.objectBudgetComponent.id = 0
			this.objectBudgetComponent.budget_id = null
			this.objectBudgetComponent.name = ''
			this.objectBudgetComponent.pages = null
			this.objectBudgetComponent.width = null
			this.objectBudgetComponent.height = null
			this.objectBudgetComponent.widthxheight = ''
			this.objectBudgetComponent.print_mode = ''
			this.objectBudgetComponent.material_type_sub_classification_id = null
			this.objectBudgetComponent.material_type_sub_classification = null
			this.objectBudgetComponent.thickness = null
			this.objectBudgetComponent.material_width = null
			this.objectBudgetComponent.material_height = null
			this.objectBudgetComponent.material_widthxheight = ''
			this.objectBudgetComponent.equipment_id = null
			this.objectBudgetComponent.equipment = null
			this.objectBudgetComponent.material_width_in_equipment = null
			this.objectBudgetComponent.material_height_in_equipment = null
			this.objectBudgetComponent.material_widthxheight_in_equipment = ''
			this.objectBudgetComponent.finishes = []
			this.objectBudgetComponent.production_functions = []
			this.objectBudgetComponent.created_at = null
			this.objectBudgetComponent.updated_at = null
		},
		clearObjectBudgetComponentProductionFunction() {
			this.objectBudgetComponentProductionFunction.exists = 0
			this.objectBudgetComponentProductionFunction.id = 0
			this.objectBudgetComponentProductionFunction.budget_component_id = 0
			this.objectBudgetComponentProductionFunction.budget_component = null
			this.objectBudgetComponentProductionFunction.production_function_id = null
			this.objectBudgetComponentProductionFunction.production_function = null
			this.objectBudgetComponentProductionFunction.employee_id = null
			this.objectBudgetComponentProductionFunction.employee = null
			this.objectBudgetComponentProductionFunction.price_hour_function = 0
			this.objectBudgetComponentProductionFunction.hours = 0
			this.objectBudgetComponentProductionFunction.equipment_id = null
			this.objectBudgetComponentProductionFunction.equipment = null
			this.objectBudgetComponentProductionFunction.price_hour_equipment = 0
			this.objectBudgetComponentProductionFunction.created_at = null
			this.objectBudgetComponentProductionFunction.updated_at = null
		},
		clearObjectBudgetQuantity() {
			this.objectBudgetQuantity.id = 0
			this.objectBudgetQuantity.budget_id = 0
			this.objectBudgetQuantity.quantity = 0
		},
		deleteBudget(id) {
			let url = this.getApiBackendNew + 'budget/' + id + '/delete'
			this.axiosActionIS(url, 'DELETE', 'deleteBudgetResult')
		},
		deleteBudgetResult(response) {
			if (response.data.success == true) {
				this.deleteItemListBudget(response.data.data)
			}
			this.deleteBudgetResultLocal(response)
		},
		deleteBudgetResultLocal(response) {
			console.log('deleteBudgetResultLocal no desarrollado', response)
		},
		deleteItemListBudget(id) {
			console.log(id)
			let index = this.listBudgets.map((el) => parseInt(el.id)).indexOf(parseInt(id))
			let budgetDeleted = this.listBudgets.splice(index, 1)
		},
		getBudgets(page = 1) {
			this.listBudgets = []
			this.paginationBudgets = []
			this.loadingBudgets = true
			let param = '?page=' + page + '&search=' + this.searchBudgets
			let url = this.getApiBackendNew + 'budgets' + param
			this.axiosActionIS(url, 'GET', 'getBudgetsResult')
		},
		getBudgetsResult(response) {
			this.loadingBudgets = false
			if (response.data.success = true) {
				this.listBudgets = response.data.data
				this.paginationBudgets = response.data.paginate
			}
			this.getBudgetsResultLocal(response)
		},
		getBudgetsResultLocal(response) {
			console.log('getBudgetsResultLocal no desarrollado', response)
		},
		saveBudget(b) {
			this.savingBudget = true
			let method = (b.id == 0) ? 'POST' : 'PUT'
			let url = this.getApiBackendNew + 'budget/'
			url += (method == 'POST') ? 'register' : b.id + '/update'
			this.axiosActionIS(url, method, 'saveBudgetResult', b)
		},
		saveBudgetResult(response) {
			this.savingBudget = false
			if (response.data.success == true) {
				this.setObjectBudget(response.data.data)
				this.set
			}
			this.saveBudgetResultLocal(response)
		},
		saveBudgetResultLocal(response) {
			console.log('saveBudgetResultLocal no desarrollado', response)
		},
		setObjectBudget(b) {
			this.clearObjectBudget()
			for (const property in b) {
				this.objectBudget[property] = b[property]
			}
			this.objectBudget.dimensions = this.objectBudget.width + 'x' + this.objectBudget.height
			this.objectBudget.dimensions += (this.objectBudget.depth != null) ? 'x' + this.objectBudget.depth : ''
			this.objectBudget.design = (this.objectBudget.design == null) ? 0 : this.objectBudget.design
			this.objectBudget.components.forEach(c => {
				c.widthxheight = (c.width != null && c.height != null) ? c.width + 'x' + c.height : ''
				c.material_widthxheight = (c.material_width != null && c.material_height != null) ? c.material_width + 'x' + c.material_height : ''
				c.material_widthxheight_in_equipment = (c.material_width_in_equipment != null && c.material_height_in_equipment != null) ? c.material_width_in_equipment + 'x' + c.material_height_in_equipment : ''
			});
		},
		setObjectBudgetCopy() {
			for (const property in this.objectBudget) {
				if (!Array.isArray(this.objectBudget[property])) {
					this.objectBudget['_' + property] = this.objectBudget[property]
				}
			}
		},
		showBudget(id, resource = '') {
			this.searchingBudget = true
			let param = '?resource=' + resource
			let url = this.getApiBackendNew + 'budget/' + id + param
			this.axiosActionIS(url, 'GET', 'showBudgetResult')
		},
		showBudgetResult(response) {
			this.searchingBudget = false
			if (response.data.success == true) {
				this.setObjectBudget(response.data.data)
			}
			this.showBudgetResultLocal(response)
		},
		showBudgetResultLocal(response) {
			console.log('showBudgetResultLocal no desarrollado', response)
		}
	},
};

export default budget;