import { computed } from "vue";

var budgetComponent = {
    data() {
        return {
            listBudgetComponents: [],
            loadingBudgetComponents: false,
            paginationBudgetComponents: [],
            savingBudgetComponent: false,
            searchingBudgetComponent: false,
            searchBudgetComponents: ''
        }
    },
    computed: {
        changedListBudgetComponents() {
            let r = false
            this.listBudgetComponents.forEach(bc => {
                if (bc.id > 0) {
                    bc.changed = this.changedObject(bc)
                    if (bc.changed == true) {
                        r = bc.changed
                    }
                }
            })
            return r
        }
    },
    methods: {
        addItemListBudgetComponents(bc) {
            this.listBudgetComponents.push(bc)
            let index = this.listBudgetComponents.map((el) => parseInt(el.id)).indexOf(parseInt(bc.id))
            this.setListBudgetComponent(this.listBudgetComponents[index])
            return index
        },
        deleteBudgetComponent(bc) {
            let url = this.getApiBackendNew + 'budget/' + bc.budget_id + '/component/' + bc.id + '/delete'
            this.axiosActionIS(url, 'DELETE', 'deleteBudgetComponentResult')
        },
        deleteBudgetComponentResult(response) {
            if (response.data.success == true) {
                this.deleteItemListBudgetComponent(response.data.data)
            }
            this.deleteBudgetComponentResultLocal(response)
        },
        deleteBudgetComponentResultLocal(response) {
            console.log('deleteBudgetComponentResultLocal no desarrollado', response)
        },
        deleteItemListBudgetComponent(id) {
            let index = this.listBudgetComponents.map((el) => parseInt(el.id)).indexOf(parseInt(id))
            let budgetComponentDeleted = this.listBudgetComponents.splice(index, 1)
        },
        getBudgetComponents(page = 1) {
            this.listBudgetComponents = []
            this.paginationBudgetComponents = []
            this.loadingBudgetComponents = true
            let param = '?page=' + page + '&search=' + this.searchBudgetComponents
            let url = this.getApiBackendNew + 'budgetComponents' + param
            this.axiosActionIS(url, 'GET', 'getBudgetComponentsResult')
        },
        getBudgetComponentsByBudgetId(budgetId) {
            this.listBudgetComponents = []
            this.paginationBudgetComponents = []
            this.loadingBudgetComponents = true
            let url = this.getApiBackendNew + 'budget/' + budgetId + '/components'
            this.axiosActionIS(url, 'GET', 'getBudgetComponentsResult')
        },
        getBudgetComponentsResult(response) {
            this.loadingBudgetComponents = false
            if (response.data.success == true) {
                this.listBudgetComponents = response.data.data
                this.paginationBudgetComponents = response.data.paginate
                this.listBudgetComponents.forEach(c => {
                    c.widthxheight = (c.width != null && c.height != null) ? c.width + 'x' + c.height : ''
                    c.material_widthxheight = (c.material_width != null && c.material_height != null) ? c.material_width + 'x' + c.material_height : ''
                    c.material_widthxheight_in_equipment = (c.material_width_in_equipment != null && c.material_height_in_equipment != null) ? c.material_width_in_equipment + 'x' + c.material_height_in_equipment : ''
                });

            }
            this.getBudgetComponentsResultLocal(response)
        },
        getBudgetComponentsResultLocal(response) {
            console.log('getBudgetComponentsResultLocal no desarrollado', response)
        },
        saveBudgetComponent(bc) {
            this.savingBudgetComponent = true
            let method = (bc.id == 0) ? 'POST' : 'PUT'
            let url = this.getApiBackendNew + 'budget/' + bc.budget_id + '/component/'
            url += (method == 'POST') ? 'register' : bc.id + '/update'
            this.axiosActionIS(url, method, 'saveBudgetComponentResult', bc)
        },
        saveBudgetComponentResult(response) {
            this.savingBudgetComponent = false
            if (response.data.success == true) {
                this.setObjectBudgetComponent(response.data.data)
            }
            this.saveBudgetComponentResultLocal(response)
        },
        saveBudgetComponentResultLocal(response) {
            console.log('saveBudgetComponentResultLocal no desarrollado', response)
        },
        setListBudgetComponent(bc) {
            bc.widthxheight = (bc.width != null && bc.height != null) ? bc.width + 'x' + bc.height : ''
            bc.material_widthxheight = (bc.material_width != null && bc.material_height != null) ? bc.material_width + 'x' + bc.material_height : ''
            bc.material_widthxheight_in_equipment = (bc.material_width_in_equipment != null && bc.material_height_in_equipment != null) ? bc.material_width_in_equipment + 'x' + bc.material_height_in_equipment : ''
        },
        setListBudgetComponentCopy(bc) {
            for (const property in bc) {
                if (typeof bc[property] != 'object') {
                    bc['_' + property] = bc[property]
                }
            }
        },
        setObjectBudgetComponent(bc) {
            this.clearObjectBudgetComponent()
            for (const property in bc) {
                this.objectBudgetComponent[property] = bc[property]
            }
        },
        showBudgetComponent(id) {
            this.searchingBudgetComponent = true
            let url = this.getApiBackendNew + 'budgetComponent/' + id
            this.axiosActionIS(url, 'GET', 'showBudgetComponentResult')
        },
        showBudgetComponentResult(response) {
            this.searchingBudgetComponent = false
            if (response.data.success == true) {
                this.setObjectBudgetComponent(response.data.data)
            }
            this.showBudgetComponentResultLocal(response)
        },
        showBudgetComponentResultLocal(response) {
            console.log('showBudgetComponentResultLocal no desarrollado', response)
        },
        updateItemListBudgetComponents(data) {
            let index = this.listBudgetComponents.map(e => parseInt(e.id)).indexOf(data.id)
            if (index > -1) {
                for (const property in data) {
                    this.listBudgetComponents[index][property] = data[property]
                }
                this.setListBudgetComponent(this.listBudgetComponents[index])
            }
            return index
        },
    },
};

export default budgetComponent;