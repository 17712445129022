<template>
	<div id="orderComponent">
		<div v-show="searchingOrder == true">
			<mensagge-loading-component class="bg-aasinet text-white"
				:text="'Buscando Orden de Pedido Nº ' + this.token">
			</mensagge-loading-component>
		</div>

		<div v-show="searchingOrder == false" class="fs---1">
			<div class="card mb-2">
				<div class="row p-2">
					<div class="col-6">
						<h5 class="fs-0 ms-2">
							Orden de Pedido
							<span v-show="objectOrder.id == 0">
								- <span class="text-primary">Nuevo {{ token }}</span>
							</span>
							<span v-show="objectOrder.id > 0">
								Nº <span @dblclick="console(objectOrder)"
									:title="'Creado: ' + objectOrder.created_at">{{ objectOrder.id }}</span>
								<span class="" v-if="objectOrder.status != null">
									| Estado:
									<span class="badge" :class="'bg-' + objectOrder.status.color"
										:title="'Actualizado: ' + objectOrder.updated_at">{{
											objectOrder.status.name }}</span>
								</span>
							</span>
						</h5>
					</div>
					<div class="col text-end me-4">
						<div v-show="visibilyBtnsOrder == true">
							<button class="px-2 btn btn-link btn-sm" :class="'text-' + checkAllItemOrderPayment"
								title="Forma de Pago" :disabled="disableSpanCashRegister"
								@click="showOrderPaymentLocal()" ref="spanSpanCashRegister" data-bs-toggle="modal"
								data-bs-target="#orderPaymentModal">
								<i class="fas fa-cash-register"></i>
							</button>
							<button class="px-2 btn btn-link btn-sm" title="Guardar Pedido"
								:disabled="disableSpanSaveOrder" @click="beforeSavingOrder">
								<i class="far fa-save"></i>
							</button>
							<button class="btn btn-link btn-sm px-2" title="Pedidos WEB"
								@click="getJumpsellerOrdersLocal" data-bs-toggle="modal"
								data-bs-target="#jumpsellerOrdersModal" v-show="visibilyBtnWEB == true">
								<img width="16px" src="/assets/img/a.png">
							</button>
							<button class="btn btn-link btn-sm px-2" title="Limpiar Formulario" @click="clearFormOrder">
								<i class="fas fa-broom text-secondary"></i>
							</button>

						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-7">
					<div class="card mb-3">
						<div class="card-header pb-0">
							<h6 class="mb-2">
								Detalle del Pedido
							</h6>
							<div class="dropdown btn-reveal-trigger position-absolute top-0 end-0 m-2 mb-0"
								v-show="priceListIdSelected > 0">
								<button
									class="btn btn-link btn-reveal text-600 btn-sm dropdown-toggle dropdown-caret-none"
									type="button" id="studentInfoDropdown" data-bs-toggle="dropdown"
									data-boundary="viewport" aria-haspopup="true" aria-expanded="false">
									<span class="fas fa-ellipsis-h fs--2"></span>
								</button>
								<div class="dropdown-menu dropdown-menu-end border py-2"
									aria-labelledby="studentInfoDropdown">
									<span class="dropdown-item pointer" data-bs-toggle="modal"
										data-bs-target="#productPriceListSearchModal" ref="spanProductPriceListSearch"
										@click="productPriceListSearchModalOpen = true">Buscar productos</span>
									<span class="dropdown-item pointer" data-bs-toggle="modal"
										data-bs-target="#jumpsellerPromotionsModal" ref="spanJumpsellerPromotion"
										@click="showJumpsellerPromotionsModal">Promociones</span>
									<div class="dropdown-divider"></div>
									<span class="dropdown-item pointer" href="#!" data-bs-toggle="modal"
										data-bs-target="#clientListSeachModal" ref="dropdownItemChangeClient"
										@click="clientListSeachModalOpen = true">Cambiar
										Cliente</span>
								</div>
							</div>
							<div class="row" v-show="visibilySearchProducts">
								<div class="col-md-6">
									<div class="input-group input-group-sm">
										<input class="form-control form-control-sm" type="text"
											:disabled="disabledInputSearchProducts" v-model="searchProductsLocal"
											@keyup.enter="analizeSTRLocal" @focus="$event.target.select()"
											ref="searchProducts" />
										<span class="input-group-text">x</span>
										<span class="input-group-text">{{ orderProductQuantity }}</span>
									</div>
								</div>
								<div class="col-md-6">
									<select class="form-select form-select-sm" v-model="priceListIdSelected"
										@change="changeSelectPriceList" :disabled="selectPriceList">
										<option disabled value="0">Seleccione la lista de precio</option>
										<option v-for="(pl, i) in listPriceListsFilter" :key="i" :value="pl.id">{{
											pl.name }}</option>
									</select>
								</div>
							</div>
						</div>
						<div class="card-body">
							<div class="table-responsive fs--1" style="height: 550px;">
								<table class="table table-striped border-bottom">
									<thead class="bg-200">
										<tr>
											<th class="text-900 border-0">Producto</th>
											<th class="text-900 border-0 text-end" width="100px">Precio</th>
											<th class="text-900 border-0 text-center" width="100px">Cantidad</th>
											<th class="text-900 border-0 text-end" width="115px">Total</th>
											<th class="text-900 border-0 text-center px-0" title="Bonifica?"
												v-show="visibleColumnTeam">B?</th>
											<th class="text-900 border-0 text-center px-0" title="Diezma?"
												v-show="visibleColumnTeam">D?</th>
											<th class="text-900 border-0 text-center px-0" title="Lleva Flete?"
												v-show="visibleColumnTeam">F?</th>
											<th class="text-900 border-0 text-end px-0"></th>
										</tr>
									</thead>
									<tbody>
										<tr class="border-200 icon-hiden" v-for="(op, i) in listOrderProductsFilter"
											:key="i">
											<td class="align-middle">
												<h6 class="mb-0 text-nowrap">{{ op.product.name }}</h6>
												<p class="mb-0">Código: {{ op.product_id }}</p>
											</td>
											<td class="align-middle text-end">
												<input class="input-clear text-end fs--1" v-model="op.price"
													:title="'Precio Original ' + methodFormatNumber(op.original_price)"
													@focus="$event.target.select()"
													:disabled="disabledInputObjectOrderProductPrice(op)">
											</td>
											<td class="align-middle text-center">
												<input class="input-clear text-center" v-model="op.quantity"
													style="width:80px" @blur="calculatePrecioItemOrderProduct(op)"
													@keyup.enter="calculatePrecioItemOrderProduct(op)"
													@focus="$event.target.select()"
													:disabled="disabledInputObjectOrderProductQuantity(op)">
											</td>
											<td class="align-middle text-end">
												${{ methodFormatNumber(op.price * op.quantity) }}
											</td>
											<td class="align-middle text-center px-0" v-show="visibleColumnTeam">
												<span v-if="op.bonusable == 1">
													<i class="fas fa-check text-success"></i>
												</span>
											</td>
											<td class="align-middle text-center px-0" v-show="visibleColumnTeam">
												<span v-if="op.tithable == 1">
													<i class="fas fa-check text-success"></i>
												</span>
											</td>
											<td class="align-middle text-center px-0" v-show="visibleColumnTeam">
												<span v-if="op.with_freight > 0">
													<i class="fas fa-check text-success"></i>
												</span>
											</td>
											<td class="align-middle text-end px-0">
												<span class=" visible-hover pointer"
													@click="deleteItemObjectOrderProductLocal(op)">
													<i class="far fa-trash-alt text-danger"></i>
												</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="row g-0 justify-content-end border-top">
								<div class="col-auto">
									<table class="table table-sm table-borderless fs--1 text-end">
										<tr>
											<th class="text-900">Sub Total:</th>
											<td class="fw-semi-bold">{{ methodFormatNumber(subTotalOrder) }}</td>
										</tr>
										<tr>
											<th class="text-900">Flete:</th>
											<td class="fw-semi-bold">
												<input class="input-clear text-end fs--1" v-model="objectOrder.freight"
													@focus="$event.target.select()" :disabled="!modifiableOrder"
													style="padding: 0; font-size: 24px;">
											</td>
										</tr>
										<tr>
											<th class="text-900">Diezmo:</th>
											<td class="fw-semi-bold">
												{{ methodFormatNumber(titheOrderProducts) }}
											</td>
										</tr>
										<tr class="border-top fs-1 fw-black">
											<th class="text-900">TOTAL A PAGAR:</th>
											<td class="text-900 fw-semi-bold">${{ methodFormatNumber(totalOrder) }}</td>
										</tr>
										<tr class="">
											<th class="text-900">Pagado:</th>
											<td class="fw-semi-bold">
												<span class="p-0" @dblclick="showOrderPaymentLocal('dbl')">${{
													methodFormatNumber(totalOrderPayment) }}</span>
											</td>
										</tr>
										<tr class="fs-1 fw-black">
											<td class="text-end">
												<span v-show="totalOrderPayment > totalOrder"
													class="text-primary">VUELTO:</span>
												<span v-show="totalOrderPayment < totalOrder"
													class="text-danger">DEUDA:</span>
											</td>
											<td class="text-end">
												<span v-show="totalOrderPayment != totalOrder" class="p-0">
													{{ methodFormatNumber(totalOrderPayment - totalOrder) }}
												</span>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-5">
					<div v-if="orderWEB != null">
						<div class="alert alert-info fs--1" role="alert">
							Está tomando el pedido WEB Nº <span class="pointer btn-link"
								@dblclick="console(orderWEB)">{{
									orderWEB.id }}</span> que está
							<span :class="setDataByJumpsellerStatus(orderWEB.status, 'class')"
								style="padding: 2px 5px;">
								{{ setDataByJumpsellerStatus(orderWEB.status, 'spanish') }}
							</span>,
							por un monto de ${{ methodFormatNumber(orderWEB.total) }}
						</div>

						<div v-if="searchingJumpsellerLinkCustomer" class="alert alert-info fs--1" role=" alert">
							Buscando si el cliente WEB Nº {{ orderWEB.customer.id }} está vinculado a un
							cliente en el sistema . . . <img src="/assets/img/search-color.gif" width="30px" />
						</div>
						<div v-if="!searchingJumpsellerLinkCustomer && !jumsellerCustomerLink"
							class="alert alert-warning fs--1" role=" alert">
							El cliente WEB Nº
							<a :href="'https://editorial-aces-chile.jumpseller.com/admin/cl/customers/show/' + orderWEB.customer.id"
								target="_blank">{{ orderWEB.customer.id }}</a>
							no está vinculado en el sistema
						</div>
					</div>

					<div class="card font-sans-serif mb-2">
						<div class="card-header pb-0">
							<h6 class="mb-0">Datos del Cliente <span
									v-show="objectOrder.client != null && objectOrder.client_id == 0">-
									<span>Nuevo</span></span></h6>
							<div class="dropdown btn-reveal-trigger position-absolute top-0 end-0 m-2 mb-0"
								v-show="modifiableOrder == true">
								<button
									class="btn btn-link btn-reveal text-600 btn-sm dropdown-toggle dropdown-caret-none"
									type="button" id="studentInfoDropdown" data-bs-toggle="dropdown"
									data-boundary="viewport" aria-haspopup="true" aria-expanded="false">
									<span class="fas fa-ellipsis-h fs--2"></span>
								</button>
								<div class="dropdown-menu dropdown-menu-end border py-2"
									aria-labelledby="studentInfoDropdown">
									<span class="dropdown-item pointer" @click="showClientLocal()">Ver Cliente</span>
									<span class="dropdown-item pointer"
										@click="selectClientLocal(defaultClient)">Cliente
										Predeterminado</span>
									<div class="dropdown-divider"></div>
									<span class="dropdown-item pointer" href="#!" data-bs-toggle="modal"
										data-bs-target="#clientListSeachModal" ref="dropdownItemChangeClient"
										@click="clientListSeachModalOpen = true">Cambiar
										Cliente</span>
								</div>
							</div>
						</div>
						<div class="card-body align-items-center">
							<div v-show="objectClient.exists == 0 && modifiableOrder == true" class="fs--1">
								<input type="text" v-model="searchClientsLocal" class="form-control form-control-sm"
									placeholder="Ingrese Nombre o Numero de Documento" @keyup.enter="getClientLocal()"
									ref="inputSearchClients" @focus="$event.target.select()"
									v-show="loadingClients == false">
								<span v-show="loadingClients == true">Buscando Cliente . . . {{ loadingClients }}</span>
							</div>
							<table class="table table-borderless fs--1 fw-medium mb-0" v-if="objectClient.exists == 1">
								<tbody>
									<tr>
										<td class="p-1" style="width: 35%;">Nombre</td>
										<td class="p-1 text-600">{{ objectOrder.client.name }}</td>
									</tr>
									<tr>
										<td class="p-1" style="width: 35%;">Apellido</td>
										<td class="p-1 text-600">{{ objectOrder.client.surname }}</td>
									</tr>
									<tr>
										<td class="p-1" style="width: 35%;">Numero de Documento:</td>
										<td class="p-1 text-600">{{ objectOrder.client.number_document }}</td>
									</tr>
									<tr>
										<td class="p-1" style="width: 35%;">Email:</td>
										<td class="p-1"><a class="text-600 text-decoration-none"
												:href="'mailto:' + objectOrder.client.email">{{ objectOrder.client.email
												}}
											</a>
										</td>
									</tr>
									<tr>
										<td class="p-1" style="width: 35%;">Telefono:</td>
										<td class="p-1"><a class="text-600 text-decoration-none"
												:href="'tel:' + objectOrder.client.phone">{{ objectOrder.client.phone
												}}</a>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div class="card" v-show="false">
						<div class="card-body">
							<shipping-request-component ref="shippingRequestComponent" :withLinkGenesys="true"
								:id="shippingRequestSelected"
								@saveShippingRequestResultComponent="saveShippingRequestResultLocal"
								@savedShippingRequest="savedShippingRequestLocal" :data="objectShippingRequest">
							</shipping-request-component>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div class="modal fade" id="jumpsellerPromotionsModal" tabindex="-1"
			aria-labelledby="jumpsellerPromotionsModalLabel" aria-hidden="true" data-bs-backdrop="static">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="modal-body">
						<jumpseller-promotion-list-component ref="jumpsellerPromotionListComponent"
							@selectedJumpsellerPromotion="selectedJumpsellerPromotionLocal"></jumpseller-promotion-list-component>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
							ref="btnCloseJumpsellerPromotionsModal">Cerrar</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="jumpsellerOrdersModal" tabindex="-1" aria-labelledby="jumpsellerOrdersModalLabel"
			aria-hidden="true" data-bs-backdrop="static">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="modal-body">
						<jumpseller-order-list-component ref="jumpsellerOrderListComponent"
							@selectedJumpsellerOrder="selectedJumpsellerOrderLocal"></jumpseller-order-list-component>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
							ref="btnCloseJumpsellerOrdersModal">Cerrar</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="clientListSeachModal" tabindex="-1" aria-labelledby="clientListSeachModalLabel"
			aria-hidden="true" data-bs-backdrop="static">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<client-list-search-component @manyResults="clientManyResults" @selectClient="selectClientLocal"
						@clientNotFound="clientNotFoundLocal" :searchParent="searchClientsLocal"
						ref="clientListSearchComponent"></client-list-search-component>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"
							ref="btnCloseClientListSeachModal" @click="clientListSeachModalOpen = false">Cerrar</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="productPriceListSearchModal" tabindex="-1"
			aria-labelledby="productPriceListSearchModalLabel" aria-hidden="true" data-bs-backdrop="static">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<product-price-list-search-component @manyResults="productPriceListManyResults"
						@selectProduct="selectProductPriceListLocal" @clientNotFound="productPriceListNotFoundLocal"
						:searchParent="searchProductsLocal" :priceListId="priceListIdSelected"
						:quantity="orderProductQuantity"
						ref="productPriceListSearchComponent"></product-price-list-search-component>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"
							ref="btnCloseProductPriceListSearchModal"
							@click="productPriceListSearchModalOpen = false">Cerrar</button>
					</div>
				</div>
			</div>
		</div>

		<!-- Button trigger modal -->
		<button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal"
			data-bs-target="#selectClientDefaultModal" ref="btnSelectClientDefaultModal" v-show="false">
			Show Select Client Default
		</button>

		<!-- Modal -->
		<div class="modal fade" id="selectClientDefaultModal" tabindex="-1"
			aria-labelledby="selectClientDefaultModalLabel" aria-hidden="true" data-bs-backdrop="static">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-body fs--1">
						<h5 class="px-3">El cliente WEB con el RUT <span v-if="orderWEB != null">{{
							orderWEB.billing_address.taxid }}</span> no está registrado</h5>
						<ul class="ul-select-client-order-web">
							<li class="bg-100 pointer opacity-hover" v-if="orderWEB != null" data-bs-dismiss="modal"
								@click="setNewClientWEBLocal()">
								<h6 class="text-secondary">
									Registrar el Cliente {{ orderWEB.billing_address.name }} {{
										orderWEB.billing_address.surname }}
								</h6>
								<div>RUT: {{ orderWEB.billing_address.taxid }}</div>
							</li>
							<li class="bg-100 pointer opacity-hover" v-else data-bs-dismiss="modal"
								@click="setNewClientLocal()">
								<h6 class="text-secondary">
									Registrar el Cliente con este dato "{{ this.searchClientsLocal }}"
								</h6>
							</li>
							<li class="bg-100 pointer opacity-hover" @click="selectClientLocal(defaultClient)"
								data-bs-dismiss="modal">
								<h6 class="text-secondary">Usar {{ defaultClient.name.toUpperCase() }} {{
									defaultClient.surname.toUpperCase()
								}}
								</h6>
								<div>RUT: {{ defaultClient.number_document.toUpperCase() }}</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="orderPaymentModal" tabindex="-1" aria-labelledby="orderPaymentModalLabel"
			aria-hidden="true" data-bs-backdrop="static">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-body">
						<div>
							<div class="row fs--1 justify-content-center text-center">
								<div class="col-3 text-center opacity-hover"
									v-for="(tp, index) in listTypePaymentsFilter" :key="index">
									<div class="item-type-payment pointer mb-2"
										:class="'text-' + tp.color + ' border border-' + tp.color"
										@click="addItemListOrderPaymentLocal(tp)">
										<div class="item-type-payment-icon">
											<i :class="tp.class" class="fs-5"
												v-show="tp.class != null && tp.class != ''"></i>
											<i class="far fa-image fs-5"
												v-show="tp.class == null || tp.class == ''"></i>
										</div>
										<div class="px-2">
											<span>{{ tp.name }}</span>
											<span class="float-end"
												v-show="existsTypePraymentInOrderPayment(tp.id) == true">
												<i class="fas fa-check-circle text-success"></i>
											</span>
										</div>
									</div>
								</div>
							</div>
							<div>
								<table class="table table-sm fs--1">
									<thead>
										<tr>
											<th class="text-center">ID</th>
											<th>Forma de Pago</th>
											<th class="text-end">Monto</th>
											<th>Banco</th>
											<th class="text-center">Código</th>
											<th>Descripción</th>
											<th class="text-center">Fecha</th>
											<th class="text-center">Estado</th>
											<th class="text-end"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(op, i) in listOrderPaymentsFilter" :key="i">
											<td class="text-center">{{ op.id }}</td>
											<td>{{ op.type_payment.name }}</td>
											<td class="text-end">
												<input class="input-clear text-end" v-model="op.amount"
													@focus="$event.target.select()"
													:ref="'inputItemListOrderPaymentAmount' + i"
													:disabled="disabledItemListOrderPayment(op)" />
											</td>
											<td>
												<select class="select-clear px-2" v-model="op.bank_id"
													v-show="op.type_payment.listBanks.length > 0"
													:disabled="disabledItemListOrderPayment(op)">
													<option disabled :value="null">Seleccione Banco</option>
													<option v-for="(b, x) in op.type_payment.listBanks" :key="x"
														:value="b.id">{{ b.name
														}}</option>
												</select>
											</td>
											<td class=" text-center">
												<input class="input-clear text-center" v-model="op.code"
													@focus="$event.target.select()" placeholder="Código de Transacción"
													:disabled="disabledItemListOrderPayment(op)" />
											</td>
											<td>
												<input class="input-clear" v-model="op.description"
													@focus="$event.target.select()" placeholder="Descripción"
													:disabled="disabledItemListOrderPayment(op)" />
											</td>
											<td class="text-center">
												<input class="input-clear text-center" v-model="op.transaction_date"
													type="date" @focus="$event.target.select()"
													:disabled="(op.type_payment.requires_date == 0 || disabledItemListOrderPayment(op) == true)" />
											</td>
											<td class="text-center">
												<span v-if="op.status != null">
													{{ op.status.name }}
												</span>
											</td>
											<td class="text-end">
												<span v-html="checkItemOrderPayment(op, 'html')">
												</span>
											</td>
										</tr>
									</tbody>
									<tfoot>
										<tr class="fs-1">
											<td colspan="2" class="text-end">Total Pagos:</td>
											<td class="text-end">
												<span
													:class="(totalOrderPayment < totalOrder) ? 'text-danger' : (totalOrderPayment > totalOrder) ? 'text-success' : ''">
													{{ methodFormatNumber(totalOrderPayment) }}
												</span>
											</td>
											<td colspan="4" class="text-end">
												<span v-show="totalOrderPayment > totalOrder"
													class="text-primary">Vuelto:</span>
												<span v-show="totalOrderPayment < totalOrder"
													class="text-danger">Deuda:</span>
											</td>
											<td colspan="2" class="text-end">
												<span v-show="totalOrderPayment != totalOrder">
													{{ methodFormatNumber(totalOrderPayment - totalOrder) }}
												</span>
											</td>
										</tr>
									</tfoot>
								</table>
							</div>
						</div>
						<div class="text-end">
							<button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"
								ref="btnCloseOrderPaymentModal">Cerrar</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import orders from '@/mixins/orders'
import shippingRequest from '@/mixins/shippingsRequest'
import jumpsellerCustomer from '@/mixins/jumpseller/customer.js'
import jumpsellerOrder from '@/mixins/jumpseller/order.js'
import jumpsellerProduct from '@/mixins/jumpseller/product.js'
import clients from '@/mixins/clients'
import priceList from '@/mixins/generals/priceList.js'
import analizeSTR from '@/mixins/generals/analizeSTR.js'
import typePayment from '@/mixins/generals/typePayment.js'

import JumpsellerPromotionListComponent from '@/components/JumpsellerPromotionListComponent.vue'
import JumpsellerOrderListComponent from '@/components/JumpsellerOrderListComponent.vue'
import MessageLoadingComponent from '@/components/MessageLoadingComponent.vue'
import ShippingRequestComponent from '@/components/ShippingRequestComponent.vue'
import ClientListSearchComponent from '@/components/ClientListSearchComponent.vue'
import ProductPriceListSearchComponent from '@/components/ProductPriceListSearchComponent.vue'

export default {
	name: 'Order',
	mixins: [mixin, axiosAction, errors, orders, shippingRequest, jumpsellerCustomer, jumpsellerOrder, jumpsellerProduct, clients, priceList, analizeSTR, typePayment],
	props: ['token'],
	data() {
		return {
			priceListIdSelected: 0,
			confirmingOrder: false,
			shippingRequestSelected: 0,
			searchClientsLocal: '',
			searchProductsLocal: '',
			disabledBtnSaveShippingRequest: false,
			orderWEB: null,
			clientListSeachModalOpen: false,
			orderProductQuantity: 1,
			countSearchClient: 0,
			productPriceListSearchModalOpen: false,
			statusIdsToModifyOrder: [null, 0, 30],
			resultTypeCheckOrderPayment: [
				{ status_id: -1, html: '<i class="fas fa-exclamation-triangle text-danger" title="Faltan datos"></i>', color: 'danger', title: 'Faltan datos' },
				{ status_id: 0, html: '<i class="fas fa-exclamation-triangle text-warning" title="Monto en 0"></i>', color: 'warning', title: 'Monto en 0' },
				{ status_id: 1, html: '<i class="text-success" title="Completo"></i>', color: 'success', title: 'Completo' },
			],
			typeOrderIdsTeam: [2, 4, 5, 6, 14, 15]
		}
	},
	components: {
		'product-price-list-search-component': ProductPriceListSearchComponent,
		'client-list-search-component': ClientListSearchComponent,
		'jumpseller-promotion-list-component': JumpsellerPromotionListComponent,
		'jumpseller-order-list-component': JumpsellerOrderListComponent,
		'mensagge-loading-component': MessageLoadingComponent,
		'shipping-request-component': ShippingRequestComponent,
	},
	computed: {
		checkAllItemOrderPayment() {
			if (this.objectOrder.order_payment.length > 0) {
				let r = 1
				let re = 1
				this.objectOrder.order_payment.forEach(op => {
					re = this.checkItemOrderPayment(op)
					if (re == 0 && r == 1) {
						r = 0
					}
					if (re == -1) {
						r = -1
					}
				})
				let i = this.resultTypeCheckOrderPayment.map(rt => rt.status_id).indexOf(r)
				if (i > -1) {
					return this.resultTypeCheckOrderPayment[i]['color']
				}
			}

			return 'primary'
		},
		disabledInputSearchProducts() {
			if (this.priceListIdSelected == 0)
				return true
			return false
		},
		disableSpanCashRegister() {
			if (this.modifiableOrder == true) {
				return false
			}
			return true
		},
		disableSpanSaveOrder() {
			if (this.modifiableOrder == true) {
				if (this.validateFormOrder == true) {
					return false
				}
			}
			return true
		},
		listOrderPaymentsFilter() {
			return this.objectOrder.order_payment
		},
		listOrderProductsFilter() {
			this.objectOrder.order_products.forEach(op => {
				op.price = parseInt(op.price)
				op.original_price = parseInt(op.original_price)
			})
			return this.objectOrder.order_products
		},
		listPriceListsFilter() {
			let list = []
			let existsPriceList = false
			list = this.listPriceLists.filter(lp => {
				if (this.objectOrder.client != null) {
					if (this.objectOrder.client.client_price_list != undefined) {
						existsPriceList = false
						this.objectOrder.client.client_price_list.forEach(cpl => {
							if (parseInt(lp.id) == parseInt(cpl.price_list_id)) {
								existsPriceList = true
							}
						})
					}
				}
				return existsPriceList
			})
			let index = list.map(l => parseInt(l.id)).indexOf(parseInt(this.defaultPriceListId))
			this.priceListIdSelected = (index > -1) ? this.defaultPriceListId : 0
			return list
		},
		listTypePaymentsFilter() {
			return this.listTypePayments
		},
		modifiableOrder() {
			if (this.actionSecurity(23)) {
				return true
			}
			if (this.actionSecurity(24)) {
				if (this.checkStatusIdToModifyOrder(this.objectOrder.status_id)) {
					return true
				}
			}
			return false
		},
		selectPriceList() {
			if (this.listPriceListsFilter.length > 0) {
				return false
			}
			return true
		},
		validateFormOrder() {
			let r = true
			if (this.objectOrder.client == null) {
				r = false
			}
			if (this.objectOrder.order_products.length == 0) {
				if (isNaN(this.objectOrder.freight) || this.objectOrder.freight == '' || this.objectOrder.freight == 0) {
					r = false
				}
			}
			this.objectOrder.order_products.forEach(op => {
				if (isNaN(op.quantity) || isNaN(op.price) || op.quantity == '' || op.quantity == 0 || op.price == '' || op.price == 0) {
					r = false
				}
			});
			return r
		},
		visibilyBtnsOrder() {
			if (this.modifiableOrder == true) {
				return true
			}
			return false
		},
		visibilyBtnWEB() {
			if (this.visibilyBtnsOrder == true && this.orderWEB == null) {
				return true
			}
			return false
		},
		visibilySearchProducts() {
			if (this.modifiableOrder == true) {
				return true
			}
			return false
		},
		visibleColumnTeam() {
			let i = this.typeOrderIdsTeam.map(id => id).indexOf(parseInt(this.objectOrder.type_order_id))
			return (i > -1)
		}
	},
	methods: {
		addItemListOrderPaymentLocal(tp) {
			let i = -1
			console.log(tp)
			let amount = this.totalOrder - this.totalOrderPayment
			if (tp.multiple == 0) {
				i = this.objectOrder.order_payment.map(op => parseInt(op.type_payment_id)).indexOf(parseInt(tp.id))
				if (i > -1) {
					if (i > -1) {
						setTimeout(() => { this.$refs['inputItemListOrderPaymentAmount' + i][0].focus() }, 100)
					}
					return
				}
			}
			let op = {
				exists: 0,
				id: 0,
				type_payment_id: tp.id,
				type_payment: tp,
				amount: (amount > 0) ? amount : 0,
				bank_id: null,
				bank: null,
				code: '',
				description: '',
				transaction_date: this.currentDate(),
				status_id: null,
				status: null,
				created_at: null,
				updated_at: null
			}
			i = this.addItemListOrderPayment(op)
			if (i > -1) {
				setTimeout(() => { this.$refs['inputItemListOrderPaymentAmount' + i][0].focus() }, 100)
			}
		},
		addOrderProductsLocal(op, type = 'default') {
			let i = this.objectOrder.order_products.map(op_ => parseInt(op_.product_id)).indexOf(parseInt(op.product_id))
			if (i > -1) {
				this.objectOrder.order_products[i].quantity = parseInt(this.objectOrder.order_products[i].quantity) + parseInt(op.quantity)
				this.calculatePrecioItemOrderProduct(this.objectOrder.order_products[i])
			} else {
				this.objectOrder.order_products.push(op)
				this.calculatePrecioItemOrderProduct(this.objectOrder.order_products[this.objectOrder.order_products.length - 1])
			}
		},
		analizeSTRLocal() {
			if (this.searchProductsLocal.trim().length > 0) {
				let analize = this.analizeSTR(this.searchProductsLocal)
				if (analize.result == 'INTEGER') {
					this.orderProductQuantity = this.searchProductsLocal
					this.searchProductsLocal = ''
				} else if (analize.result == 'PRODUCT' || analize.result == 'ISBN' || (analize.result == 'CHARACTER' && analize.data.trim().length > 3)) {
					this.searchProductsLocal = analize.data
					let i = this.objectOrder.order_products.map(op => parseInt(op.product_id)).indexOf(parseInt(analize.data))
					if (i > -1) {
						this.objectOrder.order_products[i].quantity = parseInt(this.objectOrder.order_products[i].quantity) + parseInt(this.orderProductQuantity)
						this.calculatePrecioItemOrderProduct(this.objectOrder.order_products[i])
						this.searchProductsLocal = ''
					} else {
						setTimeout(() => { this.$refs.productPriceListSearchComponent.$refs.btnGetProducts.click() }, 50)
					}
				} else {
					console.log('Esto no va acá')
				}
			}
		},
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getAllTypePaymentsResult': this.getAllTypePaymentsResult(response); break;
				case 'getAllPriceListsResult': this.getAllPriceListsResult(response); break;
				case 'saveOrderResult': this.saveOrderResult(response); break;
				case 'showLinkJumpsellerCustomerResult': this.showLinkJumpsellerCustomerResult(response); break;
				case 'showOrderResult': this.showOrderResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		beforeSavingOrder() {
			if (this.validateFormOrder == true) {
				if (this.totalOrderPayment < this.totalOrder) {
					if (this.objectOrder.client_id == 1) {
						alert(this.objectOrder.client.name + ' ' + this.objectOrder.client.surname + ' no tiene permitido guardar con deuda')
						this.$refs.spanSpanCashRegister.click()
					} else {
						if (this.objectOrder.id == 0) {
							if (confirm('El total de pago (' + this.methodFormatNumber(this.totalOrderPayment) + ') es menor al total del Pedido (' + this.methodFormatNumber(this.totalOrder) + ')\nEl cliente ' + this.objectOrder.client.name + ' ' + this.objectOrder.client.surname + ' quedará con deuda\n¿Desea guardar igualmente?')) {
								this.saveOrderLocal()
							} else {
								this.$refs.spanSpanCashRegister.click()
							}
						} else {
							console.log('Guardar Pedido con deuda')
						}
					}
				} else {
					this.saveOrderLocal()
					console.log('Guardar Pedido')
				}
			}
		},
		calculatePrecioItemOrderProduct(op) {
			let maxQuantity = 1
			let productPriceList = op.product_price_list.filter(ppl => {
				if (parseInt(ppl.id) == parseInt(this.priceListIdSelected) && parseInt(ppl.status_id) == 1 && parseInt(ppl.quantity) <= parseInt(op.quantity)) {
					if (maxQuantity < ppl.quantity) {
						maxQuantity = ppl.quantity
					}
					return true
				} else {
					return false
				}
			})
			productPriceList = productPriceList.filter(ppl => {
				return (maxQuantity == ppl.quantity)
			})

			if (productPriceList.length == 1) {
				op.price = productPriceList[0].price
			} else {

			}
			setTimeout(() => { this.$refs.searchProducts.focus() }, 50)
		},
		changeSelectPriceList() {
			this.objectOrder.order_products = []
		},
		checkItemOrderPayment(op, type = 'status_id') {
			let r = 1
			if (parseFloat(op.amount) == 0) {
				r = 0
			} else {
				if (op.type_payment.listBanks.length > 0) {
					if (op.bank_id == null || op.bank_id < 1) {
						r = -1
					}
				}
				if (op.requires_date == 1) {
					if (op.transaction_date == null || op.transaction_date == '') {
						r = -1
					}
				}
			}
			let i = this.resultTypeCheckOrderPayment.map(rt => rt.status_id).indexOf(r)
			if (i > -1) {
				return this.resultTypeCheckOrderPayment[i][type]
			}
			return null
		},
		checkStatusIdToModifyOrder(id) {
			return (this.statusIdsToModifyOrder.map(i => (i)).indexOf(parseInt(id)) > -1)
		},
		clearFormOrder() {
			this.clearObjectOrder()
			this.objectOrder.type_order_id = this.getTypeOrderId()
			this.objectOrder.order_observations = []
			this.objectOrder.order_observations = []
			this.clearObjectClient()
			this.orderWEB = null
			this.searchClientsLocal = ''
			this.priceListIdSelected = 0
			this.orderProductQuantity = 1
			this.countSearchClient = 0
			this.$refs.shippingRequestComponent.$refs.btnClearShippingRequest.click()
		},
		clientNotFoundLocal() {
			this.loadingClients = false
			if (this.orderWEB != null && this.countSearchClient < 1) {
				this.searchClientsLocal = this.defaultClient.number_document
				this.countSearchClient++
			}
			this.$refs.btnSelectClientDefaultModal.click()
		},
		clientManyResults(data) {
			this.loadingClients = false
			if (this.clientListSeachModalOpen == false) {
				this.$refs.dropdownItemChangeClient.click()
			}
		},
		deleteItemObjectOrderProductLocal(op) {
			let index = this.objectOrder.order_products.map((el) => parseInt(el.product_id)).indexOf(parseInt(op.product_id))
			if (index > -1) {
				this.objectOrder.order_products.splice(index, 1)
			}
		},
		disabledInputObjectOrderProductQuantity(op) {
			if (this.actionSecurity(23)) {
				return false
			}
			if (this.modifiableOrder) {
				if (op.web != true) { // Si el Item viene de la WEB no se podrá cambiar el precio
					return false
				}
			}
			return true
		},
		disabledInputObjectOrderProductPrice(op) {
			if (this.actionSecurity(23)) {
				return false
			}
			if (this.modifiableOrder) {
				if (op.web == true) { // Si el Item viene de la WEB no se podrá cambiar el precio
					return true
				}
				if (this.actionSecurity(25)) {
					return false
				}
			}
			return true
		},
		disabledItemListOrderPayment(op) {
			if (parseInt(op.status_id) == 39) {
				return true
			}
			return false
		},
		existsTypePraymentInOrderPayment(typePaymentId) {
			let i = this.objectOrder.order_payment.map(op => parseInt(op.type_payment_id)).indexOf(parseInt(typePaymentId))
			return (i > -1)
		},
		getAllPriceListsResultLocal(response) { },
		getAllTypePaymentsResultLocal(response) { },
		getClientLocal() {
			if (this.searchClientsLocal.trim().length > 2) {
				let alysisStr = this.analizeSTR(this.searchClientsLocal)
				if (alysisStr.result == 'DOCUMENTNUMBER') {
					if (!this.checkDigitoRUT(alysisStr.data)) {
						alert('Estas ingresando un RUT y el digito no es valido')
						return
					} else {
						this.searchClientsLocal = alysisStr.data
					}
				}
				this.loadingClients = true
				setTimeout(() => { this.$refs.clientListSearchComponent.$refs.btnGetClients.click() }, 50)
			}
		},
		getJumpsellerOrdersLocal() {
			this.$refs.jumpsellerOrderListComponent.$refs.btnGetJumpSellerOrders.click()
		},
		getTypeOrderId() {
			return 1
		},
		getTaxProductPercentage(p) {
			return 0.19
		},
		initModuleLocal() {
			if (this.modifiableOrder == true) {
				this.setOrderInit()
			} else {
				alert('No hay nada que hacer acá, vayase de aquí, intruso')
				this.path('/')
			}
		},
		JSONPaymentInformationJumpseller(pi) {
			let dataJSON = {}
			let arrayBR = pi.split(',')
			let array_ = null
			arrayBR.forEach(abr => {
				abr = abr.replaceAll('<br/>', '')
				array_ = abr.split(':')
				if (array_.length == 2) {
					dataJSON[array_[0].replaceAll(' ', '').normalize('NFD').replace(/[\u0300-\u036f]/g, "")] = array_[1].trim()
				} else if (array_.length == 3) {
					dataJSON[array_[0].replaceAll(' ', '').normalize('NFD').replace(/[\u0300-\u036f]/g, "")] = array_[1].trim().split(' ')[0]
				}
			});
			return dataJSON
		},
		productPriceListManyResults(data) {
			if (this.productPriceListSearchModalOpen == false) {
				this.$refs.spanProductPriceListSearch.click()
			}
		},
		productPriceListNotFoundLocal() {
			alert('hacer algo acá')
		},
		savedShippingRequestLocal(val) {
			this.disabledBtnSaveShippingRequest = val
		},
		saveOrderLocal() {
			if (this.validateFormOrder == true) {
				this.saveOrder(this.objectOrder, 'standard', 'New')
			}
		},
		saveOrderResultLocal(response) {
			console.log(response)
		},
		saveShippingRequestResultLocal(response) {
			this.confirmingOrder = false
			if (response.data.success == true) {
				this.saveOrderShippingRequest(this.objectOrder.id, response.data.data.id)
			}
		},
		selectClientLocal(c) {
			this.loadingClients = false
			if (c.id > 0) {
				this.searchClientsLocal = ''
				this.setObjectClient(c)
				this.setOrderClientLocal()
			} else {
				this.$refs.btnSelectClientDefaultModal.click()
			}
			this.$refs.btnCloseClientListSeachModal.click()
		},
		selectProductPriceListLocal(p) {
			this.searchProductsLocal = ''
			this.setNewOrderProductLocal(p)
			this.$refs.btnCloseProductPriceListSearchModal.click()
		},
		selectedJumpsellerPromotionLocal(jp) {
			this.objectOrder.order_products.forEach(op => {
				if (op.web != true) {
					op.price = parseInt(op.original_price) - parseInt(parseInt(op.original_price) * (parseInt(jp.discount_amount_percent) / 100))
				}
			})
			this.$refs.btnCloseJumpsellerPromotionsModal.click()
		},
		selectedJumpsellerOrderLocal(jo) {
			this.$refs.btnCloseJumpsellerOrdersModal.click()
			this.orderWEB = jo
			this.objectOrder.freight = this.orderWEB.shipping
			this.setOrderPaymentsByJumpseller(this.orderWEB)
			this.setOrderProductsByJumpseller(this.orderWEB.products)
			this.showLinkJumpsellerCustomerLocal(this.orderWEB.customer.id)
			this.objectOrder.order_jumpseller.push({ id: 0, jumpseller_order_id: this.orderWEB.id, order_id: this.objectOrder.id, created_at: null, updated_at: null })
		},
		setNewClientLocal() {
			this.clearObjectClient()
			this.objectClient.exists = 1
			if (this.formatRUT(this.searchClientsLocal) == true) {
				this.objectClient.number_document = this.searchClientsLocal.toUpperCase()
			} else {
				this.objectClient.name = this.searchClientsLocal
			}
			this.setOrderClientLocal()
		},
		setNewClientWEBLocal() {
			this.clearObjectClient()
			this.objectClient.exists = 1
			this.objectClient.name = this.orderWEB.billing_address.name
			this.objectClient.surname = this.orderWEB.billing_address.surname
			this.objectClient.number_document = this.orderWEB.billing_address.taxid.toUpperCase()
			this.objectClient.email = this.orderWEB.customer.email
			this.objectClient.phone = this.orderWEB.customer.phone
			this.objectClient.client_price_list = [{ price_list_id: 1 }]
			this.setOrderClientLocal()
		},
		setNewOrderProductLocal(p) {
			if (p.price == null) {
				alert('El Articulo "' + p.name + '" no tiene precio en la lista de precios seleccionada')
			} else {
				let product = { id: p.id, name: p.name, isbn: p.isbn }
				let op = {
					exists: 0,
					id: 0,
					order_id: 0,
					product_id: p.id,
					product: product,
					quantity: this.orderProductQuantity,
					price: parseInt(p.price),
					original_price: parseInt(p.price),
					tax: this.getTaxProductPercentage(p),
					price_list_id: this.priceListIdSelected,
					product_price_list: p.product_price_list,
					with_freight: withFreight(p),
					bonusable: isBonusable(p),
					tithable: isTithable(p),
					discount: 0,
					created_at: null,
					updated_at: null
				}
				this.addOrderProductsLocal(op)
			}
		},
		setOrderPaymentsByJumpseller(ow) {
			this.objectOrder.order_payment = []
			if (ow.payment_method_type != null && ow.payment_method_type != '') {
				let i = this.listTypePayments.map(tp => tp.payment_method_type).indexOf(ow.payment_method_type)
				if (i > -1) {
					let paymentInformation = this.JSONPaymentInformationJumpseller(ow.payment_information)
					let quotas = ''
					if (paymentInformation['TipodeCuotas'] == 'Credito') {
						quotas = (paymentInformation['NumeroCuotas'] != 0 && paymentInformation['NumeroCuotas'] != '0' && paymentInformation['NumeroCuotas'] != '') ? ' (' + paymentInformation['NumeroCuotas'] + ')' : ' (1)'
					}
					console.log(paymentInformation)
					let op = {
						exists: 0,
						id: 0,
						type_payment_id: this.listTypePayments[i].id,
						type_payment: this.listTypePayments[i],
						amount: ow.total,
						bank_id: null,
						bank: null,
						code: paymentInformation['CodigoAutorizacion'],
						description: paymentInformation['TipodeCuotas'] + quotas,
						transaction_date: paymentInformation['Fecha'],
						status_id: 39,
						status: { id: 39, name: 'Confirmado', synthetic: 'CONFIRMED', type_status_id: 7, success: 'success' },
						created_at: null,
						updated_at: null
					}
					this.addItemListOrderPayment(op)
				}
			}
		},
		setOrderProductsByJumpseller(jop) {
			this.objectOrder.order_products = []
			jop.forEach(product => {
				console.log(product)
				let code = this.getJumpsellerProductIdBySku(product.sku)
				let p = { id: code, name: product.name, isbn: null }
				let op = {
					web: true,
					exists: 0,
					id: 0,
					order_id: 0,
					product_id: code,
					product: p,
					quantity: product.qty,
					price: parseInt(product.price) - parseInt(product.discount),
					original_price: parseInt(product.price),
					product_price_list: []
				}
				this.addOrderProductsLocal(op)
			});
		},
		setOrderClientLocal() {
			this.objectOrder.client_id = this.objectClient.id
			this.objectOrder.client = this.objectClient
			console.log('acaaa esta bien', this.objectOrder)
			let i = -1
			this.defaultClient.client_price_list.forEach(cpl => {
				i = this.objectOrder.client.client_price_list.map(cpl_ => parseInt(cpl_.id)).indexOf(parseInt(cpl.id))
				if (i == -1) {
					this.objectOrder.client.client_price_list.push(cpl)
				}
			})
		},
		setShippingRequestByJumpseller(jo) {
			this.objectShippingRequest.total_price = this.totalOrder
			this.objectShippingRequest.recipient_data.sendto = jo.shipping_address.name + ' ' + jo.shipping_address.surname
			this.objectShippingRequest.recipient_data.address = jo.shipping_address.address
			this.objectShippingRequest.recipient_data.comuna_id = -1
			this.objectShippingRequest.recipient_data.comuna = jo.shipping_address.municipality
			this.objectShippingRequest.recipient_data.phone = jo.customer.phone
			this.objectShippingRequest.recipient_data.number_document = jo.billing_address.taxid
			this.objectShippingRequest.recipient_data.email = jo.customer.email
			let transport = jo.shipping_method_name.split(':')
			this.objectShippingRequest.recipient_data.transport = (transport.length == 2) ? transport[1].trim() : ''
			setTimeout(() => { this.$refs.shippingRequestComponent.$refs.btnToCompleteData.click() }, 1000)
		},
		setOrderInitLocal() {
			this.getAllTypePayments()
			this.getAllPriceLists()
			this.clearFormOrder()
			this.showOrderLocal()
		},
		showClientLocal() {
		},
		showLinkJumpsellerCustomerLocal(id) {
			this.objectOrder.client_id = 0
			this.jumsellerCustomerLink = false
			this.showLinkJumpsellerCustomer(id)
		},
		showLinkJumpsellerCustomerResultLocal(response) {
			if (response.data.success == true) {
				this.setObjectClient(response.data.data)
				this.setOrderClientLocal()
			} else {
				this.searchClientsLocal = this.defaultClient.number_document
				if (this.orderWEB != null) {
					if (this.formatRUT(this.orderWEB.billing_address.taxid)) {
						this.searchClientsLocal = this.orderWEB.billing_address.taxid
					} else {
						this.$refs.btnSelectClientDefaultModal.click()
					}
				}
				this.getClientLocal()
			}
		},
		showJumpsellerPromotionsModal() {
			this.$refs.jumpsellerPromotionListComponent.$refs.btnGetJumpSellerPromotions.click()
		},
		showJumpSellerOrdersModal() {
		},
		showOrderLocal() {
			this.clearObjectOrder()
			if (this.token != undefined && this.token.length == 32) {
				this.showOrder(this.token)
			}
		},
		showOrderPaymentLocal(typeClick = 'simple') {
			if (typeClick == 'simple') {
				if (this.disableSpanCashRegister == false) {
					console.log('Abrir formas de pago')
				}
			} else if (typeClick == 'dbl') {
				this.$refs.spanSpanCashRegister.click()
			}
		},
		showOrderResultLocal(response) {
			if (response.data.success == true) {
				if (this.objectOrder.id > 0) {
					if (this.objectOrder.client != undefined) {
						this.objectOrder.client.exists = 1
					}
					this.setObjectClient(this.objectOrder.client)
				}
			} else {
				console.log(response)
			}
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>

<style lang="scss">
.ul-select-client-order-web {
	padding: 0px;

	li {
		list-style-type: none;
		margin: 10px 10px;
		padding: 10px 20px;
	}
}

.item-type-payment {
	padding-top: 4px;
	padding-bottom: 2px;

	.item-type-payment-icon {
		height: 41px;
	}
}
</style>