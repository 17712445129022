var materialType = {
	data() {
		return {
			listMaterialTypes: [],
			listMaterialTypeClassifications: [],
			listMaterialTypeSubClassifications: [],
			paginationListMaterialTypes: [],
			loadingMaterialTypes: false,
			materialTypeClassificationIds: '',
			searchMaterialTypeClassification: ''
		}
	},
	methods: {
		getMaterialTypes(page) {
			this.loadingMaterialTypes = true
			let url = this.getApiBackendNew + 'materialTypes'
			this.axiosActionIS(url, 'GET', 'getMaterialTypesResult')
		},
		getMaterialTypesResult(response) {
			this.loadingMaterialTypes = false
			if (response.data.success == true) {
				this.listMaterialTypes = response.data.data
				this.paginationListMaterialTypes = response.data.paginate
			}
			this.getMaterialTypesResultLocal(response)
		},
		getMaterialTypesResultLocal(response) {
			console.log('getMaterialTypesResultLocal no desarrollado', response)
		},
		getMaterialTypeClassifications(page) {
			this.loadingMaterialTypes = true
			let url = this.getApiBackendNew + 'materialTypeClassifications'
			this.axiosActionIS(url, 'GET', 'getMaterialTypeClassificationsResult')
		},
		getMaterialTypeClassificationsResult(response) {
			this.loadingMaterialTypes = false
			if (response.data.success == true) {
				this.listMaterialTypeClassifications = response.data.data
			}
			this.getMaterialTypeClassificationsResultLocal(response)
		},
		getMaterialTypeClassificationsResultLocal(response) {
			console.log('getMaterialTypeClassificationsResultLocal no desarrollado', response)
		},
		getMaterialTypeSubClassifications(page) {
			this.loadingMaterialTypes = true
			let param = '?search=' + this.searchMaterialTypeClassification + '&page=' + page + '&materialTypeClassificationIds=' + this.materialTypeClassificationIds
			let url = this.getApiBackendNew + 'materialTypeSubClassifications' + param
			this.axiosActionIS(url, 'GET', 'getMaterialTypeSubClassificationsResult')
		},
		getMaterialTypeSubClassificationsResult(response) {
			this.loadingMaterialTypes = false
			if (response.data.success == true) {
				this.listMaterialTypeSubClassifications = response.data.data
			}
			this.getMaterialTypeSubClassificationsResultLocal(response)
		},
		getMaterialTypeSubClassificationsResultLocal(response) {
			console.log('getMaterialTypeSubClassificationsResultLocal no desarrollado', response)
		}
	},
};

export default materialType;