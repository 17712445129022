<template>
	<div id="paymentComponent">
		<button ref="btnShowPayment" @click="showPaymentLocal" v-show="false">Show Payment</button>
		<div v-show="savingPayment == true"
			style="position: absolute; z-index:1; height: 100vh; top:0; right:0; left: 0px; align-items: center; display: flex;"
			class="bg-secondary">
			<div style="width: 100%;" class="text-center text-bg-secondary">
				<div class="spinner-border spinner-border-sm" role="status"><span
						class="visually-hidden">Loading...</span></div>
				Guardando Cambios
			</div>
		</div>
		<div v-if="visiblePaymentComponent">
			<div class="row mb-2">
				<div class="col-sm-4 text-end">Forma de Pago:</div>
				<div class="col-sm-8  fw-semibold">
					<select v-model="objectPayment.type_payment_id" class="input-clear text-muted fw-semibold"
						:disabled="notModifiable"
						@change="updatePaymentLocal(objectPayment.id, 'type_payment_id', objectPayment.type_payment_id)">
						<option v-for="(tp, i) in listTypePayments" :key="i" :value="tp.id">{{ tp.name }}</option>
					</select>
				</div>
			</div>
			<div class="row mb-2">
				<div class="col-sm-4 text-end">Monto:</div>
				<div class="col-sm-8">
					<span v-show="notModifiable == true" class="text-muted fw-semibold">{{
						methodFormatNumber(objectPayment.amount) }}</span>
					<input v-show="notModifiable == false" v-model="objectPayment.amount" :readonly="notModifiable"
						class="input-clear text-muted fw-semibold" @focus="$event.target.select()"
						@keyup.enter="updatePaymentLocal(objectPayment.id, 'amount', objectPayment.amount)" />
				</div>
			</div>
			<div class="row mb-2">
				<div class="col-sm-4 text-end">Banco:</div>
				<div class="col-sm-8 fw-semibold">
					<select v-model="objectPayment.bank_id" class="input-clear text-muted fw-semibold"
						:disabled="notModificableStatus" v-show="listBanks.length > 0"
						@change="updatePaymentLocal(objectPayment.id, 'bank_id', objectPayment.bank_id)">
						<option v-for="(b, i) in listBanks" :key="i" :value="b.id">{{ b.name }}</option>
					</select>
				</div>
			</div>
			<div class="row mb-2">
				<div class="col-sm-4 text-end">Codigo:</div>
				<div class="col-sm-8">
					<input v-model="objectPayment.code" :readonly="notModifiable"
						class="input-clear text-muted fw-semibold" @focus="$event.target.select()"
						@keyup.enter="updatePaymentLocal(objectPayment.id, 'code', objectPayment.code)" />
				</div>
			</div>
			<div class="row mb-2">
				<div class="col-sm-4 text-end">Descripción:</div>
				<div class="col-sm-8">
					<input v-model="objectPayment.description" :readonly="notModifiable"
						class="input-clear text-muted fw-semibold" @focus="$event.target.select()"
						@keyup.enter="updatePaymentLocal(objectPayment.id, 'description', objectPayment.description)" />
				</div>
			</div>
			<div class="row mb-2">
				<div class="col-sm-4 text-end">Fecha:</div>
				<div class="col-sm-8">
					<input v-model="objectPayment.transaction_date" type="date" :readonly="notModificableStatus"
						class="input-clear text-muted fw-semibold"
						@change="updatePaymentLocal(objectPayment.id, 'transaction_date', objectPayment.transaction_date)"
						@keydown="keyDownFalse" />
				</div>
			</div>
			<div class="row mb-2">
				<div class="col-sm-4 text-end">Estado:</div>
				<div class="col-sm-8  fw-semibold">
					<select v-model="objectPayment.status_id" class="input-clear text-muted fw-semibold text-white"
						:disabled="notModificableStatus" :class="'bg-' + objectPayment.status.color"
						@change="updatePaymentLocal(objectPayment.id, 'status_id', objectPayment.status_id)">
						<option v-for="(s, i) in listStatusPaymentFilter" :key="i" :value="s.id">{{ s.name }}</option>
					</select>
				</div>
			</div>
			<div v-if="objectPayment.source.type != undefined" class="px-2">
				<hr>
				<div v-if="objectPayment.source.type == 'order'">
					<div class="fw-medium">
						{{ objectPayment.source.data[0].typeOrder.name }} Nº {{ objectPayment.source.data[0].id }}
					</div>
					<div>
						<div>
							<span><i class="fas fa-church"></i></span>
							Iglesia/Grupo {{ objectPayment.source.data[0].church.name }}
							(ID: {{ objectPayment.source.data[0].church.id }})
						</div>
					</div>
					<div>
						<div>
							<span class="me-2"><i class="fas fa-user-tie"></i></span>
							{{ objectPayment.source.data[0].client.name }}
							{{ objectPayment.source.data[0].client.surname }}
						</div>
					</div>
				</div>
				<div v-if="objectPayment.source.type == 'consignment'">
					<div class="fw-medium">
						Remesa Nº {{ objectPayment.source.data[0].id }}
					</div>
					<div>
						{{ objectPayment.source.data[0].description }}
					</div>
					<div>
						{{ objectPayment.source.data[0].team.name }} (ID: {{ objectPayment.source.data[0].team.id }})
					</div>
				</div>
			</div>
			<div v-if="listUploadDocuments.length > 0" class="px-2">
				<hr>
				<div class="fw-medium">
					Documentos adjuntos ({{ listUploadDocuments.length }})
				</div>
				<div>
					<div>
						<div v-for="(ud, i) in listUploadDocuments" :key="i">
							<div>
								<img :src="ud.public_path + ud.name_save" width="100%">
							</div>
							<div class="fs--1">
								<span>{{ ud.name_original }}</span>
								<span class="float-end">{{ ud.created_at }}</span>
							</div>
							<hr>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import payment from '@/mixins/generals/payment.js'
import typePayment from '@/mixins/generals/typePayment.js'

export default {
	name: 'PaymentComponent',
	mixins: [mixin, axiosAction, errors, payment, typePayment],
	props: ['id'],
	data() {
		return {
			listUploadDocuments: []
		}
	},
	components: {
	},
	computed: {
		listStatusPaymentFilter() {
			return this.listStatusPayment
		},
		notModifiable() {
			if (this.actionSecurity(20)) {
				return false
			}
			return true
		},
		notModificableStatus() {
			let r = true
			if (this.objectPayment.status.id == 38) {
				r = false
			}

			r = (r) ? this.notModifiable : r
			return r
		},
		visiblePaymentComponent() {
			if (this.searchingPayment == true) {
				return false
			}
			if (this.objectPayment.id == 0) {
				return false
			}
			return true
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getAllTypePaymentsResult': this.getAllTypePaymentsResult(response); break;
				case 'getPaymentUploadDocumentResult': this.getPaymentUploadDocumentResult(response); break;
				case 'showPaymentResult': this.showPaymentResult(response); break;
				case 'updatePaymentResult': this.updatePaymentResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.setPaymentComponentInit()
		},
		getPaymentUploadDocumentResultLocal(response) {
			if (response.data.success == true) {
				this.listUploadDocuments = response.data.data
			}
		},
		setPaymentComponentInit() {
			this.getAllTypePayments()
		},
		getAllTypePaymentsResultLocal(response) {

		},
		showPaymentLocal() {
			this.listUploadDocuments = []
			if (this.id != undefined && this.id > 0) {
				this.showPayment(this.id)
			}
		},
		showPaymentResultLocal(response) {
			if (response.data.success == true) {
				if (this.objectPayment.id > 0) {
					this.getPaymentUploadDocument(this.objectPayment.id)
				}
			}
		},
		updatePaymentLocal(id, field, value) {
			this.updatePayment(id, field, value)
		},
		updatePaymentResultLocal(response) {
			if (response.data.success == true) {
				if (response.data.message == 'UPDATED') {
					this.showMsgErrorHandling('Actualizado', 'Se actualizó con exito el pago', 'info', 5000, 'top right')
					this.$emit('updatePaymentResult', response)
					if (response.data.extra != null) {
						response.data.extra.forEach(e => {
							if (e.legal_documents != undefined) {
								let document = ''
								e.legal_documents.forEach(ld => {
									document += (document == '') ? ld.legal_document_number : ', ' + ld.legal_document_number
								});
								this.showMsgErrorHandling('Documentos Electronicos', 'Se han generado ' + e.legal_documents.length + ' documentos electronicos (' + document + ')', 'success', 5000, 'top right')
							}
						});
					}
				}
			}
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>