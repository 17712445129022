var priceCalculation = {
	data() {
		return {
			listPriceCalculations: [],
			loadingPriceCalculations: false,
			paginationListPriceCalculations: [],
			objectPriceCalculation: {
				exists: 0,
				id: 0,
				script: '',
				observation: '',
				status_id: null,
				status: null,
				price_calculation_type_id: null,
				price_calculation_type: null,
				created_at: null,
				updated_at: null,
			},
			qRowPriceCalculation: 10,
			savingPriceCalculation: false,
			searchingPriceCalculation: false,
			searchPriceCalculations: ''
		}
	},
	computed: {
	},
	methods: {
		addItemListPriceCalculations(pc) {
			this.listPriceCalculations.push(pc)
		},
		clearObjectPriceCalculation() {
			this.objectPriceCalculation.exists = 0
			this.objectPriceCalculation.id = 0
			this.objectPriceCalculation.script = ''
			this.objectPriceCalculation.observation = ''
			this.objectPriceCalculation.status_id = null
			this.objectPriceCalculation.status = null
			this.objectPriceCalculation.price_calculation_type_id = null
			this.objectPriceCalculation.price_calculation_type = null
			this.objectPriceCalculation.created_at = null
			this.objectPriceCalculation.updated_at = null
		},
		execPriceCalculationById(id, param = "?") {
			let url = this.getApiBackendNew + 'priceCalculation/' + id + '/calculateById?'
			url += param
			this.axiosActionIS(url, 'GET', 'execPriceCalculationByIdResult')
		},
		execPriceCalculationByIdResult(response) {
			this.execPriceCalculationByIdResultLocal(response)
		},
		execPriceCalculationByIdResultLocal(response) {
			console.log('execPriceCalculationByIdResultLocal no desarrollado', response)
		},
		getPriceCalculations(page = 1) {
			this.listPriceCalculations = []
			this.paginationPriceCalculations = []
			this.loadingPriceCalculations = true
			let param = '?page=' + page + '&search=' + this.searchPriceCalculations + '&qRows=' + this.qRowPriceCalculation
			let url = this.getApiBackendNew + 'priceCalculations' + param
			this.axiosActionIS(url, 'GET', 'getPriceCalculationsResult')
		},
		getPriceCalculationsResult(response) {
			this.loadingPriceCalculations = false
			if (response.data.success == true) {
				this.listPriceCalculations = response.data.data
				this.paginationListPriceCalculations = response.data.paginate
			}
			this.getPriceCalculationsResultLocal(response)
		},
		getPriceCalculationsResultLocal(response) {
			console.log('getPriceCalculationsResultLocal no desarrollado', response)
		},
		savePriceCalculation(pc) {
			this.savingPriceCalculation = true
			let method = (pc.id == 0) ? 'POST' : 'PUT'
			let url = this.getApiBackendNew + 'priceCalculation/'
			url += (method == 'POST') ? 'register' : pc.id + '/update'
			this.axiosActionIS(url, method, 'savePriceCalculationResult', pc)
		},
		savePriceCalculationResult(response) {
			this.savingPriceCalculation = false
			if (response.data.success == true) {
				this.setObjectPriceCalculation(response.data.data)
			}
			this.savePriceCalculationResultLocal(response)
		},
		savePriceCalculationResultLocal(response) {
			console.log('savePriceCalculationResultLocal no desarrollado', response)
		},
		setObjectPriceCalculation(pc) {
			this.clearObjectPriceCalculation()
			for (const property in pc) {
				this.objectPriceCalculation[property] = pc[property]
			}
		},
		setObjectPriceCalculationCopy() {
			for (const property in this.objectPriceCalculation) {
				if (!Array.isArray(this.objectPriceCalculation[property])) {
					this.objectPriceCalculation['_' + property] = this.objectPriceCalculation[property]
				}
			}
		},
		showPriceCalculation(id) {
			this.searchingPriceCalculation = true
			let url = this.getApiBackendNew + 'priceCalculation/' + id
			this.axiosActionIS(url, 'GET', 'showPriceCalculationResult')
		},
		showPriceCalculationResult(response) {
			this.searchingPriceCalculation = false
			if (response.data.success == true) {
				this.setObjectPriceCalculation(response.data.data)
			}
			this.showPriceCalculationResultLocal(response)
		},
		showPriceCalculationResultLocal(response) {
			console.log('showPriceCalculationResultLocal no desarrollado', response)
		},
		updateItemListPriceCalculations(data) {
			let index = this.listPriceCalculations.map(e => parseInt(e.id)).indexOf(data.id)
			if (index > -1) {
				for (const property in data) {
					this.listPriceCalculations[index][property] = data[property]
				}
			}
		},
		validateCopyObjectPriceCalculation() {
			let r = true
			for (const property in this.objectPriceCalculation) {
				if (property.substring(0, 1) != "_" && this.objectPriceCalculation['_' + property] != undefined) {
					if (this.objectPriceCalculation['_' + property] != this.objectPriceCalculation[property]) {
						r = false
					}
				}
			}
			return r
		}
	},
};

export default priceCalculation;