var jumpsellerOrder = {
	data() {
		return {
			listJumsellerOrders: [],
			listJumpsellerOrderStatus: [
				{ status: 'Abandoned', spanish: 'Abandonado', class: 'jump-bg-abandoned', selected: false },
				{ status: 'Canceled', spanish: 'Cancelado', class: 'jump-bg-canceled', selected: false },
				{ status: 'PendingPayment', spanish: 'Pendiente de Pago', class: 'jump-bg-pending-payment', selected: true },
				{ status: 'Paid', spanish: 'Pagado', class: 'jump-bg-paid', selected: true },
			],
			loadingJumsellerOrders: false,
		}
	},
	methods: {
		getJumpsellerOrders(page = 1, limit = 20) {
			this.loadingJumsellerOrders = true
			this.listJumsellerOrders = []
			let param = '?page=' + page + '&limit=' + limit
			let url = this.getApiBackendNew + 'jumpseller/orders' + param
			this.axiosActionIS(url, 'GET', 'getJumpsellerOrdersResult')
		},
		getJumpsellerOrdersResult(response) {
			this.loadingJumsellerOrders = false
			if (response.data.success == true) {
				this.listJumsellerOrders = response.data.data
			}
			this.getJumpsellerOrdersResultLocal(response)
		},
		getJumpsellerOrdersResultLocal(response) {
			console.og('getJumpsellerOrdersResultLocal no desarrollado', response)
		},
		setDataByJumpsellerStatus(status, type = 'spanish') {
			let data = null
			let index = this.listJumpsellerOrderStatus.map(el => (el.status)).indexOf(status)
			if (index > -1) {
				data = this.listJumpsellerOrderStatus[index][type]
			}
			return data
		},
	},
};

export default jumpsellerOrder;